import React, { useContext } from 'react';
import SigninContext from '../contexts/SigninContext';
import styled from 'styled-components';
import exportDataImage from '../images/export-data.png';
import format from 'date-fns/format';
import VerificationModalDialog from '../containers/VerificationModalDialog';
import VerifyEmail from '../components/verification/VerifyEmail';
import LoadingSpinner from '../common/LoadingSpinner';

import { PageWrapper, PageContent, PageSection, StyledIntroFlex } from '../common/Page';
import { Heading, Paragraph, Spacer } from '@zillow/constellation';
import { API_REQUESTS_DOWNLOAD_FUNC } from '../constants/ApiConstants';
import { REQUEST_TYPE } from '../constants/RequestsConstants';
import { VERIFICATION_STATE } from '../constants/VerificationConstants';

const StyledPageContent = styled(PageContent)`
    padding: 0;
`;
const StyledTextContent = styled.span`
    color: ${props => props.theme.constellation?.colors.red500};
    font-style: italic;
    padding-left: 10px;
`;

const DownloadDataPage = () => {
    const {
        state: { downloadRequests, isDownloadRequestsCalculated, primaryEmail, userEmails },
    } = useContext(SigninContext);

    const isPrimaryEmailVerified =
        userEmails.filter(
            ue => ue.email === primaryEmail && ue.verificationState === VERIFICATION_STATE.VERIFIED
        ).length > 0;
    const isDownloadReady = downloadRequests.length > 0;

    const isDataExpired = lastUpdatedTimeStamp => {
        return (
            Math.ceil(
                (Math.round(new Date().getTime() / 1000) - lastUpdatedTimeStamp) / (60 * 60 * 24)
            ) > 30
        );
    };

    return (
        <PageWrapper backgroundColor="#f4f4f4">
            <StyledPageContent>
                <PageSection maxWidth={1200}>
                    <StyledIntroFlex
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        flexWrap="wrap"
                        image={exportDataImage}
                    >
                        <div className="page-hero-image" />
                        {!isDownloadRequestsCalculated ? (
                            isPrimaryEmailVerified ? (
                                <LoadingSpinner isLarge />
                            ) : null
                        ) : isDownloadReady ? (
                            <div>
                                <Heading level="2" serif>
                                    Your data is ready
                                </Heading>
                                <Paragraph fontType="h5" as="p" marginTop="sm">
                                    We've compiled the personal data you requested. It will remain
                                    available here for the next 30 days.
                                </Paragraph>
                            </div>
                        ) : (
                            <div>
                                <Heading level="2" serif>
                                    Your data is not available
                                </Heading>
                                <Paragraph fontType="h5" as="p" marginTop="sm">
                                    It's either pending or expired, please check back later.
                                </Paragraph>
                            </div>
                        )}
                    </StyledIntroFlex>
                </PageSection>
            </StyledPageContent>

            {isDownloadReady ? (
                downloadRequests.map(({ requestId, timestamp, lastUpdatedTimeStamp }) => (
                    <Spacer marginTop="md">
                        <PageContent maxWidth={1200}>
                            <Heading level="5">
                                <strong>Data archive requested</strong>
                            </Heading>

                            <Paragraph marginTop="md">
                                {format(timestamp * 1000, 'EEEE, MMMM dd, yyyy')}{' '}
                            </Paragraph>

                            <Spacer marginTop="lg" marginBottom="md">
                                <VerificationModalDialog
                                    buttonText="Download data"
                                    downloadUrl={API_REQUESTS_DOWNLOAD_FUNC({ requestId })}
                                    initialVerificationContentDisplayName={VerifyEmail.displayName}
                                    type={REQUEST_TYPE.DOWNLOAD}
                                    shouldCloseOnOutsideClick={false}
                                    shouldCloseOnESCKeyPress={false}
                                    isDisabled={isDataExpired(lastUpdatedTimeStamp)}
                                />
                                {isDataExpired(lastUpdatedTimeStamp) ? (
                                    <StyledTextContent>Data is expired </StyledTextContent>
                                ) : (
                                    ''
                                )}
                            </Spacer>
                        </PageContent>
                    </Spacer>
                ))
            ) : (
                <VerificationModalDialog
                    closeButton={null}
                    defaultOpen={true}
                    initialVerificationContentDisplayName={VerifyEmail.displayName}
                    type={REQUEST_TYPE.REQUESTS}
                    shouldCloseOnOutsideClick={false}
                    shouldCloseOnESCKeyPress={false}
                />
            )}
        </PageWrapper>
    );
};

export default DownloadDataPage;
