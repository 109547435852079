import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import API from '../../utils/api';
import SigninContext, { ACTIONS as SIGNIN_ACTIONS } from '../../contexts/SigninContext';
import LoadingSpinner from '../../common/LoadingSpinner';
import _get from 'lodash/get';

import { API_IDENTIFICATION } from '../../constants/ApiConstants';
import { PageWrapper } from '../../common/Page';
import { parseErrorResponse } from '../../utils';
import { Text, mediaBreakpointMixin } from '@zillow/constellation';
import { Redirect } from 'react-router-dom';

const StyledPageWrapper = styled(PageWrapper)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    @media ${mediaBreakpointMixin('lg')} {
        display: flex;
    }
`;

const SigninFlow = ({ signinPromise }) => {
    const {
        state: { csrfToken, redirectTo },
        dispatch: signinDispatch,
    } = useContext(SigninContext);

    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [flashError, setFlashError] = useState(null);

    useEffect(() => {
        const signin = async () => {
            try {
                const signinResponse = await signinPromise;
                const signinError = _get(signinResponse, 'data.error');

                if (signinError) {
                    setFlashError(signinError);
                } else {
                    const identifiers = _get(signinResponse, 'data.identifiers');
                    if (identifiers) {
                        signinDispatch({
                            type: SIGNIN_ACTIONS.SET_USER_EMAILS,
                            payload: {
                                identifiers,
                            },
                        });
                        signinDispatch({
                            type: SIGNIN_ACTIONS.LOGIN,
                            payload: {
                                isAuthenticated: true,
                            },
                        });
                    }

                    const primaryEmailResponse = await API({
                        url: API_IDENTIFICATION,
                        headers: {
                            'X-Csrf-Token': csrfToken,
                        },
                    });
                    const primaryEmail = _get(primaryEmailResponse, 'data.primaryEmail');
                    signinDispatch({
                        type: SIGNIN_ACTIONS.SET_PRIMARY_EMAIL,
                        payload: {
                            primaryEmail,
                        },
                    });
                }

                setShouldRedirect(true);
            } catch (error) {
                setFlashError(parseErrorResponse(error));
                setShouldRedirect(true);
            }
        };

        // happens only on csrfToken change when it's not null
        csrfToken !== null && signin();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [csrfToken]);

    return (
        <StyledPageWrapper>
            {shouldRedirect && (
                <Redirect
                    to={{
                        pathname: redirectTo,
                        state: {
                            error: flashError,
                        },
                    }}
                />
            )}
            <Text className="signing-in" fontType="h5">
                Signing in, please wait... <LoadingSpinner />
            </Text>
        </StyledPageWrapper>
    );
};

export default SigninFlow;
