import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

const SigninConfirmationWithRedirect = ({ redirectTo }) => {
    return <Redirect to={redirectTo} />;
};

SigninConfirmationWithRedirect.displayName = 'SIGNIN_CONFIRMATION_WITH_REDIRECT';

SigninConfirmationWithRedirect.propTypes = {
    redirectTo: PropTypes.string.isRequired,
};

export default SigninConfirmationWithRedirect;
