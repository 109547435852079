import managePrivacyPreferences from '../images/manage-privacy-preferences.png';
import exportDataImage from '../images/export-data.png';
import deleteDataImage from '../images/delete-data.png';
import updateYourDataImage from '../images/correct-your-data.png';

export const COLLECT_INFO_CARD = {
    mainTitle: 'The information we collect',
    mainDescription:
        'What we learn when you use our sites and apps helps us pave the way to your next home.',
    infoList: [
        {
            title: 'Transaction details',
            description:
                'If you ask Zillow to make an offer on your home, we collect information to assess its value.',
        },
        {
            title: 'Search history',
            description:
                "We track the houses and neighborhoods you explore on our sites and apps so we can show you more homes you'll love.",
        },
        {
            title: 'Payment info',
            description:
                'If you pay your rent through Zillow, we collect your payment details to reliably process your automatic payments.',
        },
    ],
};

export const USE_INFO_CARD = {
    mainTitle: 'How we use this information',
    mainDescription: 'We use your data to make your home journey easier and more efficient.',
    infoList: [
        {
            title: 'Transactions',
            description:
                'If you ask us to, we use the information you share with us to help with selling your home, signing a lease or paying rent.',
        },
        {
            title: 'Personalization',
            description:
                'We use your search history on our sites and apps to keep you updated about homes that we think you might like.',
        },
        {
            title: 'Convenience',
            description:
                "If you've enabled your mobile device's location settings, we can help you unlock a front door for an unassisted home tour.",
        },
    ],
};

export const SHARE_INFO_CARD = {
    mainTitle: 'How we share information',
    mainDescription:
        'We share information across our lines of business and with partners and service providers who can help with your next move.',
    infoList: [
        {
            title: 'Real estate experts',
            description:
                'If you ask to connect with an agent, lender or other real estate professional on Zillow, we pass along the relevant details.',
        },
        {
            title: 'Ad partners',
            description:
                'We partner with companies like Google and Instagram to make sure the ads you see on their platforms reflect your search criteria.',
        },
        {
            title: 'Service providers',
            description:
                'If you ask us to, we share your information with third-party vendors like Experian to complete a credit check for your rental application.',
        },
    ],
};

export const MANAGE_PRIVACY_PREFERENCES_ACTION_CARD = {
    image: managePrivacyPreferences,
    title: 'Manage Privacy Preferences',
    buttonText: 'Get started',
    actionLink: '/cookie',
};

export const EXPORT_DATA_ACTION_CARD = {
    image: exportDataImage,
    title: 'Request a copy of your data',
    buttonText: 'Get started',
    actionLink: '/export',
};

export const DELETE_DATA_ACTION_CARD = {
    image: deleteDataImage,
    title: 'Delete your data',
    buttonText: 'Get started',
    actionLink: '/delete',
};

export const UPDATE_YOUR_DATA_ACTION_CARD = {
    image: updateYourDataImage,
    title: 'Update your personal data',
    buttonText: 'Get started',
    actionLink: '/correction',
};
