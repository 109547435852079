import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Heading, Paragraph, Spacer, spaceMixin } from '@zillow/constellation';

export const DISPLAY_NAME = 'FOUND_ADDITIONAL_EMAIL';
const VERIFY = 'verify';
const DENY = 'deny';

const StyledRadioGroup = styled.ul`
    width: 75%;
    margin: 0 auto;
    color: ${props => props.theme.constellation?.colors.textMedium};
    text-align: left;
    margin-bottom: ${spaceMixin('lg')};

    > li {
        display: flex;
        margin-bottom: ${spaceMixin('sm')};

        > input {
            margin-right: ${spaceMixin('xs')};
        }
    }
`;

const StyledWrapper = styled.div`
    .email-wrapper {
        word-break: break-word;
    }
`;

const StyledEmailParagraph = styled(Paragraph)`
    font-weight: 600;
`;

const FoundAdditionalEmail = ({ email, order, totalNumber, onDenyEmailPromise, onClickNext }) => {
    // const denyEmailRadio = useRef(null);
    const [verificationState, setVerificationState] = useState(null);

    const onClickNextButton = () => {
        if (verificationState === VERIFY) {
            onClickNext();
        }
        if (verificationState === DENY) {
            onDenyEmailPromise().then(onClickNext);
        }
    };

    return (
        <StyledWrapper>
            <Heading level="3" serif marginTop="sm">
                Please verify your email
            </Heading>
            <Paragraph marginTop="lg" marginBottom="lg">
                We found an additional email address associated with your account. Is this email
                yours?
            </Paragraph>
            <Spacer marginTop="lg" marginBottom="lg">
                <Paragraph fontType="smalllabel">
                    {order} OF {totalNumber}
                </Paragraph>
                <StyledEmailParagraph fontType="h5" className="email-wrapper">
                    {email}
                </StyledEmailParagraph>
            </Spacer>
            <StyledRadioGroup marginTop="lg" marginBottom="xs">
                <li>
                    <input
                        type="radio"
                        id="isEmailMineTrue"
                        name="isEmailMine"
                        onChange={() => setVerificationState(VERIFY)}
                    />
                    <label htmlFor="isEmailMineTrue">Yes, this email is mine</label>
                </li>
                <li>
                    <input
                        type="radio"
                        id="isEmailMineFalse"
                        name="isEmailMine"
                        onChange={() => setVerificationState(DENY)}
                    />
                    <label htmlFor="isEmailMineFalse">
                        No, this email is not mine <br />
                        (or I don't have access)
                    </label>
                </li>
            </StyledRadioGroup>
            <Button onClick={onClickNextButton} buttonType="primary">
                Next
            </Button>
        </StyledWrapper>
    );
};

FoundAdditionalEmail.propTypes = {
    email: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
    totalNumber: PropTypes.number.isRequired,
    onClickNext: PropTypes.func.isRequired,
};

FoundAdditionalEmail.displayName = DISPLAY_NAME;

export default FoundAdditionalEmail;
