import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import logo from '../images/zgroup-logo.svg';
import NavHamburger from './NavHamburger';

import { Flex, TextButton, mediaBreakpointMixin } from '@zillow/constellation';
import { Link } from 'react-router-dom';

const StyledHeader = styled.header`
    border-bottom: 1px solid ${props => props.theme.constellation?.colors.borderLight};

    > div {
        height: 80px;
    }
`;

const StyledLogo = styled.img`
    width: 220px;
    height: 100%;
`;

const StyledSignoutWrapper = styled.div`
    display: none;

    @media ${mediaBreakpointMixin('lg')} {
        display: inline-block;
    }
`;

const Header = ({
    isAuthenticated,
    onClickHamburger,
    onClickLogout,
    primaryEmail,
    openSigninModal,
}) => {
    return (
        <StyledHeader>
            <Flex display="flex" marginLeft="md" marginRight="md" justifyContent="space-between">
                <Link to="/">
                    <StyledLogo src={logo} className="zillow-group-logo" alt="zillow-group-logo" />
                </Link>
                <Flex display="flex" alignItems="center">
                    {isAuthenticated ? (
                        <StyledSignoutWrapper>
                            Hi, {primaryEmail}{' '}
                            <TextButton
                                fontType="bodySmallHeading"
                                marginLeft="md"
                                onClick={onClickLogout}
                            >
                                Sign out
                            </TextButton>
                        </StyledSignoutWrapper>
                    ) : (
                        <TextButton fontType="bodySmallHeading" onClick={openSigninModal}>
                            Sign in
                        </TextButton>
                    )}
                </Flex>
                {isAuthenticated && <NavHamburger onClick={onClickHamburger} />}
            </Flex>
        </StyledHeader>
    );
};

Header.propTypes = {
    isAuthenticated: PropTypes.bool,
    onClickHamburger: PropTypes.func,
    primaryEmail: PropTypes.string,
    openSigninModal: PropTypes.func.isRequired,
};

export default Header;
