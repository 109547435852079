import React, { useContext, useEffect, useReducer } from 'react';
import styled from 'styled-components';
import exportDataImage from '../images/export-data.png';
import SelectList from '../components/SelectList';
import SigninContext from '../contexts/SigninContext';
import VerificationModalDialog from '../containers/VerificationModalDialog';
import NeedHelp from '../components/NeedHelp';
import API from '../utils/api';
import axios from 'axios';
import produce from 'immer';

import {
    PageWrapper,
    PageContent,
    PageSection,
    StyledIntroFlex,
    StyledLoadingSpinnerSection,
    StyledHeadingParagraph,
} from '../common/Page';
import { spaceMixin, Heading, Paragraph, Spacer, Text } from '@zillow/constellation';
import { API_DATA_CATEGORIES } from '../constants/ApiConstants';
import { REQUEST_TYPE } from '../constants/RequestsConstants';
import VerifyEmail from '../components/verification/VerifyEmail';
import CollapsibleSampleData from '../components/CollapsibleSampleData';
import SeparatorLine from '../common/SeparatorLine';

const StyledPageContent = styled(PageContent)`
    padding: 0;
`;

const StyledParagraph = styled(Paragraph)`
    max-width: 792px;
    margin-top: ${spaceMixin('md')};
`;

const StyledParagraphWithoutTopMargin = styled(Paragraph)`
    max-width: 792px;
`;

const StyledLargeSpacer = styled.div`
    margin-top: 72px;
`;

// reducer actions
export const ACTIONS = {
    CHECK_ALL: 'CHECK_ALL',
    UNCHECK_ALL: 'UNCHECK_ALL',
    TOGGLE_CHECKBOX: 'TOGGLE_CHECKBOX',
    UPDATE_BASIC_INFO_SELECTIONS: 'UPDATE_BASIC_INFO_SELECTIONS',
    UPDATE_ADVANCED_INFO_SELECTIONS: 'UPDATE_ADVANCED_INFO_SELECTIONS',
    INIT_SELECTED_ITEMS: 'INIT_SELECTED_ITEMS',
};

const createInitialSelectedItems = selections =>
    selections.reduce((accumulator, currentValue) => {
        accumulator[currentValue.name] = false;
        return accumulator;
    }, {});

// reducer
const exportDataPageReducer = produce((draft, action) => {
    switch (action.type) {
        case ACTIONS.TOGGLE_CHECKBOX:
            const { name } = action.payload;
            draft.selectedItems[name] = !draft.selectedItems[name];
            return;
        case ACTIONS.CHECK_ALL:
            action.payload.names.forEach(name => {
                draft.selectedItems[name] = true;
            });
            return;
        case ACTIONS.UNCHECK_ALL:
            action.payload.names.forEach(name => {
                draft.selectedItems[name] = false;
            });
            return;
        case ACTIONS.UPDATE_BASIC_INFO_SELECTIONS:
            const { basicInfoSelections } = action.payload;
            draft.basicInfoSelections = basicInfoSelections;
            return;
        case ACTIONS.UPDATE_ADVANCED_INFO_SELECTIONS:
            const { advancedInfoSelections } = action.payload;
            draft.advancedInfoSelections = advancedInfoSelections;
            return;
        case ACTIONS.INIT_SELECTED_ITEMS:
            const { selections } = action.payload;
            draft.selectedItems = createInitialSelectedItems(selections);
            return;
        default:
            return;
    }
});

const ExportDataPage = () => {
    const initialState = {
        advancedInfoSelections: [],
        basicInfoSelections: [],
        selectedItems: {},
    };

    const [
        { basicInfoSelections, advancedInfoSelections, selectedItems },
        exportDataDispatch,
    ] = useReducer(exportDataPageReducer, initialState);

    const {
        state: { csrfToken },
    } = useContext(SigninContext);

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        API({
            url: API_DATA_CATEGORIES,
            headers: {
                'X-Csrf-Token': csrfToken,
            },
            cancelToken: source.token,
        })
            .then(res => {
                const selections = res.data.dataCategories;
                const basicSelections = selections.filter(s => !s.isAdvanced);
                const advancedSelections = selections.filter(s => s.isAdvanced);
                exportDataDispatch({
                    type: ACTIONS.UPDATE_BASIC_INFO_SELECTIONS,
                    payload: {
                        basicInfoSelections: basicSelections,
                    },
                });
                exportDataDispatch({
                    type: ACTIONS.UPDATE_ADVANCED_INFO_SELECTIONS,
                    payload: {
                        advancedInfoSelections: advancedSelections,
                    },
                });
                exportDataDispatch({
                    type: ACTIONS.INIT_SELECTED_ITEMS,
                    payload: {
                        selections,
                    },
                });
            })
            .catch(error => {
                console.error(error);
            });

        return () => {
            source.cancel('cancel fetchData here to avoid memory leak');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageWrapper backgroundColor="#f4f4f4">
            <StyledPageContent>
                <PageSection maxWidth={1200}>
                    <StyledIntroFlex
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        flexWrap="wrap"
                        image={exportDataImage}
                    >
                        <div className="page-hero-image" />
                        <div>
                            <Heading level="3" serif>
                                Request a copy of your data
                            </Heading>
                            <StyledHeadingParagraph fontType="h5" marginTop="sm">
                                Choose the types of information you're interested in and we'll
                                prepare a data archive for you.
                            </StyledHeadingParagraph>
                        </div>
                    </StyledIntroFlex>
                </PageSection>
            </StyledPageContent>

            <Spacer marginTop="md">
                <PageContent maxWidth={1200}>
                    <Heading level="5">
                        <strong>What to expect</strong>
                    </Heading>

                    <StyledParagraph>
                        This request will include the data that our systems have associated with you
                        or your account. When your archive is ready, we'll email you a link to a
                        file containing the personal data you've requested. You'll need to sign in
                        to your account to view and download this file. In order to ensure that you
                        receive accurate data, we will export it in the same format in which it's
                        stored — we won't manipulate it prior to sharing it with you. This means
                        that the data will be in a machine-readable format. Be aware that it may
                        take up to 45 days for our systems to gather and verify your data.
                    </StyledParagraph>

                    <CollapsibleSampleData />
                </PageContent>
            </Spacer>

            <Spacer marginTop="md">
                <PageContent maxWidth={1200}>
                    {basicInfoSelections.length > 0 && advancedInfoSelections.length > 0 ? (
                        <>
                            <SelectList
                                header="Basic information"
                                description={["Select the types of data you'd like to see:"]}
                                selections={basicInfoSelections}
                                selectedItems={selectedItems}
                                dispatch={exportDataDispatch}
                            />
                            <Spacer marginTop="xl" marginBottom="xl">
                                <SelectList
                                    header="Advanced information"
                                    description={[
                                        'These categories can be quite data heavy and take extra time to download.',
                                        "Select the types of data you'd like to see:",
                                    ]}
                                    selections={advancedInfoSelections}
                                    selectedItems={selectedItems}
                                    dispatch={exportDataDispatch}
                                />
                            </Spacer>

                            <VerificationModalDialog
                                type={REQUEST_TYPE.EXPORT}
                                buttonText="Create data archive"
                                initialVerificationContentDisplayName={VerifyEmail.displayName}
                                dataCategories={Object.keys(selectedItems).filter(
                                    id => selectedItems[id]
                                )}
                                isDisabled={
                                    Object.keys(selectedItems).filter(id => selectedItems[id])
                                        .length > 0
                                        ? false
                                        : true
                                }
                                shouldCloseOnOutsideClick={false}
                                shouldCloseOnESCKeyPress={false}
                                renderCloseButton={null}
                            />

                            <StyledLargeSpacer>
                                <Text fontType="bodySmallHeading" as="p">
                                    <strong>WHAT'S NOT INCLUDED</strong>
                                </Text>
                            </StyledLargeSpacer>

                            <SeparatorLine
                                width={16}
                                justifyContent="flex-start"
                                lgSpacingTop="20"
                                lgSpacingBottom="20"
                            />

                            <StyledParagraphWithoutTopMargin>
                                Information we get from public records and other sources about
                                properties, including square feet, number of rooms, property taxes
                                and past sales. Or the{' '}
                                <a
                                    href="https://www.zillow.com/zestimate/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Zestimate®
                                </a>
                                , Zillow's estimate of a home's market value.
                            </StyledParagraphWithoutTopMargin>

                            <StyledParagraph>
                                Details relating to a{' '}
                                <a
                                    href="https://www.zillow.com/offers/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Zillow Offer
                                </a>{' '}
                                that you've requested, including internal calculations and estimates
                                for recommended repairs and upgrades.
                            </StyledParagraph>

                            <StyledParagraph>
                                Data that isn't linked to you or your account.
                            </StyledParagraph>

                            <StyledParagraph>
                                Any photos, videos or 3D home tours that you've uploaded. These are
                                available for you to download at any time.
                            </StyledParagraph>

                            <StyledParagraph>
                                Information outside the scope of the California Consumer Privacy
                                Act.
                            </StyledParagraph>

                            <StyledParagraph>
                                Data such as disaster recovery backups, unstructured data not linked
                                to your account, and records that are retained for legal or
                                compliance purposes, that were not searched.
                            </StyledParagraph>

                            <StyledParagraph>
                                Information related to your employment or application for employment
                                with Zillow Group. Please contact your Human Resources Business
                                Partner or email
                                <a href="mailto:privacy@zillow.com"> privacy@zillow.com</a>
                            </StyledParagraph>
                        </>
                    ) : (
                        <StyledLoadingSpinnerSection isLarge />
                    )}
                </PageContent>
            </Spacer>

            <Spacer marginTop="md">
                <PageContent maxWidth={1200}>
                    <NeedHelp />
                </PageContent>
            </Spacer>
        </PageWrapper>
    );
};

export default ExportDataPage;
