import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SigninCSRAdmin from '../components/signin/SigninCSRAdmin';
import SigninLanding from '../components/signin/SigninLanding';
import SigninNoAccount from '../components/signin/SigninNoAccount';
import SigninConfirmationWithRedirect from '../components/signin/SigninConfirmationWithRedirect';
import API from '../utils/api';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import SigninContext, { ACTIONS } from '../contexts/SigninContext';
import {
    API_IDENTIFICATION_EMAIL_ONLY,
    API_IDENTIFICATION_PROVIDERS,
    ZILLOW_OAUTH_FUNC,
    ZILLOW_OAUTH_REDIRECT_URL_FUNC,
} from '../constants/ApiConstants';
import { ModalDialog } from '@zillow/constellation';
import { parseErrorResponse } from '../utils';

const StyledModalDialog = styled(ModalDialog)`
    text-align: center;
`;

const SigninModalDialog = (props, close) => {
    const {
        state: { contentType, csrfToken, redirectTo },
        dispatch,
    } = useContext(SigninContext);

    const [emailOnlyServerError, setEmailOnlyServerError] = useState('');
    const [identificationProviders, setIdentificationProviders] = useState({});

    const updateSigninContent = contentType => {
        dispatch({
            type: ACTIONS.UPDATE_SIGNIN_CONTENT,
            payload: {
                contentType,
            },
        });
    };

    let RenderContent = SigninLanding;
    let onClose = () => {};
    let newProps = {};

    if (contentType === SigninLanding.displayName) {
        if (_isEmpty(identificationProviders)) {
            API({
                url: API_IDENTIFICATION_PROVIDERS,
                headers: {
                    'X-Csrf-Token': csrfToken,
                },
            })
                .then(res => {
                    if (res && res.data) {
                        setIdentificationProviders(res.data);
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }

        newProps = {
            identificationProviders,
            closeSigninModal: () => {
                dispatch({
                    type: ACTIONS.CLOSE_SIGNIN_MODAL,
                });
            },
            goNext: () => {
                updateSigninContent(SigninNoAccount.displayName);
            },
            goAdmin: () => {
                updateSigninContent(SigninCSRAdmin.displayName);
            },
        };
    } else if (contentType === SigninNoAccount.displayName) {
        RenderContent = SigninNoAccount;
        newProps = {
            goNext: email => {
                API({
                    method: 'post',
                    url: `${API_IDENTIFICATION_EMAIL_ONLY}&email=${encodeURIComponent(email)}`,
                    headers: {
                        'X-Csrf-Token': csrfToken,
                    },
                })
                    .then(res => {
                        if (res.data && res.data.identifiers) {
                            dispatch({
                                type: ACTIONS.SET_USER_EMAILS,
                                payload: {
                                    identifiers: res.data.identifiers,
                                },
                            });
                            dispatch({
                                type: ACTIONS.IS_BRAND_ACCOUNT,
                                payload: {
                                    isBrandAccount: false,
                                },
                            });
                            dispatch({
                                type: ACTIONS.SET_PRIMARY_EMAIL,
                                payload: {
                                    primaryEmail: email,
                                },
                            });
                            dispatch({
                                type: ACTIONS.LOGIN,
                                payload: {
                                    isAuthenticated: true,
                                },
                            });
                            updateSigninContent(SigninConfirmationWithRedirect.displayName);
                        }
                    })
                    .catch(error => {
                        let errorMessage = '';
                        const providersWithAccounts = _get(
                            error,
                            'response.data.providersWithAccounts',
                            []
                        );
                        if (providersWithAccounts.length === 1) {
                            errorMessage = `The given email is associated with a ${providersWithAccounts[0]} account.`;
                        } else if (providersWithAccounts.length >= 2) {
                            const lastProvider = providersWithAccounts.pop();
                            const commaConcatenatedProviders = providersWithAccounts
                                .map(p => `a ${p} account`)
                                .join(', ');
                            errorMessage = `The given email is associated with ${commaConcatenatedProviders} and a ${lastProvider} account.`;
                        } else {
                            errorMessage = parseErrorResponse(error);
                        }
                        setEmailOnlyServerError(`Error: ${errorMessage}`);
                    });
            },
            serverError: emailOnlyServerError,
            onClickBack: () => {
                setEmailOnlyServerError('');
                updateSigninContent(SigninLanding.displayName);
            },
        };
    } else if (contentType === SigninCSRAdmin.displayName) {
        RenderContent = SigninCSRAdmin;
        newProps = {
            onSubmit: impersonatedEmail => {
                dispatch({
                    type: ACTIONS.CLOSE_SIGNIN_MODAL,
                });
                updateSigninContent(SigninLanding.displayName);
                window.location = ZILLOW_OAUTH_FUNC({
                    baseDomain: identificationProviders.zillow,
                    redirectUrl: ZILLOW_OAUTH_REDIRECT_URL_FUNC({
                        impersonatedEmail,
                    }),
                });
            },
            onClickBack: () => {
                updateSigninContent(SigninLanding.displayName);
            },
        };
    } else if (contentType === SigninConfirmationWithRedirect.displayName) {
        dispatch({
            type: ACTIONS.CLOSE_SIGNIN_MODAL,
        });
        updateSigninContent(SigninLanding.displayName);
        return <SigninConfirmationWithRedirect redirectTo={redirectTo} />;
    }

    return (
        <StyledModalDialog
            {...props}
            data-testid="signin-modal"
            onClose={() => {
                onClose();
                props.onClose();
            }}
            body={<RenderContent {...newProps} />}
        />
    );
};

SigninModalDialog.propTypes = {
    contentType: PropTypes.string.isRequired,
};

SigninModalDialog.defaultProps = {
    contentType: SigninLanding.displayName,
};

export default SigninModalDialog;
