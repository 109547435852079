import React from 'react';
import styled from 'styled-components';
import { spaceMixin, Paragraph, mediaBreakpointMixin } from '@zillow/constellation';

const StyledFooter = styled.footer`
    border-top: 1px solid ${props => props.theme.constellation?.colors.borderLight};
    text-align: center;

    > ul {
        display: none;

        @media ${mediaBreakpointMixin('lg')} {
            max-width: 1200px;
            margin: 0 auto;
            display: flex;
            justify-content: space-around;
            padding: ${spaceMixin('lg')} 0;
            border-bottom: 1px solid ${props => props.theme.constellation?.colors.borderLight};

            a {
                color: ${props => props.theme.constellation?.colors.textDark};
            }
        }
    }
`;

const Footer = () => (
    <StyledFooter>
        <Paragraph marginTop="lg" marginBottom="lg">
            ©{new Date().getFullYear()} Zillow Group, All Rights Reserved.
        </Paragraph>
    </StyledFooter>
);

export default Footer;
