import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _pickBy from 'lodash/pickBy';

import { ACTIONS } from '../pages/ExportDataPage';
import {
    Checkbox,
    Heading,
    Label,
    Paragraph,
    Spacer,
    TextButton,
    spaceMixin,
} from '@zillow/constellation';

const StyledSelection = styled(Spacer)`
    > ul {
        padding: 0;

        > li {
            display: flex;
            align-items: center;
            border-bottom: 1px solid ${props => props.theme.constellation?.colors.borderLight};
            padding: ${spaceMixin('md')} 0;

            > input {
                flex-shrink: 0;
                margin-right: ${spaceMixin('md')};
            }
        }
    }
`;

const StyledTextButton = styled(TextButton)`
    font-size: 16px;
    margin-top: ${spaceMixin('md')};
`;

const StyledParagraph = styled(Paragraph)`
    max-width: 792px;
`;

const SelectList = props => {
    const { description, header, selections, selectedItems, dispatch } = props;

    const selectionNames = selections.map(sel => sel.name);

    const doesSelectedAll =
        selections.length ===
        Object.keys(
            _pickBy(
                selectedItems,
                (value, key) => selectionNames.indexOf(key) >= 0 && value === true
            )
        ).length;

    return (
        <StyledSelection>
            <Heading level="5">
                <strong>{header}</strong>
            </Heading>

            {description.map(d => (
                <Spacer marginTop="md" key={d}>
                    <StyledParagraph>{d}</StyledParagraph>
                </Spacer>
            ))}

            <ul>
                {selections.map(sel => (
                    <li key={sel.name}>
                        <Checkbox
                            id={sel.name}
                            checked={!!selectedItems[sel.name]}
                            onChange={() => {
                                dispatch({
                                    type: ACTIONS.TOGGLE_CHECKBOX,
                                    payload: {
                                        name: sel.name,
                                    },
                                });
                            }}
                        />
                        <div>
                            <Label controlId={sel.name} size="lg">
                                {sel.displayName}
                            </Label>
                            <StyledParagraph>{sel.description}</StyledParagraph>
                        </div>
                    </li>
                ))}

                {doesSelectedAll ? (
                    <StyledTextButton
                        onClick={() => {
                            dispatch({
                                type: ACTIONS.UNCHECK_ALL,
                                payload: {
                                    names: selections.map(sel => sel.name),
                                },
                            });
                        }}
                    >
                        Unselect all
                    </StyledTextButton>
                ) : (
                    <StyledTextButton
                        onClick={() => {
                            dispatch({
                                type: ACTIONS.CHECK_ALL,
                                payload: {
                                    names: selections.map(sel => sel.name),
                                },
                            });
                        }}
                    >
                        Select all
                    </StyledTextButton>
                )}
            </ul>
        </StyledSelection>
    );
};

SelectList.propTypes = {
    header: PropTypes.string,
    description: PropTypes.arrayOf(PropTypes.string),
    selections: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            displayName: PropTypes.string,
            description: PropTypes.string,
        })
    ),
};

export default SelectList;
