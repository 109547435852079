import React, { useContext, useMemo } from 'react';
import queryString from 'query-string';
import API from '../utils/api';
import SigninContext from '../contexts/SigninContext';
import SigninFlow from '../components/signin/SigninFlow';

import {
    API_IDENTIFICATION_OAUTH_FUNC,
    API_IDENTIFICATION_ADMIN_FUNC,
    ZILLOW_OAUTH_REDIRECT_URL,
    ZILLOW_OAUTH_REDIRECT_URL_FUNC,
} from '../constants/ApiConstants';
import { withRouter } from 'react-router-dom';

const ZillowOAuthPage = ({ location }) => {
    const code = queryString.parse(location.search)['code'] || null;
    const impersonatedEmail = queryString.parse(location.search)['email'] || null;

    const {
        state: { csrfToken },
    } = useContext(SigninContext);

    const signinPromise = useMemo(
        () =>
            API({
                method: 'post',
                url: impersonatedEmail
                    ? API_IDENTIFICATION_ADMIN_FUNC({
                          authorizationCode: code,
                          impersonatedEmail,
                          redirectUrl: ZILLOW_OAUTH_REDIRECT_URL_FUNC({
                              impersonatedEmail,
                          }),
                      })
                    : API_IDENTIFICATION_OAUTH_FUNC({
                          authorizationCode: code,
                          redirectUrl: ZILLOW_OAUTH_REDIRECT_URL,
                      }),
                headers: {
                    'X-Csrf-Token': csrfToken,
                },
            }),
        [code, csrfToken, impersonatedEmail]
    );

    return useMemo(() => <SigninFlow signinPromise={signinPromise} />, [signinPromise]);
};

export default withRouter(ZillowOAuthPage);
