import React from 'react';
import styled from 'styled-components';
import { Paragraph, Spacer, Text, spaceMixin } from '@zillow/constellation';
import { Link } from 'react-router-dom';
import CollapsibleSampleData from '../components/CollapsibleSampleData';

const StyledParagraph = styled(Paragraph)`
    margin-top: ${spaceMixin('md')};
`;

const StyledOl = styled.ol`
    margin-top: ${spaceMixin('sm')};
    margin-left: ${spaceMixin('md')};
    list-style: decimal inside none;
    color: ${props => props.theme.constellation?.colors.textDark};

    > li {
        margin-top: ${spaceMixin('xs')};
    }
`;

const StyledUl = styled.ul`
    margin-top: ${spaceMixin('sm')};
    margin-left: ${spaceMixin('md')};
    list-style: disc inside none;
    color: ${props => props.theme.constellation?.colors.textDark};

    > li {
        margin-top: ${spaceMixin('xs')};
    }
`;

export const FAQ = [
    {
        question:
            "You've listed one of my old email accounts, but that account is now closed and I can't access it. How can I request my data?",
        answer: () => (
            <StyledParagraph>
                To protect your privacy, we need to ensure that you are the person making the
                request. If we can't verify that the request is coming from you, we won't be able to
                export the data associated with your account. You do have the option of updating the
                email address associated with your account(s) before submitting a request.
            </StyledParagraph>
        ),
    },
    {
        question: 'What happens when I request a copy of my data?',
        answer: () => (
            <>
                <StyledParagraph>
                    You can request an archive of your personal data <Link to="/export">here</Link>.
                    Be aware that it may take up to 45 days for our systems to gather and verify
                    your data. When the process is complete, we will email you a link to a file
                    containing your personal data. You'll need to sign in to your account to view
                    and download the file. In order to ensure that you receive accurate data, we
                    export it in the same format in which it's stored — we won't manipulate it prior
                    to sharing it with you. This means that the data will be in a machine-readable
                    format, such as a JSON file.
                </StyledParagraph>
                <CollapsibleSampleData />
            </>
        ),
    },
    {
        question: 'How long will it take to receive my data?',
        answer: () => (
            <StyledParagraph>
                We strive to provide the data you've requested as soon as possible. However, some
                time is required to compile your data and ensure that your data archive will be
                thorough and secure. Under the California Consumer Privacy Act, we are required to
                provide your data within 45 days of your request. Under certain circumstances — for
                example, if we encounter technical difficulties — we might require up to an
                additional 90 days. If your data is delayed for any reason, we'll let you know by
                email before 45 days have elapsed.
            </StyledParagraph>
        ),
    },
    {
        question: 'Why are you using JSON, XML and CSB files for my data archive?',
        answer: () => (
            <StyledParagraph>
                We export your data in the format in which we normally keep and use it. We provide
                your data in these machine-readable formats to maintain the integrity of the data
                and ensure that you can easily share it with another provider if you choose. JSON
                (JavaScript Object Notation), XML (Extensible Markup Language) and CSB (Corel Binary
                Script) are all common file types for sharing data from one system to another.
            </StyledParagraph>
        ),
    },
    {
        question: 'Can I see my data in other file types (Excel, PDF, etc.)?',
        answer: () => (
            <StyledParagraph>
                In order to maintain the integrity of your data, we don't manipulate it prior to
                sharing it with you. We provide it as it's stored and used in our systems — in JSON,
                XML or CSB files — to ensure that what you're receiving is as accurate as possible.
            </StyledParagraph>
        ),
    },
    {
        question: "What's the difference between deactivating my account and deleting my data?",
        answer: () => (
            <>
                <StyledParagraph>
                    Deactivating (or closing) an account is immediate and reversible — you can
                    always decide to reactivate it later. Be aware that as long as your account is
                    deactivated, you'll be unable to use some of our services, such as online rental
                    applications, requesting a Zillow Offer on your home or applying for a mortgage
                    online.
                </StyledParagraph>
                <StyledParagraph>
                    Deleting your data, on the other hand, is permanent.
                    <strong>
                        {' '}
                        Once you delete your data, you will lose access to your account{' '}
                    </strong>
                    and will be unable to retrieve any associated personal information.
                </StyledParagraph>
            </>
        ),
    },
    {
        id: 'deactivate-account',
        question: 'How do I deactivate my account?',
        answer: () => (
            <>
                <StyledParagraph>
                    To deactivate a <strong>Zillow</strong> account:
                </StyledParagraph>
                <StyledOl>
                    <li>
                        Log in to your Zillow account on{' '}
                        <strong>our desktop or mobile web site.</strong>
                    </li>
                    <li>
                        Select <strong>Account settings.</strong>.
                    </li>
                    <li>
                        Select <strong>Profile</strong>.
                    </li>
                    <li>
                        Select <strong>Deactivate Account</strong>.
                    </li>
                    <li>Select a reason for deactivating your account.</li>
                    <li>
                        Select <strong>Deactivate Account</strong> on this page to confirm your
                        request.
                    </li>
                </StyledOl>
                <StyledParagraph>
                    To deactivate a <strong>Trulia</strong> account,{' '}
                    <a
                        href="https://support.trulia.com/hc/en-us/requests/new"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        use this request form
                    </a>
                    .
                </StyledParagraph>
                <StyledParagraph>
                    To deactivate a <strong>HotPads</strong> account,{' '}
                    <a
                        href="https://help.hotpads.com/hc/en-us/requests/new"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        use this request form
                    </a>
                    .
                </StyledParagraph>
            </>
        ),
    },
    {
        question: 'How do I delete my data?',
        answer: () => (
            <StyledParagraph>
                You can request to permanently delete your personal data{' '}
                <Link to="/delete">here</Link>.
            </StyledParagraph>
        ),
    },
    {
        question: 'How long will it take to delete my data?',
        answer: () => (
            <StyledParagraph>
                After you verify your email, we will start the process of deleting your data. We aim
                to complete the process as soon as possible. However, because of the complexity of
                our data systems and the number of requests we receive, some time is required for
                this process and to ensure that your data is deleted safely and completely. Under
                the California Consumer Privacy Act, we are required to delete your data within 45
                days of your request. Under certain circumstances — for example, if we encounter
                technical difficulties — we might need up to an additional 90 days. If your data is
                delayed for any reason, we'll let you know by email before 45 days have elapsed.
            </StyledParagraph>
        ),
    },
    {
        question: "Is there any data you won't delete?",
        answer: () => (
            <>
                <StyledParagraph>
                    The following types of data won't be included in a deletion:
                </StyledParagraph>

                <StyledParagraph>
                    Information we get from public records and other sources about properties,
                    including square feet, number of rooms, property taxes and past sales. Or the{' '}
                    <a
                        href="https://www.zillow.com/zestimate/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Zestimate®
                    </a>
                    , Zillow's estimate of a home's market value.
                </StyledParagraph>

                <StyledParagraph>
                    Details relating to a{' '}
                    <a
                        href="https://www.zillow.com/offers/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Zillow Offer
                    </a>{' '}
                    that you've requested, including internal calculations and estimates for
                    recommended repairs and upgrades.
                </StyledParagraph>

                <StyledParagraph>Data that isn't linked to you or your account.</StyledParagraph>

                <StyledParagraph>
                    Information outside the scope of the California Consumer Privacy Act.
                </StyledParagraph>

                <StyledParagraph>
                    In some circumstances, we may need to retain data or keep an account active for
                    a period of time. For example:
                </StyledParagraph>

                <Spacer marginTop="md">
                    <Text fontType="smalllabel">
                        <strong>IF YOU HAVE ONGOING BUSINESS WITH US</strong>
                    </Text>
                    <Paragraph marginTop="xs">
                        We cannot delete your information if you have ongoing business with us, such
                        as a rental application, an offer on your home, automatic rent payments or
                        an active Premier Agent account.
                    </Paragraph>
                </Spacer>

                <Spacer marginTop="md">
                    <Text fontType="smalllabel">
                        <strong>IF YOU HAVE RECENTLY DONE BUSINESS WITH US</strong>
                    </Text>
                    <Paragraph marginTop="xs">
                        For legal reasons, we need to retain your information for a period of time
                        after any business transaction ends.
                    </Paragraph>
                </Spacer>

                <Spacer marginTop="md">
                    <Text fontType="smalllabel">
                        <strong>
                            IF WE'RE REQUIRED TO KEEP IT OR WE NEED IT FOR FRAUD PREVENTION
                        </strong>
                    </Text>
                    <Paragraph marginTop="xs">
                        We won't be able to delete data if we're required to keep it or if it's
                        necessary to combat fraudulent transactions and other illegal activities.
                    </Paragraph>
                </Spacer>

                <Spacer marginTop="md">
                    <Text fontType="smalllabel">
                        <strong>IF YOU HAVE A PENDING DATA ARCHIVE REQUEST</strong>
                    </Text>
                    <Paragraph marginTop="xs">
                        We cannot delete your data until we've completed any archive request you've
                        made
                    </Paragraph>

                    <StyledParagraph>
                        Some types of data can only be{' '}
                        <a
                            href="https://zillow.zendesk.com/hc/en-us/articles/203511930-How-do-I-add-or-remove-photos-of-my-home-"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            deleted by you
                        </a>{' '}
                        — including any photos, videos or 3D home tours that you have uploaded. In
                        those instances, you will need to delete that data before you submit a
                        deletion request. Once we complete a deletion request, you won't be able to
                        log in to remove any other data.
                    </StyledParagraph>
                    <StyledParagraph>
                        It's also possible that some data related to your use of our sites and apps
                        lives on your personal devices and is outside our control. If you'd like to
                        remove that data as well, we recommend you take the following steps:
                    </StyledParagraph>
                    <StyledUl>
                        <li>Clear cookies on all of your devices.</li>
                        <li>Delete and then reinstall any Zillow Group apps on your devices.</li>
                    </StyledUl>
                </Spacer>
            </>
        ),
    },
    {
        question: 'Can I delete my home from your sites?',
        answer: () => (
            <>
                <StyledParagraph>
                    No. We do not remove home detail pages, which are based on information gathered
                    from public records.
                </StyledParagraph>

                <StyledParagraph>
                    If there is incorrect information on your property page, you can{' '}
                    <a
                        href="https://www.zillow.com/sellerlanding/edityourhome/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        edit your home facts
                    </a>
                    . If your property page displays incorrect public record data,{' '}
                    <a
                        href="https://zillow.zendesk.com/hc/en-us/requests/new"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        report the issue here
                    </a>
                    .
                </StyledParagraph>

                <StyledParagraph>
                    Once you{' '}
                    <a
                        href="https://zillow.zendesk.com/hc/en-us/articles/203389324-How-do-I-claim-my-home"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        claim ownership of your home
                    </a>
                    , you can{' '}
                    <a
                        href="https://zillow.zendesk.com/hc/en-us/articles/203511930-How-do-I-add-or-remove-photos-of-my-home-"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        delete photos
                    </a>{' '}
                    from your listing.
                </StyledParagraph>
            </>
        ),
    },
    {
        question: 'Can I delete the Zestimate® on my home?',
        answer: () => (
            <StyledParagraph>
                No, but you can{' '}
                <a
                    href="https://www.zillow.com/sellerlanding/edityourhome/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    update your home facts
                </a>{' '}
                and fix any incorrect or incomplete information to ensure that your Zestimate is as
                accurate as possible. If you update your home facts, you may not see your Zestimate
                change immediately. The Zestimate recalculates daily, but new data can take up to
                two months to be fully incorporated.
            </StyledParagraph>
        ),
    },
    {
        question:
            "I had an interaction with your company that's not included in my data archive. Why is that?",
        answer: () => (
            <StyledParagraph>
                In some cases, it's possible that a communication is not linked to you, your account
                or your email. A data archive or delete request will not include any information
                like this that our systems do not associate with you or any data, like mortgage or
                credit-check data, that the California Consumer Privacy Act doesn't cover.
            </StyledParagraph>
        ),
    },
    {
        question: "I'm getting too many emails. How can I unsubscribe?",
        answer: () => (
            <StyledParagraph>
                It's not necessary to deactivate your account or delete your data to stop receiving
                emails from us. You can adjust or cancel your email subscriptions in your account
                settings on any of our sites or by clicking <strong>Unsubscribe</strong> at the
                bottom of our emails.
            </StyledParagraph>
        ),
    },
    {
        question: 'How do I adjust my cookie preferences?',
        answer: () => (
            <StyledParagraph>
                Get step-by-step instructions for adjusting your cookie preferences on our sites{' '}
                <Link to="/cookie">here</Link>.
            </StyledParagraph>
        ),
    },
    {
        question:
            "I signed contracts using dotloop, which is a Zillow Group brand. Why can't I submit a request to export or delete my dotloop data?",
        answer: () => (
            <StyledParagraph>
                If you worked with an agent to sign or complete transaction documents in dotloop and
                are having trouble accessing them, please contact your agent for assistance. If you
                executed contracts in dotloop in connection with a Zillow Offers transaction, those
                documents will be included in your deletion request.
            </StyledParagraph>
        ),
    },
];
