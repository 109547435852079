// Kudos to zrs-seller-tools
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Spacer, Flex, spaceMixin, mediaBreakpointMixin } from '@zillow/constellation';

const StyledLine = styled(Spacer)`
    border-bottom: 2px solid ${props => props.theme.constellation?.colors.brandSecondary};
    width: ${props => spaceMixin(props.width.toString())};

    @media ${mediaBreakpointMixin('lg')} {
        margin-top: ${props => spaceMixin(props.lgSpacingTop / 8)};
        margin-bottom: ${props => spaceMixin(props.lgSpacingBottom / 8)};
    }
`;

/**
 * A simple line that can be used underneath headers and between sections.
 * Takes all Foundation [Spacer](https://foundation.in.zillow.net/foundation-api.html#spacer) props, and sets sane defaults for mobile and desktop for you.
 *
 * @class      SeparatorLine (name)
 */
const SeparatorLine = props => {
    const { justifyContent, ...restProps } = props;
    return (
        <Flex justifyContent={justifyContent} display="flex">
            <StyledLine {...restProps} />
        </Flex>
    );
};

SeparatorLine.propTypes = {
    /**
     * The width of the line (takes Foundation t-shirt sizes or grid multiples)
     */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl'])]),
    /**
     * The Foundation key for the color of the line (e.g. yellow300)
     */
    color: PropTypes.string,
    justifyContent: PropTypes.string,
    spacingTop: PropTypes.string,
    spacingBottom: PropTypes.string,
    lgSpacingTop: PropTypes.string,
    lgSpacingBottom: PropTypes.string,
};

SeparatorLine.defaultProps = {
    width: 18,
    color: 'yellow400',
    justifyContent: 'center',
    spacingTop: '32',
    spacingBottom: '32',
    lgSpacingTop: '40',
    lgSpacingBottom: '40',
};

export default SeparatorLine;
