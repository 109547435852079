import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading, Text, Spacer, Alert, spaceMixin } from '@zillow/constellation';

const gridSize = '22% 16% 15% 18% 16% 22%';

const StyledGridContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: ${gridSize};
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 10px;
`;

const StyledReportContainer = styled.div`
    max-height: 350px;
    overflow: hidden;
    overflow-y: scroll;
`;

const StyledGridEvenContainer = styled.div`
    display: grid;
    background-color: ${props => props.theme.constellation?.colors.gray200};
    align-items: start;
    grid-template-columns: ${gridSize};
`;

const StyledGridOddContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: ${gridSize};
`;

const StyledHeader = styled.div`
    display: inline-block;
    padding: 10px;
    background-color: ${props => props.theme.constellation?.colors.gray400};
    min-height: ${spaceMixin('xs')};
`;
const StyledRow = styled.div`
    display: inline-block;
    padding: 5px 10px;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const OverdueRequests = props => {
    const getContent = props => {
        let content = [];
        props.data.forEach((obj, i) => {
            const StyledGridContainer = i % 2 ? StyledGridEvenContainer : StyledGridOddContainer;
            content.push(
                <StyledGridContainer key={obj.requestId}>
                    <StyledRow title={obj.userRequestId}>{obj.userRequestId}</StyledRow>
                    <StyledRow title={obj.agentName}>{obj.agentName}</StyledRow>
                    <StyledRow title={obj.messageType}>{obj.messageType}</StyledRow>
                    <StyledRow title={obj.statusType}>{obj.statusType}</StyledRow>
                    <StyledRow title={obj.statusDate}>{obj.statusDate}</StyledRow>
                    <StyledRow title={obj.date}>{obj.date}</StyledRow>
                </StyledGridContainer>
            );
        });

        return content;
    };

    const reportContent =
        props.data.length <= 0 ? (
            <Alert appearance="warning" body="No data found." />
        ) : (
            <Spacer>
                <Spacer marginTop="xs">
                    <Alert
                        body={
                            <Text>
                                Total number of records found: <strong>{props.data.length}</strong>
                            </Text>
                        }
                    />
                </Spacer>
                <StyledGridContainer>
                    <StyledHeader>UserRequestId</StyledHeader>
                    <StyledHeader>AgentName</StyledHeader>
                    <StyledHeader>RequestType</StyledHeader>
                    <StyledHeader>StatusType</StyledHeader>
                    <StyledHeader>StatusDate</StyledHeader>
                    <StyledHeader>CreateDate</StyledHeader>
                </StyledGridContainer>
                <StyledReportContainer>{getContent(props)}</StyledReportContainer>
            </Spacer>
        );

    return (
        <div>
            <Heading level="4" marginBottom="sm">
                Overdue requests in last 35 days :
            </Heading>
            {reportContent}
        </div>
    );
};

OverdueRequests.propTypes = {
    data: PropTypes.any,
};

OverdueRequests.defaultProps = {
    data: [],
};

export default OverdueRequests;
