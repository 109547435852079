import React from 'react';
import styled from 'styled-components';
import { Button } from '@zillow/constellation';
import { PageWrapper, PageContent, PageSection, StyledIntroFlex } from '../common/Page';
import { withRouter } from 'react-router-dom';
import { API_OPT_OUT_URL } from '../constants/ManageCookiePageConstants';

const StyledPageContent = styled(PageContent)`
    padding: 0;
`;

const opt_out_url = () => {
    window.open(API_OPT_OUT_URL, 'optout');
};

const OptoutTestCookiePage = () => {
    return (
        <PageWrapper backgroundColor="#f4f4f4">
            <StyledPageContent>
                <PageSection maxWidth={1000}>
                    <StyledIntroFlex
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        flexWrap="wrap"
                    >
                        <div className="page-hero-image" />
                        <div>
                            <Button
                                marginTop="md"
                                buttonType="primary"
                                onClick={() => {
                                    opt_out_url();
                                }}
                            >
                                Do not sell my personal information
                            </Button>
                        </div>
                    </StyledIntroFlex>
                </PageSection>
            </StyledPageContent>
        </PageWrapper>
    );
};

export default withRouter(OptoutTestCookiePage);
