import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading, Text, Spacer, Alert, TriggerText, spaceMixin } from '@zillow/constellation';

const gridSize = '35% 25% 25% 15%';

const StyledGridContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: ${gridSize};
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 10px;
    box-sizing: inherit;
`;

const StyledReportContainer = styled.div`
    max-height: 350px;
    overflow: hidden;
    overflow-y: scroll;
    box-sizing: inherit;
`;

const StyledGridEvenContainer = styled.div`
    display: grid;
    background-color: ${props => props.theme.constellation?.colors.gray200};
    align-items: start;
    grid-template-columns: ${gridSize};
    box-sizing: inherit;
`;

const StyledGridOddContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: ${gridSize};
`;

const StyledHeader = styled.div`
    display: grid;
    padding: 10px;
    background-color: ${props => props.theme.constellation?.colors.gray400};
    min-height: ${spaceMixin('xs')};
    text-overflow: ellipsis;
    overflow: hidden;
`;
const StyledRow = styled.div`
    display: grid;
    padding: 5px 10px;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const StylePieChartOutter = styled.div`
    position: relative;
    margin: 30px auto 2;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-image: conic-gradient(
        #f7e8ff ${props => props.slaPercentage}%,
        #b827ff ${props => props.slaPercentage}%
    );
`;

const StylePieChartInner = styled.div`
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 33px;
    height: 33px;
    border-radius: 50%;
`;

const StylePieChartSpan = styled.div`
    width: 18px;
    height: 18px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    padding: 2px;
`;

const StyledTriggerText = styled(TriggerText)`
    text-align: left;
`;

const OverdueRequests1 = props => {
    const getContent = props => {
        let content = [];
        props.data.forEach((obj, i) => {
            const StyledGridContainer = i % 2 ? StyledGridEvenContainer : StyledGridOddContainer;
            content.push(
                <StyledGridContainer>
                    <StyledRow>{obj.userRequestId}</StyledRow>
                    <StyledRow>{obj.messageType}</StyledRow>
                    <StyledRow>{obj.date}</StyledRow>
                    <StyledRow>
                        <StylePieChartOutter slaPercentage={getrequestSlaRemaining(obj.date)}>
                            <StylePieChartInner>
                                <StylePieChartSpan>
                                    <StyledTriggerText>{getRequestSla(obj.date)}</StyledTriggerText>
                                </StylePieChartSpan>
                            </StylePieChartInner>
                        </StylePieChartOutter>
                    </StyledRow>
                </StyledGridContainer>
            );
        });

        return content;
    };

    const getrequestSlaRemaining = requestcreateDate => {
        // calculating the number of days between the current date and the request created date.
        const daysSinceCreation = Math.floor(
            (new Date() - new Date(requestcreateDate)) / (1000 * 60 * 60 * 24)
        );
        // returning how many 45 day sla have passed since the request creation date.
        return Math.floor((daysSinceCreation / 45) * 100);
    };

    const getRequestSla = requestcreateDate => {
        // deadlineDate represents the same date as requestcreateDate but with the time portion set to 00:00:00.
        const deadlineDate = new Date(new Date(requestcreateDate).getTime());
        // adds 45 days to deadlineDate (deadlineDate will be 45 days after the requestcreateDate)
        deadlineDate.setDate(deadlineDate.getDate() + 45);
        // calculates the time difference in milliseconds between deadlineDate and the current date and time.
        const diffInMs = deadlineDate.getTime() - new Date().getTime();
        // converts the time difference from milliseconds to days by dividing it by the number of milliseconds in a day
        return Math.round(diffInMs / (1000 * 60 * 60 * 24));
    };

    const reportContent =
        props.data.length <= 0 ? (
            <Alert appearance="warning" body="No data found." />
        ) : (
            <Spacer>
                <Spacer marginTop="xs">
                    <Alert
                        body={
                            <Text>
                                Total number of records found: <strong>{props.data.length}</strong>
                            </Text>
                        }
                    />
                </Spacer>
                <StyledGridContainer>
                    <StyledHeader>UserRequestId</StyledHeader>
                    <StyledHeader>RequestType</StyledHeader>
                    <StyledHeader>CreateDate</StyledHeader>
                    <StyledHeader>SLA (Days)</StyledHeader>
                </StyledGridContainer>
                <StyledReportContainer>{getContent(props)}</StyledReportContainer>
            </Spacer>
        );

    return (
        <div>
            <Heading level="4" marginBottom="sm">
                Overdue requests in last 35 days :
            </Heading>
            {reportContent}
        </div>
    );
};

OverdueRequests1.propTypes = {
    data: PropTypes.any,
};

OverdueRequests1.defaultProps = {
    data: [],
};

export default OverdueRequests1;
