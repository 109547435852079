import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Heading, spaceMixin, mediaBreakpointMixin } from '@zillow/constellation';
import { Link } from 'react-router-dom';

const StyledDiv = styled.div`
    background-color: #fff;
    overflow: auto;
    padding-top: ${spaceMixin('md')};
    padding-bottom: 60px; /* height of bottom button */
    margin: ${spaceMixin('md')} 0;
    position: relative;

    @media ${mediaBreakpointMixin('lg')} {
        padding-top: ${spaceMixin('lg')};
        padding-bottom: 73px; /* height of bottom button */
        margin: ${spaceMixin('lg')} 0;
    }
`;

const StyledImage = styled.div`
    width: 125px;
    height: 125px;
    margin: 0 auto;
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;

    @media ${mediaBreakpointMixin('lg')} {
        width: 160px;
        height: 160px;
    }
`;

const StyledHeading = styled(Heading)`
    text-align: center;
    font-size: 17px;
    margin: 40px;
`;

const StyledButton = styled(Button)`
    border-radius: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 16px;
    height: 60px;

    &:focus {
        outline: none;
    }

    @media ${mediaBreakpointMixin('lg')} {
        font-size: 22px;
        font-weight: 400;
        height: 73px;
    }
`;

const ActionCard = props => {
    const {
        image,
        title,
        buttonText,
        actionLink,
        isAuthenticated,
        shouldIgnoreLogin,
        openSigninModal,
    } = props;
    let ActionButton;

    if (shouldIgnoreLogin || isAuthenticated) {
        ActionButton = (
            <Link to={actionLink}>
                <StyledButton fluid buttonType="primary" actionLink={actionLink}>
                    {buttonText}
                </StyledButton>
            </Link>
        );
    } else {
        ActionButton = (
            <StyledButton fluid buttonType="primary" onClick={openSigninModal}>
                {buttonText}
            </StyledButton>
        );
    }

    return (
        <StyledDiv>
            <StyledImage image={image} />
            <StyledHeading level="6" serif margin="xl">
                {title}
            </StyledHeading>
            {ActionButton}
        </StyledDiv>
    );
};

ActionCard.defaultProps = {
    buttonText: 'Get started',
    isAuthenticated: false,
    shouldIgnoreLogin: false,
    openSigninModal: null,
};

ActionCard.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    isAuthenticated: PropTypes.bool.isRequired,
    shouldIgnoreLogin: PropTypes.bool.isRequired,
    openSigninModal: PropTypes.func,
};

export default ActionCard;
