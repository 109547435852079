import React from 'react';
import { Formik } from 'formik';
import { Alert, Button, FormField, Input, Label, Spacer } from '@zillow/constellation';
import _omitBy from 'lodash/omitBy';
import _isEmpty from 'lodash/isEmpty';

const IdentifierToRequestIdForm = ({ onSubmit }) => (
    <Formik
        initialValues={{
            zuids: '',
            emails: '',
            tuids: '',
            beth_ids: '',
            hotpads_user_tokens: '',
            encoded_zuids: '',
            street_easy_user_ids: '',
        }}
        validate={values => {
            let errors = {};
            if (_isEmpty(_omitBy(values, _isEmpty))) {
                errors.general = 'At least one field is required';
            }
            return errors;
        }}
        onSubmit={values => onSubmit(values)}
    >
        {({ errors, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
                <FormField marginBottom="sm">
                    <Label htmlFor="zuids">zuids</Label>
                    <Input
                        name="zuids"
                        id="zuids"
                        placeholder="put comma separated zuids here"
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FormField>

                <FormField marginBottom="sm">
                    <Label htmlFor="emails">emails</Label>
                    <Input
                        name="emails"
                        id="emails"
                        placeholder="put comma separated emails here"
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FormField>

                <FormField marginBottom="sm">
                    <Label htmlFor="tuids">tuids</Label>
                    <Input
                        name="tuids"
                        id="tuids"
                        placeholder="put comma separated tuids here"
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FormField>

                <FormField marginBottom="sm">
                    <Label htmlFor="beth_ids">beth_ids</Label>
                    <Input
                        name="beth_ids"
                        id="beth_ids"
                        placeholder="put comma separated beth_ids here"
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FormField>

                <FormField marginBottom="sm">
                    <Label htmlFor="hotpads_user_tokens">hotpads_user_tokens</Label>
                    <Input
                        name="hotpads_user_tokens"
                        id="hotpads_user_tokens"
                        placeholder="put comma separated hotpads_user_tokens here"
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FormField>

                <FormField marginBottom="sm">
                    <Label htmlFor="encoded_zuids">encoded_zuids</Label>
                    <Input
                        name="encoded_zuids"
                        id="encoded_zuids"
                        placeholder="put comma separated encoded_zuids here"
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FormField>

                <FormField spacingBottom="sm">
                    <Label htmlFor="street_easy_user_ids">street_easy_user_ids</Label>
                    <Input
                        name="street_easy_user_ids"
                        id="street_easy_user_ids"
                        placeholder="put comma separated street_easy_user_ids here"
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FormField>

                {errors.general && (
                    <Spacer marginTop="xs">
                        <Alert appearance="error" body={errors.general} />
                    </Spacer>
                )}

                <Button buttonType="primary" type="submit" marginTop="sm">
                    Submit
                </Button>
            </form>
        )}
    </Formik>
);

export default IdentifierToRequestIdForm;
