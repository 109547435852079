import React from 'react';
import styled from 'styled-components';
import grayLargeAnglePattern from '../images/gray-large-angle-pattern.jpg';

import { Flex, Heading, Paragraph, Spacer } from '@zillow/constellation';
import { Link } from 'react-router-dom';

const StyledFlex = styled(Flex)`
    background-image: url(${props => props.bgPattern});
    background-size: cover;
    background-position: center;

    > section {
        padding: 165px 15px;
        margin: 0 auto;
        max-width: 620px;
        text-align: center;

        h2 {
            font-weight: 400;
            color: #fff;
        }

        > p {
            color: #fff;
        }
    }
`;

const NotFoundPage = () => {
    return (
        <StyledFlex bgPattern={grayLargeAnglePattern}>
            <section>
                <Spacer spacing="xs">
                    <Heading level="2" inverse>
                        404 Error
                    </Heading>
                </Spacer>
                <Paragraph>
                    Sorry, it looks like the content you requested doesn't exist. Return to the{' '}
                    <Link to="/">Zillow Privacy Home Page</Link>.
                </Paragraph>
            </section>
        </StyledFlex>
    );
};

export default NotFoundPage;
