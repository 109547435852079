import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import API from '../../utils/api';
import SigninContext from '../../contexts/SigninContext';
import {
    Alert,
    Button,
    ButtonGroup,
    Heading,
    ModalDialog,
    Paragraph,
    Spacer,
    Checkbox,
    Text,
    Tooltip,
    TooltipPopper,
    TriggerText,
    IconReload,
    InlineFeedback,
    LoadingButton,
    spaceMixin,
} from '@zillow/constellation';

import {
    API_REPLAY_REQUESTS,
    API_IS_USER_HAVE_FULL_ADMIN_ACCESS,
} from '../../constants/ApiConstants';

const gridSize = '12% 19% 20% 14% 15% 23%';
const modelGridSize = '20% 60% 10% 10%';
const requestButtonGridSize = '22% 64% 15%';

const StyledGridContainer = styled.div`
    font-size: 15px;
    display: grid;
    align-items: start;
    grid-template-columns: ${gridSize};
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 10px;
`;

const StyledReportContainer = styled.div`
    max-height: 350px;
    overflow: hidden;
    overflow-y: scroll;
`;

const StyledGridEvenContainer = styled.div`
    display: grid;
    background-color: ${props => props.theme.constellation?.colors.gray200};
    align-items: start;
    grid-template-columns: ${gridSize};
`;

const StyledGridOddContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: ${gridSize};
`;

const StyledHeader = styled.div`
    display: inline-block;
    padding: 10px;
    background-color: ${props => props.theme.constellation?.colors.gray400};
    min-height: ${spaceMixin('xs')};
`;

const StyledRow = styled.div`
    display: inline-block;
    padding: 5px 10px;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const StyledInnerPopUpDiv = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: ${modelGridSize};
`;

const StyledCol = styled.span`
    padding: 5px;
    padding-left: 15px;
    display: inline-block;
`;

const StyledFooterButtonsContainer = styled.div`
    width: 100%;
    text-align: right;
`;

const StyledReplayAlertContainer = styled.div`
    text-align: left;
    margin-top: 10px;
`;

const StyledInlineFeedbackDiv = styled.div`
    text-align: left;
`;

const StyledLoadingButtonDiv = styled.span`
    div[class^='StyledLoadingMask'] {
        margin-left: 14px;
    }
    button {
        padding: 9px 20px;
        height: 40px;
    }
`;

const StyledLoadingButton = styled(LoadingButton)`
    margin: 0px;
    font-family: Tahoma, Geneva, sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0px;
    text-transform: none;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    box-sizing: border-box;
    width: auto;
    border: 1px solid rgb(167, 166, 171);
    border-radius: 3px;
    transition: all 0.2s ease 0s;
    user-select: none;
    padding: 5px 20px;
    cursor: pointer;
    height: 34px;
    line-height: 22px;
    color: rgb(0, 106, 255);
    background-color: rgb(255, 255, 255);
`;

const StyledRequestsGridContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: ${requestButtonGridSize};
    margin-top: 10px;
`;

const StylePieChartOutter = styled.div`
    position: relative;
    margin: 30px auto 2;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-image: conic-gradient(
        #f7e8ff ${props => props.slaPercentage}%,
        #b827ff ${props => props.slaPercentage}%
    );
`;

const StylePieChartInner = styled.div`
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 33px;
    height: 33px;
    border-radius: 50%;
`;

const StylePieChartSpan = styled.div`
    width: 18px;
    height: 18px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    padding: 2px;
`;

const StyledParagraph = styled(Paragraph)`
    text-align: center;
`;

const StyledTriggerText = styled(TriggerText)`
    text-align: left;
`;

const StyledHeaderSpan = styled.span`
    font-weight: bold;
`;

const StyledGreenSpan = styled.span`
    color: ${props => props.theme.constellation?.colors.green500};
`;

const StyledRedSpan = styled.span`
    color: ${props => props.theme.constellation?.colors.red500};
`;

const StyledBlackSpan = styled.span`
    color: ${props => props.theme.constellation?.colors.trueBlack};
`;

const AgentsInRequestSentState = props => {
    const {
        state: { csrfToken },
    } = useContext(SigninContext);

    const [replayUserRequestIds, setReplayUserRequestIds] = useState({});
    const [replayAgentId, setReplayAgentId] = useState([]);
    const [isFinished, setIsFinished] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [agentLoadingStates, setAgentLoadingStates] = useState({});
    const [isUserBelongsToDataAgent, setIsUserBelongsToDataAgent] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [requestIds, setRequestIds] = useState([]);

    const setReplayRequest = e => {
        setErrorMessage('');
        setIsFinished(false);
        const agentId = e.target.dataset.agent;
        const userRequestId = e.target.id;
        const messageType = e.target.dataset.message;
        let userRequestIdsList = replayUserRequestIds;

        if (replayAgentId !== agentId) {
            setReplayAgentId(agentId);
        }

        if (e.target.checked) {
            userRequestIdsList[userRequestId] = messageType;
        } else {
            delete userRequestIdsList[userRequestId];
        }

        setReplayUserRequestIds({ ...userRequestIdsList });
    };

    const handleReplayRequest = () => {
        if (Object.keys(replayUserRequestIds).length < 1) {
            setErrorMessage(getErrorMessage('Please select atleast one request to replay..!'));
            return;
        }
        setIsLoading(true);
        API({
            url: API_REPLAY_REQUESTS(replayAgentId, replayUserRequestIds),
            headers: {
                'X-Csrf-Token': csrfToken,
            },
            method: 'POST',
        })
            .then(res => {
                if (res) {
                    setIsFinished(true);
                    setIsLoading(false);
                }
            })
            .catch(error => {
                console.log(error);
                setErrorMessage(getErrorMessage('Something went wrong. Please try again later..!'));
                setIsLoading(false);
            });
    };

    const getErrorMessage = errorMessageText => {
        return (
            <StyledInlineFeedbackDiv>
                <InlineFeedback appearance="error">{errorMessageText}</InlineFeedback>
            </StyledInlineFeedbackDiv>
        );
    };

    const handleOnClose = () => {
        setReplayUserRequestIds({});
        setIsFinished(false);
        setErrorMessage('');
        setIsLoading(false);
        setIsModalOpen(false);
    };

    const isOlderRequest = requestDate => {
        const currentTimeInMilliSeconds = Math.round(new Date().getTime());
        const lookBackHoursinMilliSeconds = 96 * 60 * 60 * 1000; //4 days back
        const requestDateInMilliSeconds = Math.round(new Date(requestDate).getTime());
        return requestDateInMilliSeconds < currentTimeInMilliSeconds - lookBackHoursinMilliSeconds;
    };

    const requestsInSentBodyDialog = (
        zodiacServiceName,
        agentId,
        dateAndRequestIds,
        messageTypeRequestIdsMap,
        createDateAndRequestIds
    ) => {
        setAgentLoadingStates(prevStates => ({
            ...prevStates,
            [agentId]: true,
        }));
        API({
            url: API_IS_USER_HAVE_FULL_ADMIN_ACCESS(zodiacServiceName),
            headers: {
                'X-Csrf-Token': csrfToken,
            },
            method: 'GET',
        })
            .then(res => {
                if (res) {
                    setAgentLoadingStates(prevStates => ({
                        ...prevStates,
                        [agentId]: false,
                    }));
                    setIsUserBelongsToDataAgent(res.data);
                    getRequestIds(
                        dateAndRequestIds,
                        messageTypeRequestIdsMap,
                        createDateAndRequestIds,
                        agentId
                    );
                    setIsModalOpen(true);
                    console.log(requestIds);
                }
            })
            .catch(error => {
                setIsErrorModalOpen(true);
                setAgentLoadingStates(prevStates => ({
                    ...prevStates,
                    [agentId]: false,
                }));
                setIsModalOpen(false);
                console.log(error);
            });
    };

    const getRequestIds = (
        dateAndRequestIds,
        messageTypeRequestIdsMap,
        createDateAndRequestIds,
        agentId
    ) => {
        let requestId = [];
        Object.entries(dateAndRequestIds).map(([updateRequestId, requestDate]) => {
            const messageType = messageTypeRequestIdsMap[updateRequestId];
            Object.entries(createDateAndRequestIds).map(([createRequestId, requestCreateDate]) => {
                const isDisable = !isOlderRequest(requestCreateDate);
                if (updateRequestId === createRequestId) {
                    requestId.push(
                        <StyledInnerPopUpDiv key={updateRequestId}>
                            <StyledCol spacingRight="xs">{requestCreateDate}</StyledCol>
                            <StyledCol>{updateRequestId}</StyledCol>
                            <StyledCol>
                                <Checkbox
                                    disabled={isDisable}
                                    controlId={updateRequestId}
                                    data-agent={agentId}
                                    data-message={messageType}
                                    onClick={setReplayRequest}
                                />
                            </StyledCol>
                            <StyledCol>
                                <StylePieChartOutter
                                    slaPercentage={getrequestSlaRemaining(requestCreateDate)}
                                >
                                    <StylePieChartInner>
                                        <StylePieChartSpan>
                                            <StyledTriggerText>
                                                {getRequestSla(requestCreateDate)}
                                            </StyledTriggerText>
                                        </StylePieChartSpan>
                                    </StylePieChartInner>
                                </StylePieChartOutter>
                            </StyledCol>
                        </StyledInnerPopUpDiv>
                    );
                }
                return null;
            });
            return null;
        });
        setRequestIds(requestId);
    };

    const getrequestSlaRemaining = requestcreateDate => {
        // calculating the number of days between the current date and the request created date.
        const daysSinceCreation = Math.floor(
            (new Date() - new Date(requestcreateDate)) / (1000 * 60 * 60 * 24)
        );
        // returning how many 45 day sla have passed since the request creation date.
        return Math.floor((daysSinceCreation / 45) * 100);
    };

    const getRequestSla = requestcreateDate => {
        // deadlineDate represents the same date as requestcreateDate but with the time portion set to 00:00:00.
        const deadlineDate = new Date(new Date(requestcreateDate).getTime());
        // adds 45 days to deadlineDate (deadlineDate will be 45 days after the requestcreateDate)
        deadlineDate.setDate(deadlineDate.getDate() + 45);
        // calculates the time difference in milliseconds between deadlineDate and the current date and time.
        const diffInMs = deadlineDate.getTime() - new Date().getTime();
        // converts the time difference from milliseconds to days by dividing it by the number of milliseconds in a day
        return Math.round(diffInMs / (1000 * 60 * 60 * 24));
    };

    const getContent = props => {
        let content = [];
        props.data.forEach((obj, i) => {
            const StyledGridContainer = i % 2 ? StyledGridEvenContainer : StyledGridOddContainer;
            const StyledAgentName =
                obj.agentStatus === 'ACTIVE'
                    ? StyledGreenSpan
                    : obj.agentStatus === 'INACTIVE'
                    ? StyledRedSpan
                    : StyledBlackSpan;
            const agentTooltip = (
                <Tooltip
                    body={
                        <Paragraph>
                            Agent Status :<StyledHeaderSpan> {obj.agentStatus} </StyledHeaderSpan>
                        </Paragraph>
                    }
                />
            );
            const oldestDate = Object.values(obj.createDateAndRequestIds)[0];
            content.push(
                <StyledGridContainer key={i}>
                    <StyledRow>{obj.agentId}</StyledRow>
                    <TooltipPopper triggered={agentTooltip}>
                        <StyledTriggerText>
                            <StyledAgentName>{obj.agentName}</StyledAgentName>
                        </StyledTriggerText>
                    </TooltipPopper>
                    <StyledRow>
                        <a
                            href={'https://zodiac.zgtools.net/services/' + obj.zodiacServiceName}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {obj.zodiacServiceName}
                        </a>
                    </StyledRow>
                    <StyledRow>{Object.values(obj.dateAndRequestIds).length}</StyledRow>
                    <StyledRow>{oldestDate}</StyledRow>
                    <StyledRow>
                        <StyledLoadingButton
                            buttonType="primary"
                            onClick={() =>
                                requestsInSentBodyDialog(
                                    obj.zodiacServiceName,
                                    obj.agentId,
                                    obj.dateAndRequestIds,
                                    obj.messageTypeRequestIdsMap,
                                    obj.createDateAndRequestIds
                                )
                            }
                            loading={agentLoadingStates[obj.agentId]}
                        >
                            Show Req Ids
                        </StyledLoadingButton>
                        {isModalOpen && (
                            <ModalDialog
                                isOpen={isModalOpen}
                                onClose={() => {
                                    setIsModalOpen(false);
                                }}
                                wide
                                header={
                                    <Heading level={6}>Request Ids in REQUEST_SENT state</Heading>
                                }
                                body={
                                    <>
                                        <StyledRequestsGridContainer>
                                            <StyledHeader>Create Date</StyledHeader>
                                            <StyledHeader>Request Id</StyledHeader>
                                            <StyledHeader>SLA (Days)</StyledHeader>
                                        </StyledRequestsGridContainer>
                                        <React.Fragment>{requestIds}</React.Fragment>
                                    </>
                                }
                                footer={
                                    <StyledFooterButtonsContainer>
                                        {errorMessage}
                                        <ButtonGroup>
                                            <Button buttonType="primary" onClick={handleOnClose}>
                                                Close
                                            </Button>
                                            {isLoading ? (
                                                <StyledLoadingButtonDiv>
                                                    <LoadingButton
                                                        size="lg"
                                                        buttonType="primary"
                                                        loading
                                                    >
                                                        <IconReload size="xs" /> Replaying
                                                    </LoadingButton>
                                                </StyledLoadingButtonDiv>
                                            ) : (
                                                <Button
                                                    buttonType="primary"
                                                    disabled={!isUserBelongsToDataAgent}
                                                    onClick={handleReplayRequest}
                                                >
                                                    <IconReload size="xs" /> Replay
                                                </Button>
                                            )}
                                        </ButtonGroup>
                                        {isFinished && (
                                            <StyledReplayAlertContainer>
                                                <Alert
                                                    appearance="success"
                                                    body="Updated successfully...!"
                                                />
                                            </StyledReplayAlertContainer>
                                        )}
                                        {!isUserBelongsToDataAgent && (
                                            <StyledReplayAlertContainer>
                                                <Alert
                                                    appearance="error"
                                                    body="As you are not the owner of this data agent, you can't replay this request"
                                                />
                                            </StyledReplayAlertContainer>
                                        )}
                                    </StyledFooterButtonsContainer>
                                }
                            />
                        )}
                        {isErrorModalOpen && (
                            <ModalDialog
                                isOpen={isErrorModalOpen}
                                onClose={() => {
                                    setIsErrorModalOpen(false);
                                }}
                                wide
                                dividers={false}
                                body={
                                    <StyledParagraph>
                                        Something went wrong. Please try again later..!
                                    </StyledParagraph>
                                }
                            />
                        )}
                    </StyledRow>
                </StyledGridContainer>
            );
        });
        return content;
    };

    const reportContent =
        props.data.length <= 0 ? (
            <Alert appearance="warning" body="No data found." />
        ) : (
            <Spacer>
                <Spacer marginTop="xs">
                    <Alert
                        body={
                            <Text>
                                Total number of records found: <strong>{props.data.length}</strong>
                            </Text>
                        }
                    />
                </Spacer>
                <StyledGridContainer>
                    <StyledHeader>Agent Id</StyledHeader>
                    <StyledHeader>Agent Name</StyledHeader>
                    <StyledHeader>Zodiac Service Name</StyledHeader>
                    <StyledHeader>Req Count</StyledHeader>
                    <StyledHeader>Oldest date</StyledHeader>
                    <StyledHeader>Request Ids</StyledHeader>
                </StyledGridContainer>
                <StyledReportContainer>{getContent(props)}</StyledReportContainer>
            </Spacer>
        );

    return (
        <div>
            <Heading level="4">Agents in REQUEST_SENT state :</Heading>
            {reportContent}
        </div>
    );
};

AgentsInRequestSentState.propTypes = {
    data: PropTypes.any,
};

AgentsInRequestSentState.defaultProps = {
    data: [],
};

export default AgentsInRequestSentState;
