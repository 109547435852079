import React from 'react';
import { Formik } from 'formik';
import { Alert, Button, Input, Label, Spacer } from '@zillow/constellation';

import { LOOKBACK_HOURS_MIN, LOOKBACK_HOURS_MAX } from '../../constants/ReportsConstants';

const LookBackHoursForm = ({ onSubmit }) => (
    <Formik
        initialValues={{ lookbackHoursId: '24' }}
        validate={values => {
            let errors = {};
            if (!values.lookbackHoursId) {
                errors.lookbackHoursId = 'number of hours are required';
            } else if (
                values.lookbackHoursId < LOOKBACK_HOURS_MIN ||
                values.lookbackHoursId > LOOKBACK_HOURS_MAX ||
                values.lookbackHoursId % 1 !== 0
            ) {
                errors.lookbackHoursId = `Inalid number of hours to look back. Please enter value between (${LOOKBACK_HOURS_MIN}-${LOOKBACK_HOURS_MAX}).`;
            }
            return errors;
        }}
        onSubmit={values => onSubmit(values.lookbackHoursId)}
    >
        {({ errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
                <Spacer marginTop="lg" marginBottom="md">
                    <Label htmlFor="lookback-hours-id" size="md">
                        Look-back Hours:
                    </Label>
                    <Input
                        name="lookbackHoursId"
                        id="lookback-hours-id"
                        placeholder="Default 24 hours"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        spacingRight="md"
                        spacingLeft="sm"
                        fluid={false}
                    />
                    <Button buttonType="primary" type="submit" marginTop="sm">
                        Submit
                    </Button>
                </Spacer>
                {errors.lookbackHoursId && touched.lookbackHoursId && (
                    <Spacer marginTop="xs">
                        <Alert appearance="error" body={errors.lookbackHoursId} />
                    </Spacer>
                )}
            </form>
        )}
    </Formik>
);

export default LookBackHoursForm;
