import React from 'react';
import styled from 'styled-components';
import LoadingSpinner from '../../common/LoadingSpinner';

export const DISPLAY_NAME = 'LOADING';

const StyledWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 200px;
`;

const Loading = () => (
    <StyledWrapper>
        <LoadingSpinner isLarge />
    </StyledWrapper>
);

Loading.displayName = DISPLAY_NAME;

export default Loading;
