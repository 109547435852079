import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SeparatorLine from '../../common/SeparatorLine';
import BigArrow from '../../common/BigArrow';
import EmailValidator from 'email-validator';
import { StyledErrorParagraph } from '../../common/Page';
import { Button, Heading, Input, Paragraph, TextButton } from '@zillow/constellation';

const StyledTextButton = styled(TextButton)`
    display: block;
`;

const SigninNoAccount = ({ goNext, serverError, onClickBack }) => {
    const [emailOnly, setEmailOnly] = useState('');
    const [emailOnlyError, setEmailOnlyError] = useState('');

    const onClickNext = () => {
        if (EmailValidator.validate(emailOnly)) {
            goNext(emailOnly);
            setEmailOnlyError('');
        } else {
            setEmailOnlyError('Error: invalid email');
        }
    };

    return (
        <>
            <StyledTextButton onClick={onClickBack}>
                <BigArrow direction="left" />
                Back
            </StyledTextButton>
            <Heading level="3" serif marginTop="sm">
                No account?
                <br />
                No problem
            </Heading>
            <Paragraph marginTop="lg">
                Enter an email address you've used with one of our brands and we'll find your
                information.
            </Paragraph>
            <SeparatorLine width={16} marginTop="lg" marginBottom="lg" />
            <Input
                placeholder="Email"
                type="email"
                onChange={e => {
                    setEmailOnly(e.target.value);
                    setEmailOnlyError('');
                }}
            />
            {emailOnlyError && (
                <StyledErrorParagraph marginTop="xs">Error: invalid email</StyledErrorParagraph>
            )}
            {serverError && (
                <StyledErrorParagraph marginTop="xs">{serverError}</StyledErrorParagraph>
            )}
            <Button buttonType="primary" marginTop="lg" marginBottom="md" onClick={onClickNext}>
                Continue
            </Button>
        </>
    );
};

SigninNoAccount.propTypes = {
    goNext: PropTypes.func,
    onClickBack: PropTypes.func,
    updateEmail: PropTypes.func,
};

SigninNoAccount.displayName = 'SIGNIN_NO_ACCOUNT';

export default SigninNoAccount;
