import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { spaceMixin, Paragraph, Button } from '@zillow/constellation';
import { Link } from 'react-router-dom';

export const DISPLAY_NAME = 'AlertError';

const StyledWrapper = styled.div`
    padding: ${spaceMixin('lg')} 0;
    > * {
        color: ${props => props.theme.constellation?.colors.red400};
    }
`;

const AlertError = ({ buttonText, error, redirectTo, onCloseButtonClick }) => (
    <StyledWrapper>
        <Paragraph>
            <strong>Error:</strong> {error}
        </Paragraph>
        <Paragraph marginTop="xl">
            <Link to={redirectTo}>
                <Button marginTop="sm" buttonType="primary" onClick={onCloseButtonClick}>
                    {buttonText}
                </Button>
            </Link>
        </Paragraph>
    </StyledWrapper>
);

AlertError.propTypes = {
    error: PropTypes.string,
    onCloseButtonClick: PropTypes.func.isRequired,
    buttonText: PropTypes.string,
    redirectTo: PropTypes.string,
};

AlertError.displayName = DISPLAY_NAME;

export default AlertError;
