import React from 'react';
import JiraTicketsCreatedPerDayByAgent from '../components/Reports/JiraTicketsCreatedPerDayByAgent';
import RequestReceivedPerDayByType from '../components/Reports/RequestReceivedPerDayByType';
import AgentsInErrorState from '../components/Reports/AgentsInErrorState';
import AgentsInRequestSentState from '../components/Reports/AgentsInRequestSentState';
import OverdueRequests from '../components/Reports/OverdueRequests';
import RequestsOverdue from '../components/Reports/RequestsOverdue';
import OverallAgentStatusCounts from '../components/Reports/OverallAgentStatusCounts';
import RequestsInJiraTicketCreatedState from '../components/Reports/RequestsInJiraTicketCreatedState';
import PropTypes from 'prop-types';

import {
    REQ_REC_PER_DAY_BY_TYPE,
    AGENTS_IN_ERROR_STATE,
    JIRA_TICKETS_CREATED_PER_DAY,
    AGENTS_IN_REQUEST_SENT_STATE,
    AGENTS_OVERDUE,
    OVERALL_AGENT_STATUS_COUNTS,
    REQ_JIRA_TICKETS_CREATED_STATE,
    REQUESTS_OVERDUE,
} from '../constants/ReportsConstants';

const getReportContent = props => {
    let reportContent = null;
    if (props.reportId != null) {
        switch (props.reportId) {
            case JIRA_TICKETS_CREATED_PER_DAY:
                reportContent = <JiraTicketsCreatedPerDayByAgent data={props.data} />;
                break;
            case REQ_REC_PER_DAY_BY_TYPE:
                reportContent = <RequestReceivedPerDayByType data={props.data} />;
                break;
            case AGENTS_IN_ERROR_STATE:
                reportContent = <AgentsInErrorState data={props.data} />;
                break;
            case AGENTS_IN_REQUEST_SENT_STATE:
                reportContent = <AgentsInRequestSentState data={props.data} />;
                break;
            case AGENTS_OVERDUE:
                reportContent = <OverdueRequests data={props.data} />;
                break;
            case REQUESTS_OVERDUE:
                reportContent = <RequestsOverdue data={props.data} />;
                break;
            case OVERALL_AGENT_STATUS_COUNTS:
                reportContent = (
                    <OverallAgentStatusCounts
                        data={props.data}
                        onSubmitLookBackHoursForm={props.submitFormData}
                    />
                );
                break;
            case REQ_JIRA_TICKETS_CREATED_STATE:
                reportContent = <RequestsInJiraTicketCreatedState data={props.data} />;
                break;
            default:
                console.log('unable to find such report.');
        }
    }
    return reportContent;
};

const ReportsPage = props => {
    return <div>{getReportContent(props)}</div>;
};

ReportsPage.propTypes = {
    data: PropTypes.any,
    reportId: PropTypes.string,
    submitFormData: PropTypes.func,
};

ReportsPage.defaultProps = {
    data: [],
    reportId: '',
};

export default ReportsPage;
