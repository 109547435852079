import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TextButton, IconCloseCircle, Paragraph, spaceMixin } from '@zillow/constellation';

export const DISPLAY_NAME = 'AlertOptOutOfSaleError';

const StyledWrapper = styled.div`
    padding: ${spaceMixin('lg')} 0;
    padding-top: 5px;
`;

const StyledParagraph = styled(Paragraph)`
    max-width: 792px;
    margin-top: ${spaceMixin('md')};
    margin-bottom: ${spaceMixin('md')};
`;

const StyledIconCloseCircle = styled(IconCloseCircle)`
    font-size: 60px;
`;

const StyledParagraphFooter = styled(Paragraph)`
    max-width: 792px;
    margin-top: ${spaceMixin('xl')};
`;

const StyledErrorIcon = styled.div`
    margin-bottom: 20px;
`;

const AlertOptOutOfSaleError = ({ error, callbackFun, onCloseButtonClick }) => (
    <StyledWrapper>
        <Paragraph>
            <StyledErrorIcon>
                {' '}
                <strong>
                    <StyledIconCloseCircle color="red" />
                </strong>
            </StyledErrorIcon>
            <StyledParagraph>
                <div>
                    We were unable to complete your request at this time. Please try again later or
                    contact us at <a href="mailto: privacy@zillow.com">privacy@zillow.com</a>
                </div>
            </StyledParagraph>
            <StyledParagraph>
                <div>
                    As always , you can manually control cookies on{' '}
                    <a href="/cookie">Privacy Portal</a>
                </div>
            </StyledParagraph>
            <StyledParagraphFooter>
                <strong>
                    <a href="/">Back to Privacy Portal</a>
                </strong>
            </StyledParagraphFooter>
        </Paragraph>
        <Paragraph marginTop="xl">
            <TextButton
                as="a"
                onClick={() => {
                    onCloseButtonClick();
                }}
            >
                Back to Manage cookies
            </TextButton>
        </Paragraph>
    </StyledWrapper>
);

AlertOptOutOfSaleError.propTypes = {
    error: PropTypes.string,
    callbackFun: PropTypes.func.isRequired,
    onCloseButtonClick: PropTypes.func.isRequired,
};

AlertOptOutOfSaleError.displayName = DISPLAY_NAME;

export default AlertOptOutOfSaleError;
