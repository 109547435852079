import React from 'react';
import styled from 'styled-components';
import JSONPretty from 'react-json-pretty';
import BigArrow from '../common/BigArrow';

import { EXAMPLE_EXPORT_DATA } from '../constants/ExportDataPageConstants';
import { StyledExpandTextButton } from '../common/Page';
import { Collapsible, Spacer, Text } from '@zillow/constellation';

const StyledJSONPretty = styled(JSONPretty)`
    pre {
        font-size: 14px;
        white-space: pre-wrap;
    }
`;

const CollapsibleSampleData = () => (
    <Collapsible defaultCollapsed>
        {(collapsed, toggle) => (
            <>
                {collapsed ? null : (
                    <Spacer marginTop="xl">
                        <Text>
                            <strong>Sample format</strong>
                        </Text>
                        <Spacer marginTop="md">
                            <StyledJSONPretty
                                data={EXAMPLE_EXPORT_DATA}
                                space="4"
                            ></StyledJSONPretty>
                        </Spacer>
                    </Spacer>
                )}
                <Spacer marginTop="xl">
                    <StyledExpandTextButton fontType="bodySmall" onClick={toggle}>
                        {collapsed ? (
                            <>
                                SEE A SAMPLE OF THE DATA FORMAT{' '}
                                <BigArrow className="down-arrow" direction="down" />
                            </>
                        ) : (
                            <>
                                CLOSE <BigArrow className="up-arrow" direction="up" />
                            </>
                        )}
                    </StyledExpandTextButton>
                </Spacer>
            </>
        )}
    </Collapsible>
);

export default CollapsibleSampleData;
