import React, { useContext, useMemo } from 'react';
import queryString from 'query-string';
import API from '../utils/api';
import SigninContext from '../contexts/SigninContext';
import SigninFlow from '../components/signin/SigninFlow';

import { API_IDENTIFICATION_SSO_FUNC } from '../constants/ApiConstants';
import { withRouter } from 'react-router-dom';

const TruliaSSOPage = ({ location }) => {
    const { signedData } = queryString.parse(location.search);
    const { data, signature } = JSON.parse(signedData);
    const { tuid, email, timestamp } = JSON.parse(unescape(data));
    const ssoData = unescape(data);

    const {
        state: { csrfToken },
    } = useContext(SigninContext);
    const signinPromise = useMemo(
        () =>
            API({
                method: 'post',
                url: API_IDENTIFICATION_SSO_FUNC({
                    userId: tuid,
                    mac: signature,
                    provider: 'TRULIA',
                    ssoData: encodeURIComponent(ssoData),
                    email: encodeURIComponent(JSON.stringify(email)), // for BE signature validation purpose
                    timestamp,
                }),
                headers: {
                    'X-Csrf-Token': csrfToken,
                },
            }),
        [csrfToken, email, signature, timestamp, tuid, ssoData]
    );

    return useMemo(() => <SigninFlow signinPromise={signinPromise} />, [signinPromise]);
};

export default withRouter(TruliaSSOPage);
