// Kudos to zrs-zillow-offers
import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from './LoadingSpinner';
import styled from 'styled-components';
import {
    Flex,
    Paragraph,
    TextButton,
    fontWeightMixin,
    spaceMixin,
    mediaBreakpointMixin,
} from '@zillow/constellation';

export const PageWrapper = styled.div`
    background-color: ${props => props.backgroundColor || '#fff'};
    min-height: ${props => (props.withoutNav ? '100vh' : 'calc(100vh - 50px)')};
    text-align: ${props => (props.centeredContent ? 'center' : 'left')};
    padding-bottom: ${spaceMixin('xl')};

    @media ${mediaBreakpointMixin('lg')} {
        display: ${props => (props.centeredContent ? 'flex' : 'block')};
        flex-direction: column;
        justify-content: center;
        min-height: ${props => (props.withoutNav ? '100vh' : 'calc(100vh - 80px)')};
    }
`;

export const PageMainContent = styled.main`
    background-color: ${props => props.backgroundColor || '#fff'};
    margin: 0 auto;
    max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : null)};
    overflow: auto;

    @media ${mediaBreakpointMixin('lg')} {
        padding-bottom: ${spaceMixin('lg')};
    }
`;

export const PageContent = styled.div`
    margin: 0 auto;
    padding-top: ${spaceMixin('md')};
    padding-bottom: ${spaceMixin('md')};
    padding-left: ${props => (props.noSidePadding ? null : spaceMixin('md'))};
    padding-right: ${props => (props.noSidePadding ? null : spaceMixin('md'))};
    background-color: ${props => props.backgroundColor || '#fff'};
    max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : null)};
    overflow: auto;
`;

export const PageSection = styled.section`
    margin: ${spaceMixin('md')} auto;
    padding-top: ${spaceMixin('md')};
    padding-bottom: ${spaceMixin('md')};
    padding-left: ${props => (props.noSidePadding ? null : spaceMixin('md'))};
    padding-right: ${props => (props.noSidePadding ? null : spaceMixin('md'))};
    max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : null)};
    overflow: auto;
`;

export const StyledIntroFlex = styled(Flex)`
    .page-hero-image {
        background-image: url(${props => props.image});
        background-size: cover;
        background-position: center;
        width: 180px;
        height: 180px;
        margin-right: ${spaceMixin('md')};
        margin-bottom: ${spaceMixin('md')};

        @media ${mediaBreakpointMixin('lg')} {
            margin-bottom: 0;
        }
    }

    > div:last-child {
        max-width: 620px;
    }
`;

export const StyledExpandTextButton = styled(TextButton)`
    color: #444;
    :focus {
        outline: none;
    }

    .down-arrow {
        transform: translate(0.5em, -0.15em);
    }
    .up-arrow {
        transform: translate(0.5em, 0.2em);
    }
`;

export const StyledErrorParagraph = styled(Paragraph)`
    color: ${props => props.theme.constellation?.colors.red400};
    font-size: 14px;
    font-weight: 600;
    text-align: left;
`;

const StyledLoadingSpinnerWrapper = styled.section`
    background-color: #fff;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledHeadingParagraph = styled(Paragraph)`
    line-height: ${spaceMixin('lg')};
    font-weight: ${fontWeightMixin('body')};
`;

export const StyledLoadingSpinnerSection = props => (
    <StyledLoadingSpinnerWrapper>
        <LoadingSpinner {...props} />
    </StyledLoadingSpinnerWrapper>
);

/**
 * A page wrapper that creates a gray background to cover the page.
 * This would most commonly be used to place [`<Card>`](https://foundation.in.zillow.net/foundation-api.html#card) elements on, but any content is allowed.
 */
const Page = props => (
    <PageWrapper {...props}>
        <PageContent maxWidth={props.maxWidth}>{props.children}</PageContent>
    </PageWrapper>
);

Page.propTypes = {
    /**
     * Should the content within the page be vertically centered?
     */
    centeredContent: PropTypes.bool,
    children: PropTypes.node,
    /**
     * The max width of the page content (in pixels).
     */
    maxWidth: PropTypes.number,
    /**
     * Is the page rendered without top nav? If true, the page
     * set its min-height to 100vh and will not leave room for a top nav.
     * */
    withoutNav: PropTypes.bool,
};

Page.defaultProps = {
    centeredContent: false,
    children: null,
    maxWidth: null,
    withoutNav: false,
};

export default Page;
