import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { spaceMixin, Heading, Paragraph } from '@zillow/constellation';

const StyledParagraph = styled(Paragraph)`
    max-width: 792px;
    margin-top: ${spaceMixin('md')};
`;

const NeedHelp = () => (
    <>
        <Heading level="5">
            <strong>Need more help?</strong>
        </Heading>

        <Paragraph marginTop="md" marginBottom="lg">
            Have questions? We have answers — visit our{' '}
            <Link to="/support">Privacy Help Center</Link>.
        </Paragraph>

        <StyledParagraph>
            For information related to your employment or application for employment with Zillow
            Group, please contact your Human Resources Business Partner or email{' '}
            <a href="mailto:privacy@zillow.com">privacy@zillow.com</a>
        </StyledParagraph>
    </>
);

export default NeedHelp;
