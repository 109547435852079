import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SeparatorLine from '../common/SeparatorLine';
import BigArrow from '../common/BigArrow';
import {
    Card,
    Collapsible,
    Flex,
    Heading,
    Paragraph,
    Spacer,
    spaceMixin,
    mediaBreakpointMixin,
} from '@zillow/constellation';
import { StyledExpandTextButton } from '../common/Page';

const StyledDiv = styled.div`
    background-color: #fff;
    overflow: auto; /* https://stackoverflow.com/questions/1878997/child-elements-with-margins-within-divs */
    margin-bottom: ${spaceMixin('md')};

    @media ${mediaBreakpointMixin('lg')} {
        border-radius: 3px;
    }
`;

const StyledBorderlessCard = styled(Card)`
    border: 0 none;
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;

    @media ${mediaBreakpointMixin('lg')} {
        width: 32%;
    }
`;

const StyledFlex = styled(Flex)`
    display: flex;
    flex-direction: column;
    justify-content: space-between @media ${mediaBreakpointMixin('lg')} {
        flex-direction: row;
    }
`;

const CollapsibleCard = props => {
    const { mainTitle, mainDescription, infoList } = props;

    // ${mediaMixin( 'md', css` height: 305px; ` )}
    // margin={{ default: 'xs', lg: 'sm' }}

    return (
        <StyledDiv>
            <Spacer margin={{ default: 'md', lg: 'xl' }}>
                <Collapsible defaultCollapsed>
                    {(collapsed, toggle) => (
                        <>
                            <Heading serif level="4">
                                {mainTitle}
                            </Heading>
                            <Paragraph marginTop="sm" marginBottom="xl">
                                {mainDescription}
                            </Paragraph>
                            {collapsed ? null : (
                                <>
                                    <Heading level="5">For example:</Heading>
                                    <StyledFlex
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="space-between"
                                        mediaQuery={{ lg: { flexDirection: 'row' } }}
                                    >
                                        {infoList.map(info => {
                                            return (
                                                <StyledBorderlessCard key={info.title}>
                                                    <Heading level="6" fontType="body">
                                                        {info.title}
                                                    </Heading>
                                                    <SeparatorLine
                                                        width={6}
                                                        justifyContent="flex-start"
                                                        lgSpacingTop="16"
                                                        lgSpacingBottom="16"
                                                    />
                                                    <Paragraph>{info.description}</Paragraph>
                                                </StyledBorderlessCard>
                                            );
                                        })}
                                    </StyledFlex>
                                </>
                            )}
                            <Spacer marginTop="sm">
                                <StyledExpandTextButton fontType="bodySmall" onClick={toggle}>
                                    {collapsed ? (
                                        <>
                                            SEE EXAMPLES{' '}
                                            <BigArrow className="down-arrow" direction="down" />
                                        </>
                                    ) : (
                                        <>
                                            CLOSE <BigArrow className="up-arrow" direction="up" />
                                        </>
                                    )}
                                </StyledExpandTextButton>
                            </Spacer>
                        </>
                    )}
                </Collapsible>
            </Spacer>
        </StyledDiv>
    );
};

CollapsibleCard.propTypes = {
    mainTitle: PropTypes.string.isRequired,
    mainDescription: PropTypes.string.isRequired,
    infoList: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
        })
    ),
};

export default CollapsibleCard;
