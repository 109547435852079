import { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { API_CSRF } from '../constants/ApiConstants';
import API from '../utils/api';
import axios from 'axios';
import SigninContext, { ACTIONS as SIGNIN_ACTIONS } from '../contexts/SigninContext';

const GetCsrfToken = ({ children, location: { pathname } }) => {
    const {
        state: { csrfToken },
        dispatch: signinDispatch,
    } = useContext(SigninContext);

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        API({ url: API_CSRF, cancelToken: source.token })
            .then(res => {
                if (res && res.data && res.data.csrfToken) {
                    if (csrfToken !== null && csrfToken !== res.data.csrfToken) {
                        signinDispatch({
                            type: SIGNIN_ACTIONS.LOGOUT,
                        });
                    }
                    if (csrfToken !== res.data.csrfToken) {
                        signinDispatch({
                            type: SIGNIN_ACTIONS.UPDATE_CSRF,
                            payload: {
                                csrfToken: res.data.csrfToken,
                            },
                        });
                    }
                }
            })
            .catch(error => {
                console.error(error);
            });

        return () => {
            source.cancel('cancel getCsrfToken here to avoid memory leak');
        };
    }, [csrfToken, pathname, signinDispatch]);

    return children || null;
};

export default withRouter(GetCsrfToken);
