import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    Alert,
    Heading,
    Paragraph,
    Spacer,
    Text,
    Tooltip,
    TooltipPopper,
    TriggerText,
    spaceMixin,
} from '@zillow/constellation';
import LookBackHoursForm from './LookBackHoursForm';

const gridSize = '8% 21% 21% 21% 19% 10%';

const StyledGridContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: ${gridSize};
    overflow: hidden;
    overflow-y: scroll;
    margin-top: ${spaceMixin('xs')};
`;

const StyledReportContainer = styled.div`
    max-height: 350px;
    overflow: hidden;
    overflow-y: scroll;
`;

const StyledGridEvenContainer = styled.div`
    display: grid;
    background-color: ${props => props.theme.constellation?.colors.gray200};
    align-items: start;
    grid-template-columns: ${gridSize};
`;

const StyledGridOddContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: ${gridSize};
`;

const StyledHeader = styled.div`
    display: inline-block;
    padding: ${spaceMixin('xs')};
    background-color: ${props => props.theme.constellation?.colors.gray400};
    min-height: ${spaceMixin('xs')};
    text-align: center;
`;

const StyledRow = styled.div`
    display: inline-block;
    padding: 5px 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
`;

const StyledTriggerText = styled(TriggerText)`
    text-align: left;
`;

const StyledHeaderSpan = styled.span`
    font-weight: bold;
`;

const StyledGreenSpan = styled.span`
    color: ${props => props.theme.constellation?.colors.green500};
`;

const StyledRedSpan = styled.span`
    color: ${props => props.theme.constellation?.colors.red500};
`;

const StyledBlackSpan = styled.span`
    color: ${props => props.theme.constellation?.colors.trueBlack};
`;

const getContent = props => {
    let content = [];
    props.data.forEach((obj, i) => {
        const StyledGridContainer = i % 2 ? StyledGridEvenContainer : StyledGridOddContainer;
        const StyledAgentName =
            obj.agentStatus === 'ACTIVE'
                ? StyledGreenSpan
                : obj.agentStatus === 'INACTIVE'
                ? StyledRedSpan
                : StyledBlackSpan;
        const agentTooltip = (
            <Tooltip
                body={
                    <Paragraph>
                        Agent Status :<StyledHeaderSpan> {obj.agentStatus} </StyledHeaderSpan>
                    </Paragraph>
                }
            />
        );
        content.push(
            <StyledGridContainer key={obj.agentId + obj.requestStatus}>
                <StyledRow title={obj.agentId}>{obj.agentId}</StyledRow>
                <StyledRow>
                    <TooltipPopper triggered={agentTooltip}>
                        <StyledTriggerText>
                            <StyledAgentName>{obj.agentName}</StyledAgentName>
                        </StyledTriggerText>
                    </TooltipPopper>
                </StyledRow>
                <StyledRow>
                    <a
                        href={'https://zodiac.zgtools.net/services/' + obj.zodiacServiceName}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {obj.zodiacServiceName}
                    </a>
                </StyledRow>
                <StyledRow title={obj.zodiacTeamName}>{obj.zodiacTeamName}</StyledRow>
                <StyledRow title={obj.requestStatus}>{obj.requestStatus}</StyledRow>
                <StyledRow title={obj.statusCnt}>{obj.statusCnt}</StyledRow>
            </StyledGridContainer>
        );
    });
    return content;
};

const OverallAgentStatusCounts = props => {
    const reportContent =
        props.data.length <= 0 ? (
            <Alert appearance="warning" body="No data found." />
        ) : (
            <Spacer>
                <Spacer marginTop="xs">
                    <Alert
                        body={
                            <Text>
                                Total number of records found: <strong>{props.data.length}</strong>
                            </Text>
                        }
                    />
                </Spacer>
                <StyledGridContainer>
                    <StyledHeader>Agent Id</StyledHeader>
                    <StyledHeader>Agent Name</StyledHeader>
                    <StyledHeader>Zodiac Service Name</StyledHeader>
                    <StyledHeader>Zodiac Team Name</StyledHeader>
                    <StyledHeader>Status Type</StyledHeader>
                    <StyledHeader>Status Count</StyledHeader>
                </StyledGridContainer>
                <StyledReportContainer>{getContent(props)}</StyledReportContainer>
            </Spacer>
        );
    return (
        <div>
            <Heading level="4">Overall Request count per agent per status type:</Heading>
            <LookBackHoursForm onSubmit={props.onSubmitLookBackHoursForm} />
            {reportContent}
        </div>
    );
};

OverallAgentStatusCounts.propTypes = {
    data: PropTypes.any,
    onSubmitLookBackHoursForm: PropTypes.func,
};

OverallAgentStatusCounts.defaultProps = {
    data: [],
};

export default OverallAgentStatusCounts;
