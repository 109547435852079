import React, { useContext, useMemo } from 'react';
import queryString from 'query-string';
import API from '../utils/api';
import SigninContext from '../contexts/SigninContext';
import SigninFlow from '../components/signin/SigninFlow';

import { API_IDENTIFICATION_SSO_FUNC } from '../constants/ApiConstants';
import { withRouter } from 'react-router-dom';

const HotpadsSSOPage = ({ location }) => {
    const { emailAddress, signature, timestamp } = queryString.parse(location.search);

    const {
        state: { csrfToken },
    } = useContext(SigninContext);

    const signinPromise = useMemo(
        () =>
            API({
                method: 'post',
                url: API_IDENTIFICATION_SSO_FUNC({
                    email: encodeURIComponent(emailAddress),
                    mac: encodeURIComponent(signature),
                    provider: 'HOTPADS',
                    timestamp,
                }),
                headers: {
                    'X-Csrf-Token': csrfToken,
                },
            }),
        [csrfToken, emailAddress, signature, timestamp]
    );

    return useMemo(() => <SigninFlow signinPromise={signinPromise} />, [signinPromise]);
};

export default withRouter(HotpadsSSOPage);
