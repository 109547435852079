import React from 'react';
import NavBarContainer from '../containers/NavBarContainer';
import Footer from './Footer';
import GetCsrfToken from '../utils/GetCsrfToken';
import ScrollToTop from '../utils/ScrollToTop';
import AppRoutes from './AppRoutes';

import { BrowserRouter as Router } from 'react-router-dom';
import { SigninContextProvider } from '../contexts/SigninContext';

const AppRouter = () => {
    return (
        <Router>
            <ScrollToTop>
                <SigninContextProvider>
                    <GetCsrfToken>
                        <NavBarContainer />
                        <AppRoutes />
                        <Footer />
                    </GetCsrfToken>
                </SigninContextProvider>
            </ScrollToTop>
        </Router>
    );
};

export default AppRouter;
