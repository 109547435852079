import React from 'react';
import { Formik } from 'formik';
import { Alert, Button, FormField, Input, Label, Spacer, spaceMixin } from '@zillow/constellation';
import styled from 'styled-components';
import _omitBy from 'lodash/omitBy';
import _isEmpty from 'lodash/isEmpty';

const StyledRadioGroup = styled.ul`
    margin: 0 auto;
    color: ${props => props.theme.constellation?.colors.textMedium};
    text-align: left;
    margin-bottom: ${spaceMixin('sm')};
    > li {
        > input {
            margin-right: ${spaceMixin('md')};
            margin-bottom: 2px;
        }
        > label {
            font-size: 16px;
        }
    }
`;

const customPlaceholder = placeHolder => {
    switch (placeHolder) {
        case 'requestAgentId':
            return 'Put valid request Agent Id here';
        case 'userRequestId':
            return 'Put valid user request Id here';
        default:
            return 'Select a valid request Id here';
    }
};

const AgentRequestStatusForm = ({ onSubmit }) => (
    <Formik
        initialValues={{ requestId: '', requestIdType: '' }}
        validateOnChange={false}
        validateOnBlur={false}
        validate={values => {
            let errors = {};
            if (_isEmpty(_omitBy(values, _isEmpty)) || !values.requestIdType) {
                errors.general = 'At least one option is required';
            } else if (
                values.requestIdType === 'requestAgentId' &&
                (!values.requestId || !Number(values.requestId))
            ) {
                errors.general = !values.requestId
                    ? 'Request Agent Id is required'
                    : 'Invalid Request Agent Id';
            } else if (
                values.requestIdType === 'userRequestId' &&
                (!values.requestId || Number(values.requestId))
            ) {
                errors.general = !values.requestId
                    ? 'User Request Id is required'
                    : 'Invalid User Request Id';
            }
            return errors;
        }}
        onSubmit={values => {
            onSubmit(values);
        }}
    >
        {({ errors, values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
                <FormField marginBottom="sm">
                    <StyledRadioGroup spacingTop="lg" spacingBottom="xs">
                        <li>
                            <input
                                type="radio"
                                value="requestAgentId"
                                name="requestIdType"
                                onChange={e => {
                                    handleChange(e);
                                    if (values.requestId) {
                                        setFieldValue('requestId', '');
                                    }
                                    if (errors.general) {
                                        errors.general = undefined;
                                    }
                                }}
                                onBlur={handleBlur}
                            />
                            <Label htmlFor="requestAgentId">Request Agent Id</Label>
                        </li>
                        <li>
                            <input
                                type="radio"
                                value="userRequestId"
                                onChange={e => {
                                    handleChange(e);
                                    if (values.requestId) {
                                        setFieldValue('requestId', '');
                                    }
                                    if (errors.general) {
                                        errors.general = undefined;
                                    }
                                }}
                                name="requestIdType"
                            />
                            <Label htmlFor="userRequestId">User Request Id </Label>
                        </li>
                    </StyledRadioGroup>
                    <Input
                        name="requestId"
                        id="request-id"
                        placeholder={customPlaceholder(values.requestId)}
                        onChange={e => {
                            handleChange(e);
                            if (errors.general) {
                                errors.general = undefined;
                            }
                        }}
                        onBlur={handleBlur}
                        value={values.requestId}
                    />
                </FormField>
                {errors.general && (
                    <Spacer spacingTop="xs">
                        <Alert appearance="error" body={errors.general} />
                    </Spacer>
                )}
                <Button buttonType="primary" type="submit" marginTop="sm">
                    Submit
                </Button>
            </form>
        )}
    </Formik>
);

export default AgentRequestStatusForm;
