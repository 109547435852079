import React from 'react';
import { Formik } from 'formik';
import {
    Alert,
    Button,
    FormField,
    Input,
    Label,
    Radio,
    Spacer,
    spaceMixin,
} from '@zillow/constellation';
import styled from 'styled-components';
import _omitBy from 'lodash/omitBy';
import _isEmpty from 'lodash/isEmpty';

const StyledRadioGroup = styled.ul`
    margin: 0 auto;
    color: ${props => props.theme.constellation?.colors.textMedium};
    text-align: left;
    margin-bottom: ${spaceMixin('sm')};
    > li {
        > input {
            margin-right: ${spaceMixin('md')};
            margin-bottom: 2px;
        }
        > label {
            font-size: 16px;
        }
    }
`;

const customPlaceholder = placeHolder => {
    switch (placeHolder) {
        case 'email':
            return 'Put valid email Id here';
        case 'userRequestId':
            return 'Put valid user request Id here';
        default:
            return 'Select a valid Id here';
    }
};

const UserIdentifiersForm = ({ onSubmit }) => (
    <Formik
        initialValues={{ userId: '', userIdType: '' }}
        validateOnChange={false}
        validateOnBlur={false}
        validate={values => {
            let errors = {};
            if (_isEmpty(_omitBy(values, _isEmpty)) || !values.userIdType) {
                errors.general = 'At least one option is required';
            } else if (
                values.userIdType === 'email' &&
                (!values.userId || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.userId))
            ) {
                errors.general = !values.userId
                    ? 'Email address required'
                    : 'Invalid email address';
            } else if (!values.userId && values.userIdType === 'userRequestId') {
                errors.general = 'User Id required';
            }
            return errors;
        }}
        onSubmit={values => {
            onSubmit(values);
        }}
    >
        {({ errors, values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
                <FormField marginBottom="sm">
                    <StyledRadioGroup marginTop="lg" marginBottom="xs">
                        <li>
                            <Radio
                                value="email"
                                name="userIdType"
                                onChange={e => {
                                    handleChange(e);
                                    if (values.userId) {
                                        setFieldValue('userId', '');
                                    }
                                    // Clear error message when changing radio type
                                    if (errors.general) {
                                        errors.general = undefined;
                                    }
                                }}
                                onBlur={handleBlur}
                            />
                            <Label htmlFor="email">Email Id</Label>
                        </li>
                        <li>
                            <Radio
                                value="userRequestId"
                                onChange={e => {
                                    handleChange(e);
                                    if (values.userId) {
                                        setFieldValue('userId', '');
                                    }
                                    // Clear error message when changing radio type
                                    if (errors.general) {
                                        errors.general = undefined;
                                    }
                                }}
                                name="userIdType"
                            />
                            <Label htmlFor="userRequestId">User Request Id </Label>
                        </li>
                    </StyledRadioGroup>
                    <Input
                        name="userId"
                        id="user-id"
                        placeholder={customPlaceholder(values.userIdType)}
                        onChange={e => {
                            handleChange(e);
                            // Clear error message when changing radio type
                            if (errors.general) {
                                errors.general = undefined;
                            }
                        }}
                        onBlur={handleBlur}
                        value={values.userId}
                    />
                </FormField>
                {errors.general && (
                    <Spacer marginTop="xs">
                        <Alert appearance="error" body={errors.general} />
                    </Spacer>
                )}
                <Button buttonType="primary" type="submit" marginTop="sm">
                    Submit
                </Button>
            </form>
        )}
    </Formik>
);

export default UserIdentifiersForm;
