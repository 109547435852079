import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
import API from '../utils/api';
import { parseErrorResponse } from '../utils';

import { PageWrapper, PageContent, PageSection, StyledHeadingParagraph } from '../common/Page';
import { Heading } from '@zillow/constellation';
import { withRouter } from 'react-router-dom';
import {
    AUDIT_WHITE_LIST_DOMAINS,
    AUDIT_WHITE_LIST_BRANDS,
} from '../constants/ManageCookiePageConstants';
import { API_AUDIT_ERROR_FUNC } from '../constants/ApiConstants';
import { IconExclamationCircleOutline, Toast, IconArrowRight } from '@zillow/constellation';

const StyledPageContent = styled(PageContent)`
    padding: 0;
`;

const StyledToast = styled(Toast)`
    div[class^='Toast__Body'] {
        font-weight: bold;
    }
`;

const CookieOptOutError = ({ location }) => {
    const [errorCodeText, setErrorCodeText] = useState('Error Code: 000000');

    const is_valid_url = urlToTest => {
        const regexp = /^(?:(?:https?|ftp):\/\/)(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

        if (regexp.test(urlToTest)) {
            return true;
        } else {
            return false;
        }
    };
    useEffect(() => {
        if (window.performance) {
            let errorCodeText = '';
            if (performance.navigation.type === 0) {
                let errorCode = [0, 0, 0];
                const brand = queryString.parse(location.search)['brand'] || null;
                const opt_out_error_code = queryString.parse(location.search)['error_code'] || null;
                const referrer = document.referrer;
                const domainName = referrer.split('/')[2];

                if (
                    brand === undefined ||
                    (!AUDIT_WHITE_LIST_BRANDS.split(',').includes(brand) &&
                        domainName.indexOf(brand) < 0)
                ) {
                    errorCode[0] = 1;
                } else if (brand.toLowerCase() === 'privacy portal') {
                    errorCode[0] = 2;
                } else if (brand.toLowerCase() === 'zillow') {
                    errorCode[0] = 3;
                } else if (brand.toLowerCase() === 'trulia') {
                    errorCode[0] = 4;
                } else if (brand.toLowerCase() === 'hotpads') {
                    errorCode[0] = 5;
                } else if (brand.toLowerCase() === 'streeteasy') {
                    errorCode[0] = 6;
                }

                if (domainName === undefined) {
                    errorCode[1] = 2;
                } else if (!is_valid_url(referrer)) {
                    errorCode[1] = 3;
                } else if (!AUDIT_WHITE_LIST_DOMAINS.split(',').includes(domainName)) {
                    errorCode[1] = 4;
                } else {
                    errorCode[1] = 1;
                }
                errorCodeText = errorCode.join('').concat(opt_out_error_code);
                setErrorCodeText('Error Code: ' + errorCodeText);

                if (
                    domainName !== undefined &&
                    AUDIT_WHITE_LIST_DOMAINS.split(',').includes(domainName)
                ) {
                    let description = 'error_code:' + errorCodeText;
                    API({
                        url: API_AUDIT_ERROR_FUNC(description, domainName),
                        method: 'POST',
                    }).catch(error => {
                        const errorMessage = parseErrorResponse(error);
                        console.log('Error in auditing API call ' + errorMessage);
                    });
                }
            }
        }
    }, [location]);

    return (
        <PageWrapper backgroundColor="#f4f4f4">
            <StyledPageContent>
                <PageSection maxWidth={1000}>
                    <Heading level="2" serif>
                        <IconExclamationCircleOutline color="red" /> We encountered an error
                        disabling your advertising cookies
                    </Heading>
                    <StyledHeadingParagraph fontType="h5" marginTop="sm" marginBottom="md">
                        We may have disabled some of your advertising cookies, but not all. Please
                        go to <a href="/cookie">Manage Cookies</a> page and try again.
                    </StyledHeadingParagraph>

                    <StyledToast
                        appearance="error"
                        body={errorCodeText}
                        closeButton={null}
                        icon={<IconArrowRight />}
                    />
                </PageSection>
            </StyledPageContent>
        </PageWrapper>
    );
};

export default withRouter(CookieOptOutError);
