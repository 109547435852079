import React from 'react';
import AppRouter from './components/AppRouter';
import { ThemeProvider } from 'styled-components';
import { ThemeConstellation } from '@zillow/constellation';

function App() {
    return (
        <ThemeProvider theme={ThemeConstellation}>
            <AppRouter />
        </ThemeProvider>
    );
}

export default App;
