import React from 'react';
import { Formik } from 'formik';
import { Alert, Button, FormField, Input, Label, Spacer } from '@zillow/constellation';

const AgentDetailsForm = ({ onSubmit }) => (
    <Formik
        initialValues={{ agentName: '' }}
        validate={values => {
            let errors = {};
            if (!values.agentName) {
                errors.agentName = 'agentName is required';
            }
            return errors;
        }}
        onSubmit={values => onSubmit(values.agentName)}
    >
        {({ errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
                <FormField marginBottom="sm">
                    <Label htmlFor="request-id">agentName</Label>
                    <Input
                        name="agentName"
                        id="request-id"
                        placeholder="put valid agentName here"
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FormField>
                {errors.agentName && touched.agentName && (
                    <Spacer marginTop="xs">
                        <Alert appearance="error" body={errors.agentName} />
                    </Spacer>
                )}
                <Button buttonType="primary" type="submit" marginTop="sm">
                    Submit
                </Button>
            </form>
        )}
    </Formik>
);

export default AgentDetailsForm;
