import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading, Text, Spacer, Alert, spaceMixin } from '@zillow/constellation';

const gridSize = '14% 9% 20% 20% 25% 13%';

const StyledGridContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: ${gridSize};
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 10px;
    overflow-wrap: break-word;
`;

const StyledReportContainer = styled.div`
    max-height: 350px;
    overflow: hidden;
    overflow-y: scroll;
`;

const StyledGridEvenContainer = styled.div`
    display: grid;
    background-color: ${props => props.theme.constellation?.colors.gray200};
    align-items: start;
    grid-template-columns: ${gridSize};
`;

const StyledGridOddContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: ${gridSize};
`;

const StyledHeader = styled.div`
    display: inline-block;
    padding: 10px;
    background-color: ${props => props.theme.constellation?.colors.gray400};
    min-height: ${spaceMixin('xs')};
`;

const StyledRow = styled.div`
    display: inline-block;
    padding: 5px 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-decoration: none;
`;

const getContent = props => {
    let content = [];
    props.data.forEach((obj, i) => {
        const StyledGridContainer = i % 2 ? StyledGridEvenContainer : StyledGridOddContainer;
        content.push(
            <StyledGridContainer key={i}>
                <StyledRow>{obj.date}</StyledRow>
                <StyledRow>{obj.agentId}</StyledRow>
                <StyledRow title={obj.agentName}>{obj.agentName}</StyledRow>
                <StyledRow>
                    <a
                        href={'https://zodiac.zgtools.net/services/' + obj.zodiacServiceName}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {obj.zodiacServiceName}
                    </a>
                </StyledRow>
                <StyledRow>{obj.userRequestId}</StyledRow>
                <StyledRow>
                    {obj.jiraTicketNumber ? (
                        <a
                            href={
                                'https://zillowgroup.atlassian.net/browse/' + obj.jiraTicketNumber
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {obj.jiraTicketNumber}
                        </a>
                    ) : (
                        'NA'
                    )}
                </StyledRow>
            </StyledGridContainer>
        );
    });
    return content;
};

const requestsInJiraTicketCreatedState = props => {
    const reportContent =
        props.data.length <= 0 ? (
            <Alert appearance="warning" body="No data found." />
        ) : (
            <Spacer>
                <Spacer marginTop="xs">
                    <Alert
                        body={
                            <Text>
                                Total number of records found: <strong>{props.data.length}</strong>
                            </Text>
                        }
                    />
                </Spacer>
                <StyledGridContainer>
                    <StyledHeader>Date</StyledHeader>
                    <StyledHeader>Agent Id</StyledHeader>
                    <StyledHeader>Agent Name</StyledHeader>
                    <StyledHeader>Zodiac Service Name</StyledHeader>
                    <StyledHeader>User Request Id</StyledHeader>
                    <StyledHeader>Jira Ticket</StyledHeader>
                </StyledGridContainer>
                <StyledReportContainer>{getContent(props)}</StyledReportContainer>
            </Spacer>
        );

    return (
        <div>
            <Heading level="4">Requests In JIRA_TICKET_CREATED state:</Heading>
            {reportContent}
        </div>
    );
};

requestsInJiraTicketCreatedState.propTypes = {
    data: PropTypes.any,
};

requestsInJiraTicketCreatedState.defaultProps = {
    data: [],
};

export default requestsInJiraTicketCreatedState;
