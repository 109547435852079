import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mediaBreakpointMixin } from '@zillow/constellation';

const StyledButton = styled.button`
    padding: 0;
    background-color: transparent;
    border: 0;

    > .icon-bar {
        background: #777;
        display: block;
        width: 22px;
        height: 2px;
        border-radius: 1px;

        & + .icon-bar {
            margin-top: 4px;
        }
    }

    @media ${mediaBreakpointMixin('lg')} {
        display: none;
    }
`;

const NavHamburger = ({ onClick }) => (
    <StyledButton onClick={onClick}>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
    </StyledButton>
);

NavHamburger.propTypes = {
    onClick: PropTypes.func,
};

export default NavHamburger;
