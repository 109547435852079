import React, { createContext, useEffect, useReducer } from 'react';
import _pick from 'lodash/pick';
import _remove from 'lodash/remove';
import produce from 'immer';
import { DISPLAY_NAME as SIGNIN_LANDING_DISPLAY_NAME } from '../components/signin/SigninLanding';
import { VERIFICATION_STATE } from '../constants/VerificationConstants';

export const SIGNIN_NAMESPACE = 'PRIVACY.SIGNIN';

// redux actions
export const ACTIONS = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    OPEN_SIGNIN_MODAL: 'OPEN_SIGNIN_MODAL',
    CLOSE_SIGNIN_MODAL: 'CLOSE_SIGNIN_MODAL',
    UPDATE_CSRF: 'UPDATE_CSRF',
    UPDATE_SIGNIN_CONTENT: 'UPDATE_SIGNIN_CONTENT',
    SET_DOWNLOAD_REQUESTS: 'SET_DOWNLOAD_REQUESTS',
    SET_PRIMARY_EMAIL: 'SET_PRIMARY_EMAIL',
    SET_USER_EMAILS: 'SET_USER_EMAILS',
    SET_IS_DOWNLOAD_REQUESTS_CALCULATED: 'SET_IS_DOWNLOAD_REQUESTS_CALCULATED',
    SYNC_USER_EMAILS: 'SYNC_USER_EMAILS',
    IS_BRAND_ACCOUNT: 'IS_BRAND_ACCOUNT',
};

let localState = {};
try {
    localState =
        window.localStorage.getItem(SIGNIN_NAMESPACE) &&
        JSON.parse(window.localStorage.getItem(SIGNIN_NAMESPACE));
} catch (error) {
    console.error('Signin is invalid: ' + error);
}

const defaultInitialState = {
    contentType: SIGNIN_LANDING_DISPLAY_NAME,
    csrfToken: null,
    isAuthenticated: false,
    isSigninModalOpen: false,
    primaryEmail: null,
    userEmails: [],
    downloadRequests: [],
    isDownloadRequestsCalculated: false,
    redirectTo: '/',
    isBrandAccount: true,
};
const initialState = {
    ...defaultInitialState,
    ..._pick(localState, Object.keys(defaultInitialState)),
};

const SigninContext = createContext(initialState);

const signinReducer = produce((draft, action) => {
    switch (action.type) {
        case ACTIONS.LOGIN:
            draft.isAuthenticated = action.payload.isAuthenticated;
            return;
        case ACTIONS.LOGOUT:
            Object.assign(draft, defaultInitialState);
            return;
        case ACTIONS.OPEN_SIGNIN_MODAL:
            draft.isSigninModalOpen = true;
            draft.redirectTo = action.payload.redirectTo;
            return;
        case ACTIONS.CLOSE_SIGNIN_MODAL:
            draft.isSigninModalOpen = false;
            return;
        case ACTIONS.UPDATE_CSRF:
            draft.csrfToken = action.payload.csrfToken;
            return;
        case ACTIONS.UPDATE_SIGNIN_CONTENT:
            draft.contentType = action.payload.contentType;
            return;
        case ACTIONS.SET_USER_EMAILS:
            const { identifiers } = action.payload;
            const allKeys = Object.keys(identifiers);
            draft.userEmails = allKeys.map(key => {
                return {
                    id: key,
                    email: identifiers[key],
                    verificationState: VERIFICATION_STATE.UNVERIFIED,
                };
            });
            return;
        case ACTIONS.SET_PRIMARY_EMAIL:
            const { primaryEmail } = action.payload;
            draft.primaryEmail = primaryEmail;
            // Hoist primary email to the first
            const primaryEmailInfo = _remove(draft.userEmails, ue => ue.email === primaryEmail);
            draft.userEmails = primaryEmailInfo.concat(draft.userEmails);
            return;
        case ACTIONS.SYNC_USER_EMAILS:
            draft.userEmails = action.payload.userEmails;
            return;
        case ACTIONS.SET_DOWNLOAD_REQUESTS:
            draft.downloadRequests = action.payload.downloadRequests;
            return;
        case ACTIONS.SET_IS_DOWNLOAD_REQUESTS_CALCULATED:
            draft.isDownloadRequestsCalculated = true;
            return;
        case ACTIONS.IS_BRAND_ACCOUNT:
            const { isBrandAccount } = action.payload;
            draft.isBrandAccount = isBrandAccount;
            return;
        default:
            return;
    }
});

export const SigninContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(signinReducer, initialState);

    useEffect(() => {
        window.localStorage.setItem(SIGNIN_NAMESPACE, JSON.stringify(state));
    }, [state]);

    return <SigninContext.Provider value={{ state, dispatch }}>{children}</SigninContext.Provider>;
};

export default SigninContext;
