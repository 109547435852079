import React from 'react';
import PropTypes from 'prop-types';
import SeparatorLine from '../../common/SeparatorLine';
import { Button, Heading, Paragraph } from '@zillow/constellation';
import { Link } from 'react-router-dom';

export const DISPLAY_NAME = 'VERIFY_EMAIL_CONFIRMED';

const VerifyEmailConfirmed = ({
    buttonText,
    title,
    content,
    onCloseButtonClick,
    redirectTo,
    footerLinkType,
}) => (
    <>
        <Heading level="3" serif marginTop="sm">
            {title}
        </Heading>
        {content}
        <SeparatorLine width={16} marginTop="lg" />
        {redirectTo ? (
            <Link to={redirectTo}>
                {footerLinkType === 'button' ? (
                    <Button marginTop="sm" buttonType="primary" onClick={onCloseButtonClick}>
                        {buttonText}
                    </Button>
                ) : (
                    <span onClick={onCloseButtonClick}>
                        <strong>{buttonText}</strong>
                    </span>
                )}
            </Link>
        ) : (
            <Button marginTop="sm" buttonType="primary" onClick={onCloseButtonClick}>
                {buttonText}
            </Button>
        )}
    </>
);

VerifyEmailConfirmed.propTypes = {
    title: PropTypes.string,
    content: PropTypes.node,
    buttonText: PropTypes.string,
    onCloseButtonClick: PropTypes.func,
    redirectTo: PropTypes.string,
    footerLinkType: PropTypes.string,
};

VerifyEmailConfirmed.defaultProps = {
    title: 'Verify Email Confirmed',
    content: (
        <Paragraph marginTop="lg" marginBottom="lg">
            Thank you!
        </Paragraph>
    ),
    buttonText: 'Got it',
    onCloseButtonClick: null,
    redirectTo: null,
    footerLinkType: 'button',
};

VerifyEmailConfirmed.displayName = DISPLAY_NAME;

export default VerifyEmailConfirmed;
