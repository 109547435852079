import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import _get from 'lodash/get';
import BigArrow from '../common/BigArrow';
import Header from '../components/Header';
import SubNav from '../components/SubNav';
import SigninContext, { ACTIONS as SIGNIN_ACTIONS } from '../contexts/SigninContext';
import SigninModalDialog from '../containers/SigninModalDialog';
import API from '../utils/api';

import { API_IDENTIFICATION_LOGOUT } from '../constants/ApiConstants';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Toast, TextButton, spaceMixin } from '@zillow/constellation';

const StyledBackButtonWrapper = styled.div`
    margin: 0 auto;
    max-width: 1200px;
    padding-top: ${spaceMixin('md')};

    > a {
        margin-left: ${spaceMixin('md')};
    }
`;

const StyledToast = styled(Toast)`
    border-radius: 0;
    max-width: none;
`;

const NavBarContainer = ({ location: { pathname, state }, history }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [signinError, setSigninError] = useState(null);

    const {
        state: { csrfToken, isAuthenticated, primaryEmail, isSigninModalOpen },
        dispatch: signinDispatch,
    } = useContext(SigninContext);

    const backButton =
        pathname === '/cookie' || pathname === '/correction' ? (
            <StyledBackButtonWrapper>
                <Link to="/">
                    <TextButton>
                        <BigArrow direction="left" />
                        Back
                    </TextButton>
                </Link>
            </StyledBackButtonWrapper>
        ) : null;

    const logout = () => {
        API({
            method: 'post',
            url: API_IDENTIFICATION_LOGOUT,
            headers: {
                'X-Csrf-Token': csrfToken,
            },
        })
            .then(() => {
                signinDispatch({
                    type: SIGNIN_ACTIONS.LOGOUT,
                });
            })
            .catch(error => {
                console.error(error);
            });
    };

    useEffect(() => {
        setIsOpen(false);
        const flashError = _get(state, 'error');
        if (flashError) {
            setSigninError(flashError);
            history.replace({
                pathname,
            });
        }
    }, [history, pathname, state]);

    return (
        <>
            <Header
                onClickHamburger={() => {
                    setIsOpen(!isOpen);
                }}
                onClickLogout={logout}
                isAuthenticated={isAuthenticated}
                primaryEmail={primaryEmail}
                openSigninModal={() => {
                    signinDispatch({
                        type: SIGNIN_ACTIONS.OPEN_SIGNIN_MODAL,
                        payload: {
                            redirectTo: '/',
                        },
                    });
                }}
            />

            <SigninModalDialog
                isOpen={isSigninModalOpen}
                shouldCloseOnOutsideClick={false}
                shouldCloseOnESCKeyPress={false}
                onClose={() => {
                    signinDispatch({
                        type: SIGNIN_ACTIONS.CLOSE_SIGNIN_MODAL,
                    });
                }}
            />

            {isAuthenticated ? (
                <SubNav isOpen={isOpen} onClickLogout={logout} primaryEmail={primaryEmail} />
            ) : (
                backButton
            )}

            {signinError && (
                <StyledToast
                    appearance="error"
                    body={signinError}
                    onClose={() => setSigninError(null)}
                />
            )}
        </>
    );
};

export default withRouter(NavBarContainer);
