export const API_OPT_OUT_URL =
    process.env[`REACT_APP_OPT_OUT_URL_${process.env.REACT_APP_STAGE.toUpperCase()}`];

export const AUDIT_WHITE_LIST_DOMAINS =
    process.env[`REACT_APP_AUDIT_WHITE_LIST_DOMAIN_${process.env.REACT_APP_STAGE.toUpperCase()}`];

export const AUDIT_WHITE_LIST_BRANDS =
    process.env[`REACT_APP_AUDIT_WHITE_LIST_BRANDS_${process.env.REACT_APP_STAGE.toUpperCase()}`];

export const CAPTCHA_PUBLIC_API_KEY =
    process.env[
        `REACT_APP_OPT_OUT_CAPTCHA_PUBLIC_KEY_${process.env.REACT_APP_STAGE.toUpperCase()}`
    ];

export const OPT_OUT_CAPTCHA_DISPLAY =
    process.env[`REACT_APP_OPT_OUT_CAPTCHA_DISPLAY_${process.env.REACT_APP_STAGE.toUpperCase()}`];

export const OPT_OUT_OF_SALE_REQUEST =
    process.env[`REACT_APP_OPT_OUT_OF_SALE_REQUEST_${process.env.REACT_APP_STAGE.toUpperCase()}`];
