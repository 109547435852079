import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Heading, Input, Paragraph, TextButton } from '@zillow/constellation';
import { StyledErrorParagraph } from '../../common/Page';
import { parseErrorResponse } from '../../utils';

export const DISPLAY_NAME = 'VERIFY_EMAIL';

const StyledWrapper = styled.div`
    .email-wrapper {
        word-break: break-word;
    }
`;

const StyledEmailParagraph = styled(Paragraph)`
    font-weight: 600;
`;

const VerifyEmail = ({
    email,
    onClickNext,
    onResendCode,
    onValidateCodePromise,
    onComponentMount,
}) => {
    const [code, setCode] = useState(null);
    const [error, setError] = useState(null);

    const onClickNextButton = () => {
        onValidateCodePromise(code)
            .then(res => {
                if (res.data.success) {
                    onClickNext();
                }
                if (res.data.error) {
                    setError(`Error: ${res.data.error}`);
                }
            })
            .catch(error => {
                const errorMessage = parseErrorResponse(error);
                setError(`Error: ${errorMessage}`);
            });
    };

    useEffect(() => {
        onComponentMount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledWrapper>
            <Heading level="3" serif marginTop="sm">
                Please verify your email
            </Heading>
            <Paragraph marginTop="lg" marginBottom="lg">
                We sent you an email at:
            </Paragraph>
            <StyledEmailParagraph
                marginTop="lg"
                marginBottom="lg"
                fontType="h5"
                className="email-wrapper"
            >
                {email}
            </StyledEmailParagraph>
            <Paragraph marginTop="lg" marginBottom="xs">
                Please enter the code we emailed you to confirm your identity so we can process your
                request.
            </Paragraph>
            <TextButton marginBottom="lg" onClick={onResendCode}>
                Resend
            </TextButton>
            <Input
                placeholder="Enter code"
                onChange={e => {
                    setCode(e.target.value);
                }}
            />
            {error && <StyledErrorParagraph marginTop="xs">{error}</StyledErrorParagraph>}
            <Button marginTop="lg" onClick={onClickNextButton} buttonType="primary">
                Next
            </Button>
        </StyledWrapper>
    );
};

VerifyEmail.propTypes = {
    email: PropTypes.string.isRequired,
    onClickNext: PropTypes.func.isRequired,
    onComponentMount: PropTypes.func.isRequired,
    onResendCode: PropTypes.func.isRequired,
    onValidateCodePromise: PropTypes.func.isRequired,
};

VerifyEmail.displayName = DISPLAY_NAME;

export default VerifyEmail;
