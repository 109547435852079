import API from '../../utils/api';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SigninContext from '../../contexts/SigninContext';
import {
    Alert,
    Button,
    ButtonGroup,
    Spacer,
    Card,
    CardHeader,
    CardSection,
    Heading,
    IconExclamationCircleOutline,
    IconReload,
    InlineFeedback,
    ModalDialog,
    Paragraph,
    TextButton,
    Tooltip,
    Trigger,
    TriggerButton,
    TriggerText,
    TooltipPopper,
    CardButton,
    Collapsible,
    IconClose,
} from '@zillow/constellation';
import { LoadingButton } from '@zillow/constellation';
import {
    API_ADMIN_DEACTIVATE_REQUEST_DATA_AGENT,
    API_REPLAY_REQUESTS,
    API_IS_USER_HAVE_FULL_ADMIN_ACCESS,
} from '../../constants/ApiConstants';

const gridSize = '11% 16% 19% 15% 20% 18%';
const aggregatedResultGridSize = '35% 35% 30%';
const modelGridSize = '50% 50%';

const StyledAggregatedResultGridContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: ${aggregatedResultGridSize};
    margin-top: 10px;
`;

const StyledInlineFeedbackDiv = styled.div`
    text-align: left;
`;

const StyledReplayAlertContainer = styled.div`
    text-align: left;
    margin-top: 10px;
`;

const StyledAggregatedResultHeader = styled.div`
    display: inline-block;
    font-weight: bold;
    padding: 10px;
    background-color: ${props => props.theme.constellation?.colors.blue200};
    text-align: center;
`;

const StyledLoadingButtonDiv = styled.span`
    div[class^='StyledLoadingMask'] {
        margin-left: 14px;
    }
    button {
        padding: 9px 20px;
        height: 40px;
    }
`;

const StyledFooterButtonsContainer = styled.div`
    width: 100%;
    text-align: right;
`;

const StyledAggregatedResultRow = styled.div`
    display: grid;
    padding: 5px 10px;
    background-color: ${props => props.theme.constellation?.colors.blue100};
    text-align: center;
`;

const StyledGridContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: ${gridSize};
    margin-top: 10px;
`;

const StyledGridEvenContainer = styled.div`
    display: grid;
    background-color: ${props => props.theme.constellation?.colors.gray200};
    align-items: start;
    grid-template-columns: ${gridSize};
`;

const StyledGridOddContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: ${gridSize};
`;

const StyledHeader = styled.div`
    display: inline-block;
    padding: 10px;
    font-weight: bold;
    background-color: ${props => props.theme.constellation?.colors.gray400};
    min-height: 65px;
`;

const StyledRow = styled.div`
    display: inline-block;
    padding: 5px 10px;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const StyledHeaderSpan = styled.span`
    font-weight: bold;
`;

const StyledRedSpan = styled.span`
    color: ${props => props.theme.constellation?.colors.red500};
`;

const StyledGreenSpan = styled.span`
    color: ${props => props.theme.constellation?.colors.green500};
`;

const StyledYellow400Span = styled.span`
    color: ${props => props.theme.constellation?.colors.yellow400};
`;

const StyledFacebookSpan = styled.span`
    color: ${props => props.theme.constellation?.colors.facebook};
`;

const StyledIconClose = styled(IconClose)`
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
`;

const StyledInnerPopUpDiv = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: ${modelGridSize};
`;

const StyledCol = styled.span`
    padding: 5px;
    padding-left: 15px;
    display: inline-block;
    text-align: start;
`;

const StyledModalDialog = styled(ModalDialog)`
    text-align: center;
    button[class^='DialogClose-'] {
        display: none;
    }
    div[class^='DialogBody-'] {
        margin: 10px !important;
    }
`;

const StyledDialogHeader = styled.div`
    display: inline-block;
    font-weight: bold;
    padding: 10px;
    background-color: ${props => props.theme.constellation?.colors.blue200};
    text-align: start;
`;

const StyledDialogGridContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: ${modelGridSize};
    margin-top: 10px;
`;

const StyledTriggerText = styled(TriggerText)`
    text-align: left;
`;

const StyledLoadingButton = styled(LoadingButton)`
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    display: contents;
    box-sizing: border-box;
    width: 330px;
    border: 1px solid;
    border-radius: 3px;
    user-select: none;
    cursor: pointer;
    padding-top: 9px;
    padding-bottom: 9px;
    height: 60px;
    line-height: 22px;
    transition: all 0.2s ease;
`;

const StyledParagraph = styled(Paragraph)`
    text-align: center;
`;

const DisplayAgentRequestStatus = props => {
    const {
        state: { csrfToken },
    } = useContext(SigninContext);

    const [isFinished, setIsFinished] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [agentLoadingStates, setAgentLoadingStates] = useState({});
    const [replyRequestAgentId, setReplyRequestAgentId] = useState(null);
    const [userRequestIdsWithRequestType, setUserRequestIdsWithRequestType] = useState({});
    const [isUserBelongsToDataAgent, setIsUserBelongsToDataAgent] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

    const getAggregatedResult = aggregatedResult => {
        let content = [],
            aggregatedResultContent = [];

        Object.keys(aggregatedResult).map(function(keyName, keyIndex) {
            let aggregatedResultObj = {};

            const requestAgentStatus = keyName
                .slice(keyName.indexOf('requestAgentStatus'), keyName.indexOf(','))
                .split('=')[1];
            const priorityLevel = keyName
                .slice(keyName.indexOf('priorityLevel'), keyName.indexOf(')'))
                .split('=')[1];
            const requestCount = aggregatedResult[keyName];

            aggregatedResultObj.requestAgentStatus = requestAgentStatus;
            aggregatedResultObj.priorityLevel = priorityLevel;
            aggregatedResultObj.requestCount = requestCount;
            aggregatedResultContent.push(aggregatedResultObj);

            return aggregatedResultContent;
        });

        aggregatedResultContent.sort(function(result1, result2) {
            return result1.priorityLevel - result2.priorityLevel;
        });

        aggregatedResultContent.forEach((obj, i) => {
            content.push(
                <StyledAggregatedResultGridContainer key={obj.priorityLevel}>
                    <StyledAggregatedResultRow>{obj.requestAgentStatus}</StyledAggregatedResultRow>
                    <StyledAggregatedResultRow>{obj.priorityLevel}</StyledAggregatedResultRow>
                    <StyledAggregatedResultRow>{obj.requestCount}</StyledAggregatedResultRow>
                </StyledAggregatedResultGridContainer>
            );
        });

        return content;
    };

    const requestStatus = (status, isStrong = false) => {
        switch (status) {
            case 'REQUEST_SENT':
            case 'RETRY':
            case 'RETRY_SENT':
                return (
                    <StyledYellow400Span>
                        {isStrong ? <strong>{status}</strong> : status}{' '}
                    </StyledYellow400Span>
                );
            case 'REJECTED':
            case 'PARTIAL_REJECTED':
            case 'JIRA_TICKET_CREATED':
            case 'OVERDUE':
            case 'DEACTIVATED':
            case 'DELETE':
                return (
                    <StyledRedSpan>{isStrong ? <strong>{status}</strong> : status}</StyledRedSpan>
                );
            case 'EXPORT':
            case 'FINISHED':
                return (
                    <StyledGreenSpan>
                        {isStrong ? <strong>{status}</strong> : status}
                    </StyledGreenSpan>
                );
            default:
                return (
                    <StyledFacebookSpan>
                        {isStrong ? <strong>{status}</strong> : status}
                    </StyledFacebookSpan>
                );
        }
    };

    const prepareRejectionReasonList = reasonArray => {
        let reasonsList = [];
        if (reasonArray.length > 0) {
            reasonArray.forEach((reason, i) => {
                reason = reason.replace(/<[^>]+>/g, '');
                reasonsList.push(<li key={i}>{reason}</li>);
            });
        } else {
            reasonsList.push(<li key="na">Rejection reason not available.</li>);
        }
        return reasonsList;
    };

    const prepareRequestAgentExcludeDeleteDataDomainList = requestAgentExcludeDeleteDataDomainArray => {
        let requestAgentExcludeDeleteDataDomainList = [];
        if (requestAgentExcludeDeleteDataDomainArray.length > 0) {
            requestAgentExcludeDeleteDataDomainArray.forEach((dataDomain, i) => {
                let data = `[${dataDomain.brand.replace(
                    'BRAND_',
                    ''
                )}, ${dataDomain.lineOfBusiness.replace('LOB_', '')}]`;
                requestAgentExcludeDeleteDataDomainList.push(<li key={i}>{data}</li>);
            });
        } else {
            requestAgentExcludeDeleteDataDomainList.push(
                <li key="na">Request delete exclude data domain not available.</li>
            );
        }
        return requestAgentExcludeDeleteDataDomainList;
    };

    const getDisplayDate = timestamp => {
        const plus0 = num => `0${num.toString()}`.slice(-2);

        const d = new Date(timestamp);

        const year = d.getFullYear();
        const monthTmp = d.getMonth() + 1;
        const month = plus0(monthTmp);
        const date = plus0(d.getDate());
        const hour = plus0(d.getHours());
        const minute = plus0(d.getMinutes());
        const second = plus0(d.getSeconds());

        return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
    };

    const getContent = props => {
        let content = [];
        props.data.requestAgents.forEach((obj, i) => {
            if (obj.messageType === 'APPROVE_DELETE') {
                obj.priorityLevel = 0;
            }
        });
        props.data.requestAgents.sort(function(requestAgent1, requestAgent2) {
            return requestAgent1.priorityLevel - requestAgent2.priorityLevel;
        });
        props.data.requestAgents.forEach((obj, i) => {
            const StyledGridContainer = i % 2 ? StyledGridEvenContainer : StyledGridOddContainer;

            const agentTooltip = (
                <Tooltip
                    shouldCloseOnESCKeyPress={true}
                    shouldCloseOnOutsideClick={true}
                    closeButton={<StyledIconClose />}
                    body={
                        <div>
                            <div>
                                AgentID: <StyledHeaderSpan>{obj.agentId}</StyledHeaderSpan>
                            </div>
                            <div>
                                Agent Name: <StyledHeaderSpan>{obj.agentName}</StyledHeaderSpan>
                            </div>
                            <div>
                                Priority Level:{' '}
                                <StyledHeaderSpan>{obj.priorityLevel}</StyledHeaderSpan>
                            </div>
                            <Paragraph>
                                Zodiac Team:{' '}
                                <StyledHeaderSpan>{obj.zodiacTeamName}</StyledHeaderSpan>
                            </Paragraph>
                        </div>
                    }
                />
            );

            const rejectReasonTooltip = (
                <Tooltip
                    shouldCloseOnESCKeyPress={true}
                    shouldCloseOnOutsideClick={true}
                    closeButton={<StyledIconClose />}
                    body={
                        <Paragraph>{prepareRejectionReasonList(obj.rejectReasonsList)}</Paragraph>
                    }
                />
            );

            const requestAgentExcludeDeleteDataDomainTooltip = (
                <Tooltip
                    shouldCloseOnESCKeyPress={true}
                    shouldCloseOnOutsideClick={true}
                    closeButton={<StyledIconClose />}
                    body={
                        <Paragraph>
                            {prepareRequestAgentExcludeDeleteDataDomainList(
                                obj.requestAgentExcludeDeleteDataDomains
                            )}
                        </Paragraph>
                    }
                />
            );

            const deactivateAgentBodyDialog = (props, close) => (
                <ModalDialog
                    {...props}
                    wide
                    body="Do you really want to DEACTIVATE this agent for this user request?"
                    footer={
                        <StyledFooterButtonsContainer>
                            {errorMessage}
                            <ButtonGroup>
                                <TextButton onClick={close}>Cancel</TextButton>
                                {isLoading ? (
                                    <StyledLoadingButtonDiv>
                                        <LoadingButton size="lg" buttonType="primary" loading>
                                            <IconReload size="xs" /> Processing
                                        </LoadingButton>
                                    </StyledLoadingButtonDiv>
                                ) : (
                                    <Button
                                        buttonType="primary"
                                        onClick={() => deactivateRequestDataAgent(obj)}
                                    >
                                        <IconReload size="xs" /> Process
                                    </Button>
                                )}
                            </ButtonGroup>
                            {isFinished && (
                                <StyledReplayAlertContainer>
                                    <Alert appearance="success" body="Updated successfully...!" />
                                </StyledReplayAlertContainer>
                            )}
                        </StyledFooterButtonsContainer>
                    }
                />
            );

            const resendRequestAgentBodyDialog = (
                zodiacServiceName,
                agentId,
                messageType,
                requestId
            ) => {
                setReplyRequestAgentId(agentId);
                setAgentLoadingStates(prevStates => ({
                    ...prevStates,
                    [agentId]: true,
                }));
                setUserRequestIdsWithRequestType(prevState => ({
                    ...prevState,
                    [requestId]: messageType,
                }));
                API({
                    url: API_IS_USER_HAVE_FULL_ADMIN_ACCESS(zodiacServiceName),
                    headers: {
                        'X-Csrf-Token': csrfToken,
                    },
                    method: 'GET',
                })
                    .then(res => {
                        if (res) {
                            setAgentLoadingStates(prevStates => ({
                                ...prevStates,
                                [agentId]: false,
                            }));
                            setIsUserBelongsToDataAgent(res.data);
                            setIsModalOpen(true);
                        }
                    })
                    .catch(error => {
                        setIsErrorModalOpen(true);
                        setAgentLoadingStates(prevStates => ({
                            ...prevStates,
                            [agentId]: false,
                        }));
                        setIsModalOpen(false);
                        console.log(error);
                    });
            };

            const handleOnClose = () => {
                setIsFinished(false);
                setErrorMessage('');
                setIsLoading(false);
                setIsModalOpen(false);
            };

            const deactivateRequestDataAgent = object => {
                setIsLoading(true);
                API({
                    url: API_ADMIN_DEACTIVATE_REQUEST_DATA_AGENT(
                        object.requestId,
                        object.agentId,
                        object.messageType
                    ),
                    headers: {
                        'X-Csrf-Token': csrfToken,
                    },
                    method: 'POST',
                })
                    .then(res => {
                        if (res) {
                            setIsFinished(true);
                            setIsLoading(false);
                            object.requestAgentStatus = 'DEACTIVATED';
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        setErrorMessage(
                            getErrorMessage('Something went wrong. Please try again later..!')
                        );
                        setIsLoading(false);
                    });
            };

            const handleResendRequest = () => {
                setIsLoading(true);
                API({
                    url: API_REPLAY_REQUESTS(replyRequestAgentId, userRequestIdsWithRequestType),
                    headers: {
                        'X-Csrf-Token': csrfToken,
                    },
                    method: 'POST',
                })
                    .then(res => {
                        if (res) {
                            setIsFinished(true);
                            setIsLoading(false);
                            props.data.requestAgents.forEach(agent => {
                                if (agent.agentId === replyRequestAgentId) {
                                    agent.requestAgentStatus = 'RETRY';
                                }
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        setErrorMessage(
                            getErrorMessage('Something went wrong. Please try again later..!')
                        );
                        setIsLoading(false);
                    });
            };

            const getErrorMessage = errorMessageText => {
                return (
                    <StyledInlineFeedbackDiv>
                        <InlineFeedback appearance="error">{errorMessageText}</InlineFeedback>
                    </StyledInlineFeedbackDiv>
                );
            };

            const getRequestAgentStatusHistoryBodyDialog = (props, close) => {
                let requestIds = [];
                obj.requestStatusHistory.forEach(requestStatus => {
                    if (
                        requestStatus.requestType === obj.messageType &&
                        obj.agentId === requestStatus.agentId
                    ) {
                        requestIds.push(
                            <StyledInnerPopUpDiv key={requestStatus.timestamp}>
                                <StyledCol>
                                    {getDisplayDate(requestStatus.timestamp * 1000)}
                                </StyledCol>
                                <StyledCol>{requestStatus.requestStatus}</StyledCol>
                            </StyledInnerPopUpDiv>
                        );
                    }
                });

                return (
                    <StyledModalDialog
                        {...props}
                        wide
                        header={<Heading level={6}>Request Status History</Heading>}
                        body={
                            <div>
                                <StyledDialogGridContainer>
                                    <StyledDialogHeader>Update Date</StyledDialogHeader>
                                    <StyledDialogHeader>Request Status</StyledDialogHeader>
                                </StyledDialogGridContainer>
                                <React.Fragment>{requestIds}</React.Fragment>
                            </div>
                        }
                        footer={
                            <StyledFooterButtonsContainer>
                                {errorMessage}
                                <Button>
                                    <TextButton onClick={close}>OK</TextButton>
                                </Button>
                            </StyledFooterButtonsContainer>
                        }
                    />
                );
            };

            content.push(
                <StyledGridContainer key={obj.requestAgentId}>
                    <StyledRow>{obj.requestAgentId}</StyledRow>
                    <StyledRow>
                        <TooltipPopper closeWith={['mouseLeave']} triggered={agentTooltip}>
                            <StyledTriggerText>{obj.agentName}</StyledTriggerText>
                        </TooltipPopper>
                        {(process.env.REACT_APP_STAGE.toUpperCase() === 'DEV' ||
                            process.env.REACT_APP_STAGE.toUpperCase() === 'STABLE') && (
                            <Trigger triggered={deactivateAgentBodyDialog} onClose={handleOnClose}>
                                <TextButton>Deactivate</TextButton>
                            </Trigger>
                        )}
                    </StyledRow>
                    <StyledRow>
                        <a
                            href={'https://zodiac.zgtools.net/services/' + obj.zodiacServiceName}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {obj.zodiacServiceName}
                        </a>
                    </StyledRow>
                    <StyledRow>{getDisplayDate(obj.updateDate)}</StyledRow>
                    <StyledRow>
                        {requestStatus(obj.requestAgentStatus)}&nbsp;
                        {(obj.requestAgentStatus === 'REJECTED' ||
                            obj.requestAgentStatus === 'PARTIAL_REJECTED') && (
                            <TooltipPopper closeWith={['click']} triggered={rejectReasonTooltip} />
                        )}
                        &nbsp;
                        {obj.requestAgentStatus === 'PARTIAL_REJECTED' && (
                            <TooltipPopper
                                closeWith={['click']}
                                triggered={requestAgentExcludeDeleteDataDomainTooltip}
                            >
                                <TriggerButton>
                                    <IconExclamationCircleOutline size="xs" fontColor="yellow300" />
                                </TriggerButton>
                            </TooltipPopper>
                        )}
                        &nbsp;
                        {(obj.requestAgentStatus === 'RETRY_SENT' ||
                            obj.requestAgentStatus === 'JIRA_TICKET_CREATED' ||
                            obj.requestAgentStatus === 'OVERDUE' ||
                            obj.requestAgentStatus === 'ERROR' ||
                            obj.requestAgentStatus === 'RESPONSE_ERROR') && (
                            <div>
                                <StyledLoadingButton
                                    buttonType="primary"
                                    onClick={() =>
                                        resendRequestAgentBodyDialog(
                                            obj.zodiacServiceName,
                                            obj.agentId,
                                            obj.messageType,
                                            obj.requestId
                                        )
                                    }
                                    loading={agentLoadingStates[obj.agentId]}
                                >
                                    <IconReload size="xs" fontColor="brandSecondary" />
                                </StyledLoadingButton>
                            </div>
                        )}
                        {isModalOpen && (
                            <ModalDialog
                                isOpen={isModalOpen}
                                onClose={() => {
                                    setIsModalOpen(false);
                                }}
                                wide
                                body="Do you really want to RESEND this user request for this data agent?"
                                footer={
                                    <StyledFooterButtonsContainer>
                                        {errorMessage}
                                        <ButtonGroup>
                                            <Button buttonType="primary" onClick={handleOnClose}>
                                                Close
                                            </Button>
                                            {isLoading ? (
                                                <StyledLoadingButtonDiv>
                                                    <LoadingButton
                                                        size="lg"
                                                        buttonType="primary"
                                                        loading
                                                    >
                                                        <IconReload size="xs" /> Processing
                                                    </LoadingButton>
                                                </StyledLoadingButtonDiv>
                                            ) : (
                                                <Button
                                                    disabled={!isUserBelongsToDataAgent}
                                                    buttonType="primary"
                                                    onClick={() => handleResendRequest(obj)}
                                                >
                                                    <IconReload size="xs" /> Process
                                                </Button>
                                            )}
                                        </ButtonGroup>
                                        {isFinished && (
                                            <StyledReplayAlertContainer>
                                                <Alert
                                                    appearance="success"
                                                    body="Resend successfully...!"
                                                />
                                            </StyledReplayAlertContainer>
                                        )}
                                        {!isUserBelongsToDataAgent && (
                                            <StyledReplayAlertContainer>
                                                <Alert
                                                    appearance="error"
                                                    body="As you are not the owner of this data agent, you can't replay this request"
                                                />
                                            </StyledReplayAlertContainer>
                                        )}
                                    </StyledFooterButtonsContainer>
                                }
                            />
                        )}
                        {isErrorModalOpen && (
                            <ModalDialog
                                isOpen={isErrorModalOpen}
                                onClose={() => {
                                    setIsErrorModalOpen(false);
                                }}
                                wide
                                dividers={false}
                                body={
                                    <StyledParagraph>
                                        Something went wrong. Please try again later..!
                                    </StyledParagraph>
                                }
                            />
                        )}
                        {
                            <Trigger
                                triggered={getRequestAgentStatusHistoryBodyDialog}
                                onClose={handleOnClose}
                            >
                                <TextButton>Request Status History</TextButton>
                            </Trigger>
                        }
                    </StyledRow>
                    <StyledRow>{obj.messageType}</StyledRow>
                </StyledGridContainer>
            );
        });

        return content;
    };

    const reportContent =
        props.data.length <= 0 ? (
            <Alert appearance="warning" body="No data found." />
        ) : (
            <Spacer>
                <StyledGridContainer>
                    <StyledHeader>Req Agent Id</StyledHeader>
                    <StyledHeader>Agent Name</StyledHeader>
                    <StyledHeader>Zodiac Service Name</StyledHeader>
                    <StyledHeader>Update Date</StyledHeader>
                    <StyledHeader>Request Status</StyledHeader>
                    <StyledHeader>Message Type</StyledHeader>
                </StyledGridContainer>
                {getContent(props)}
            </Spacer>
        );

    return (
        <div>
            <Card>
                <Collapsible defaultCollapsed>
                    {(collapsed, toggle) => (
                        <React.Fragment>
                            <CardHeader>
                                Agent Request Status:{' '}
                                {requestStatus(props.data.requestStatus, true)}
                            </CardHeader>
                            <CardSection>
                                <Paragraph>
                                    User RequestId:{' '}
                                    <StyledHeaderSpan>
                                        {props.data.requestDetails.requestId}
                                    </StyledHeaderSpan>
                                </Paragraph>
                                <Paragraph>
                                    Request Type:{' '}
                                    {requestStatus(props.data.requestDetails.requestType, true)}
                                </Paragraph>
                                <Paragraph>
                                    Request Timestamp:{' '}
                                    <strong>
                                        {getDisplayDate(props.data.requestDetails.timestamp * 1000)}
                                    </strong>
                                </Paragraph>
                            </CardSection>
                            {collapsed ? null : (
                                <CardSection>
                                    <Spacer>
                                        <StyledAggregatedResultGridContainer>
                                            <StyledAggregatedResultHeader>
                                                Request Agent Status
                                            </StyledAggregatedResultHeader>
                                            <StyledAggregatedResultHeader>
                                                Priority Level
                                            </StyledAggregatedResultHeader>
                                            <StyledAggregatedResultHeader>
                                                Count
                                            </StyledAggregatedResultHeader>
                                        </StyledAggregatedResultGridContainer>
                                        {getAggregatedResult(props.data.aggregatedResult)}
                                    </Spacer>
                                </CardSection>
                            )}
                            <CardButton onClick={toggle}>
                                {collapsed ? 'Show' : 'Less'} Aggregated Result
                            </CardButton>
                        </React.Fragment>
                    )}
                </Collapsible>
            </Card>

            {reportContent}
        </div>
    );
};

DisplayAgentRequestStatus.propTypes = {
    data: PropTypes.any,
};

DisplayAgentRequestStatus.defaultProps = {
    data: [],
};

export default DisplayAgentRequestStatus;
