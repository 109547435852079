import React, { useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import VerifyEmail from '../components/verification/VerifyEmail';
import FoundAdditionalEmail from '../components/verification/FoundAdditionalEmail';
import VerifyEmailConfirmed from '../components/verification/VerifyEmailConfirmed';
import Loading from '../components/verification/Loading';
import AlertError from '../components/verification/AlertError';
import AlertOptOutOfSaleError from '../components/verification/AlertOptOutOfSaleError';
import AreYouSure from '../components/confirmation/AreYouSure';
import SigninContext, { ACTIONS as SIGNIN_ACTIONS } from '../contexts/SigninContext';
import API from '../utils/api';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import produce from 'immer';

import {
    Button,
    Paragraph,
    ModalDialog,
    IconCheckmarkCircle,
    TextButton,
    spaceMixin,
} from '@zillow/constellation';
import {
    API_VERIFICATION_GENERATE,
    API_VERIFICATION_VALIDATE,
    API_VERIFICATION_REMOVE,
    API_REQUESTS_EXPORT_FUNC,
    API_REQUESTS_DELETE,
    API_REQUESTS,
    API_REQUESTS_OPT_OUT_OF_SALE,
} from '../constants/ApiConstants';
import { VERIFICATION_STATE } from '../constants/VerificationConstants';
import { REQUEST_TYPE, REQUEST_STATUS } from '../constants/RequestsConstants';
import { parseErrorResponse } from '../utils';

const StyledModalDialog = styled(ModalDialog)`
    text-align: center;

    button[class^='DialogClose-'] {
        display: none;
    }
    div[class^='DialogBody-'] {
        border: none !important;
        margin: 10px !important;
    }
`;

const StyledWrapper = styled.div`
    .email-wrapper {
        word-break: break-word;
    }
`;

const StyledParagraph = styled(Paragraph)`
    max-width: 792px;
    margin-top: ${spaceMixin('md')};
    margin-bottom: ${spaceMixin('md')};
`;

const StyledIconCheckmarkCircle = styled(IconCheckmarkCircle)`
    font-size: 60px;
`;

const StyledSuccessIcon = styled.div`
    margin-bottom: 20px;
`;

const StyledEmailParagraph = styled(Paragraph)`
    font-weight: 600;
`;

// reducer actions
export const ACTIONS = {
    OPEN_MODAL: 'OPEN_MODAL',
    CLOSE_MODAL: 'CLOSE_MODAL',
    SET_USER_EMAILS_COPY: 'SET_USER_EMAILS_COPY',
    SET_IS_EXPORT_REQUEST_PENDING: 'SET_IS_EXPORT_REQUEST_PENDING',
    SET_IS_DELETE_REQUEST_PENDING: 'SET_IS_DELETE_REQUEST_PENDING',
    SET_VERIFICATION_CONTENT: 'SET_VERIFICATION_CONTENT',
    RESET_AND_CLOSE_MODAL: 'RESET_AND_CLOSE_MODAL',
    HANDLE_EXPORT_RESPONSE: 'HANDLE_EXPORT_RESPONSE',
    HANDLE_EXPORT_RESPONSE_ERROR: 'HANDLE_EXPORT_RESPONSE_ERROR',
    HANDLE_DELETE_RESPONSE: 'HANDLE_DELETE_RESPONSE',
    HANDLE_DELETE_RESPONSE_ERROR: 'HANDLE_DELETE_RESPONSE_ERROR',
    SET_IS_OPT_OUT_OF_SALE_REQUEST_EXISTS: 'SET_IS_OPT_OUT_OF_SALE_REQUEST_EXISTS',
    HANDLE_OPT_OUT_OF_SALE_RESPONSE: 'HANDLE_OPT_OUT_OF_SALE_RESPONSE',
    HANDLE_OPT_OUT_OF_SALE_RESPONSE_ERROR: 'HANDLE_OPT_OUT_OF_SALE_RESPONSE_ERROR',
};

// reducer
const verificationModalDialogReducer = produce((draft, action) => {
    switch (action.type) {
        case ACTIONS.OPEN_MODAL:
            draft.isOpen = true;
            return;
        case ACTIONS.CLOSE_MODAL:
            draft.isOpen = false;
            return;
        case ACTIONS.SET_USER_EMAILS_COPY:
            draft.userEmailsCopy = action.payload.userEmails;
            return;
        case ACTIONS.SET_IS_EXPORT_REQUEST_PENDING:
            draft.isExportRequestPending = action.payload.isExportRequestPending;
            return;
        case ACTIONS.SET_IS_DELETE_REQUEST_PENDING:
            draft.isDeleteRequestPending = action.payload.isDeleteRequestPending;
            return;
        case ACTIONS.SET_IS_REQUESTS_REQUEST_PENDING:
            draft.isRequestsRequestPending = action.payload.isRequestsRequestPending;
            return;
        case ACTIONS.SET_IS_OPT_OUT_OF_SALE_REQUEST_EXISTS:
            draft.isOptOutOfSaleRequestExist = action.payload.isOptOutOfSaleRequestExist;
            return;
        case ACTIONS.SET_VERIFICATION_CONTENT:
            draft.verificationContent = action.payload.verificationContent;
            return;
        case ACTIONS.RESET_AND_CLOSE_MODAL:
            draft.isOpen = false;
            draft.verificationContent = action.payload.verificationContent;
            return;
        case ACTIONS.HANDLE_EXPORT_RESPONSE:
            draft.verificationContent = action.payload.verificationContent;
            draft.isExportRequestPending = false;
            return;
        case ACTIONS.HANDLE_EXPORT_RESPONSE_ERROR:
            draft.serverError = action.payload.serverError;
            draft.verificationContent = action.payload.verificationContent;
            draft.isExportRequestPending = false;
            return;
        case ACTIONS.HANDLE_DELETE_RESPONSE:
            draft.verificationContent = action.payload.verificationContent;
            draft.isDeleteRequestPending = false;
            return;
        case ACTIONS.HANDLE_DELETE_RESPONSE_ERROR:
            draft.serverError = action.payload.serverError;
            draft.verificationContent = action.payload.verificationContent;
            draft.isDeleteRequestPending = false;
            return;
        case ACTIONS.HANDLE_REQUESTS_RESPONSE:
            draft.verificationContent = action.payload.verificationContent;
            draft.isRequestsRequestPending = false;
            return;
        case ACTIONS.HANDLE_REQUESTS_RESPONSE_ERROR:
            draft.serverError = action.payload.serverError;
            draft.verificationContent = action.payload.verificationContent;
            draft.isRequestsRequestPending = false;
            return;
        case ACTIONS.HANDLE_OPT_OUT_OF_SALE_RESPONSE:
            draft.verificationContent = action.payload.verificationContent;
            return;
        case ACTIONS.HANDLE_OPT_OUT_OF_SALE_RESPONSE_ERROR:
            draft.serverError = action.payload.serverError;
            draft.verificationContent = action.payload.verificationContent;
            draft.isOptOutOfSaleRequestExist = false;
            return;
        default:
            return;
    }
});

const VerificationModalDialog = props => {
    const {
        buttonText,
        dataCategories,
        defaultOpen,
        downloadUrl,
        initialVerificationContentDisplayName,
        type,
        isDisabled,
    } = props;

    const {
        state: { csrfToken, userEmails, primaryEmail },
        dispatch: signinDispatch,
    } = useContext(SigninContext);

    const initialState = {
        isOpen: !!defaultOpen || false,
        verificationContent: initialVerificationContentDisplayName,
        userEmailsCopy: userEmails,
        serverError: null,
        isExportRequestPending: false,
        isDeleteRequestPending: false,
        isRequestsRequestPending: false,
        isOptOutOfSaleRequestExist: false,
    };

    const [
        {
            isOpen,
            verificationContent,
            userEmailsCopy,
            serverError,
            isExportRequestPending,
            isDeleteRequestPending,
            isRequestsRequestPending,
            isOptOutOfSaleRequestExist,
        },
        verificationDispatch,
    ] = useReducer(verificationModalDialogReducer, initialState);

    const initUserEmail = emailId => {
        const nextUserEmailsCopy = produce(userEmailsCopy, draft => {
            let userEmail = _find(draft, { id: emailId });
            userEmail.verificationState = VERIFICATION_STATE.PENDING;
        });
        // console.log('INIT', nextUserEmailsCopy);
        return nextUserEmailsCopy;
    };

    const verifyUserEmail = emailId => {
        const nextUserEmailsCopy = produce(userEmailsCopy, draft => {
            let userEmail = _find(draft, { id: emailId });
            userEmail.verificationState = VERIFICATION_STATE.VERIFIED;
        });
        // console.log('VERIFY', nextUserEmailsCopy);
        return nextUserEmailsCopy;
    };

    const denyUserEmail = emailId => {
        const nextUserEmailsCopy = produce(userEmailsCopy, draft => {
            let userEmail = _find(draft, { id: emailId });
            userEmail.verificationState = VERIFICATION_STATE.DENIED;
        });
        // console.log('DENY', nextUserEmailsCopy);
        return nextUserEmailsCopy;
    };

    const generateCode = userEmail => {
        // FIXME: POST should pass params in the payload
        verificationDispatch({
            type: ACTIONS.SET_USER_EMAILS_COPY,
            payload: {
                userEmails: initUserEmail(userEmail.id),
            },
        });
        API({
            method: 'post',
            url: `${API_VERIFICATION_GENERATE}?v=1&emailId=${userEmail.id}&emailType=${type}`,
            headers: {
                'X-Csrf-Token': csrfToken,
            },
        })
            .then(() => {
                // console.log('successfully generate code for:', userEmail);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const verifyCode = (code, userEmail) => {
        // FIXME: POST should pass params in the payload
        return API({
            method: 'post',
            url: `${API_VERIFICATION_VALIDATE}?v=1&code=${code}`,
            headers: {
                'X-Csrf-Token': csrfToken,
            },
        }).then(res => {
            if (res.data.success) {
                verificationDispatch({
                    type: ACTIONS.SET_USER_EMAILS_COPY,
                    payload: {
                        userEmails: verifyUserEmail(userEmail.id),
                    },
                });
            }
            return res;
        });
    };

    const exportData = () => {
        if (!isExportRequestPending) {
            // console.log('start exporting');
            verificationDispatch({
                type: ACTIONS.SET_IS_EXPORT_REQUEST_PENDING,
                payload: {
                    isExportRequestPending: true,
                },
            });
            API({
                method: 'post',
                url: API_REQUESTS_EXPORT_FUNC({ dataCategories }),
                headers: {
                    'X-Csrf-Token': csrfToken,
                },
            })
                .then(res => {
                    verificationDispatch({
                        type: ACTIONS.HANDLE_EXPORT_RESPONSE,
                        payload: {
                            verificationContent: VerifyEmailConfirmed.displayName,
                        },
                    });
                    // console.table(res.data);
                })
                .catch(error => {
                    verificationDispatch({
                        type: ACTIONS.HANDLE_EXPORT_RESPONSE_ERROR,
                        payload: {
                            serverError: parseErrorResponse(error),
                            verificationContent: AlertError.displayName,
                        },
                    });
                });
        }
    };

    const deleteData = () => {
        if (!isDeleteRequestPending) {
            // console.log('start deleting');
            verificationDispatch({
                type: ACTIONS.SET_IS_DELETE_REQUEST_PENDING,
                payload: {
                    isDeleteRequestPending: true,
                },
            });
            API({
                method: 'post',
                url: API_REQUESTS_DELETE,
                headers: {
                    'X-Csrf-Token': csrfToken,
                },
            })
                .then(res => {
                    verificationDispatch({
                        type: ACTIONS.HANDLE_DELETE_RESPONSE,
                        payload: {
                            verificationContent: VerifyEmailConfirmed.displayName,
                        },
                    });
                    // console.table(res.data);
                })
                .catch(error => {
                    verificationDispatch({
                        type: ACTIONS.HANDLE_DELETE_RESPONSE_ERROR,
                        payload: {
                            serverError: parseErrorResponse(error),
                            verificationContent: AlertError.displayName,
                        },
                    });
                });
        }
    };

    const fetchRequestsData = () => {
        if (!isRequestsRequestPending) {
            // console.log('fetch requests data');

            verificationDispatch({
                type: ACTIONS.SET_IS_REQUESTS_REQUEST_PENDING,
                payload: {
                    isRequestsRequestPending: true,
                },
            });

            API({
                url: API_REQUESTS,
                headers: {
                    'X-Csrf-Token': csrfToken,
                },
            })
                .then(res => {
                    const requests = _get(res, 'data.requests', []);
                    const downloadRequests = requests.filter(
                        r =>
                            r.requestType === REQUEST_TYPE.EXPORT &&
                            r.requestStatus === REQUEST_STATUS.FINISHED
                    );
                    if (downloadRequests.length > 0) {
                        signinDispatch({
                            type: SIGNIN_ACTIONS.SET_DOWNLOAD_REQUESTS,
                            payload: {
                                downloadRequests,
                            },
                        });
                    }
                })
                .catch(error => {
                    verificationDispatch({
                        type: ACTIONS.HANDLE_REQUESTS_RESPONSE_ERROR,
                        payload: {
                            serverError: parseErrorResponse(error),
                            verificationContent: AlertError.displayName,
                        },
                    });
                })
                .finally(() => {
                    signinDispatch({
                        type: SIGNIN_ACTIONS.SET_IS_DOWNLOAD_REQUESTS_CALCULATED,
                    });
                });
        }
    };

    const optOutOfSale = () => {
        if (!isOptOutOfSaleRequestExist) {
            // console.log('start exporting');
            verificationDispatch({
                type: ACTIONS.SET_IS_OPT_OUT_OF_SALE_REQUEST_EXISTS,
                payload: {
                    isOptOutOfSaleRequestExist: true,
                },
            });
            API({
                method: 'post',
                url: API_REQUESTS_OPT_OUT_OF_SALE,
                headers: {
                    'X-Csrf-Token': csrfToken,
                },
            })
                .then(res => {
                    verificationDispatch({
                        type: ACTIONS.HANDLE_OPT_OUT_OF_SALE_RESPONSE,
                        payload: {
                            verificationContent: VerifyEmailConfirmed.displayName,
                        },
                    });
                })
                .catch(error => {
                    verificationDispatch({
                        type: ACTIONS.HANDLE_OPT_OUT_OF_SALE_RESPONSE_ERROR,
                        payload: {
                            serverError: parseErrorResponse(error),
                            verificationContent: AlertError.displayName,
                        },
                    });
                });
        }
    };

    const downloadData = () => {
        window.open(downloadUrl);
    };

    const syncUserEmails = updatedUserEmails => {
        signinDispatch({
            type: SIGNIN_ACTIONS.SYNC_USER_EMAILS,
            payload: {
                userEmails: updatedUserEmails,
            },
        });
    };

    const onCloseButtonClick = () => {
        verificationDispatch({
            type: ACTIONS.RESET_AND_CLOSE_MODAL,
            payload: {
                verificationContent: initialVerificationContentDisplayName,
            },
        });
        syncUserEmails(userEmailsCopy);
    };

    const unverifiedUserEmails = userEmailsCopy.filter(
        ue => ue.verificationState === VERIFICATION_STATE.UNVERIFIED
    );
    const pendingUserEmails = userEmailsCopy.filter(
        ue => ue.verificationState === VERIFICATION_STATE.PENDING
    );

    let isVerificationNeeded = unverifiedUserEmails.length > 0 || pendingUserEmails.length > 0;
    // Only Primary Email Verification is needed for data download
    const isPrimaryEmailVerified =
        userEmailsCopy.filter(
            ue => ue.email === primaryEmail && ue.verificationState === VERIFICATION_STATE.VERIFIED
        ).length > 0;
    if (
        (type === REQUEST_TYPE.REQUESTS || type === REQUEST_TYPE.DOWNLOAD) &&
        isPrimaryEmailVerified
    ) {
        isVerificationNeeded = false;
    }

    let RenderContent = Loading;
    let newProps = {};

    // Render Functions Start Here
    const renderAreYouSure = () => {
        RenderContent = AreYouSure;
        newProps = {
            onClickConfirm: () => {
                verificationDispatch({
                    type: ACTIONS.SET_VERIFICATION_CONTENT,
                    payload: {
                        verificationContent: VerifyEmail.displayName,
                    },
                });
            },
            subtitle:
                "If you delete your data and account(s), this action will be permanent. You won't be able to recover your data.",
            disclaimer:
                "Note: You also won't be able to create an account with these email addresses for 90 days.",
            confirmText: 'Delete data and account(s)',
        };
    };

    const renderVerifyEmail = () => {
        RenderContent = VerifyEmail;
        const currentUserEmail = pendingUserEmails[0] || unverifiedUserEmails[0];
        newProps = {
            email: currentUserEmail.email,
            onComponentMount: () => {
                if (currentUserEmail.verificationState === VERIFICATION_STATE.UNVERIFIED) {
                    generateCode(currentUserEmail);
                }
            },
            onClickNext: () => {
                if (unverifiedUserEmails.length > 0) {
                    verificationDispatch({
                        type: ACTIONS.SET_VERIFICATION_CONTENT,
                        payload: {
                            verificationContent: FoundAdditionalEmail.displayName,
                        },
                    });
                } else {
                    // All Emails Verified or Denied
                    verificationDispatch({
                        type: ACTIONS.SET_VERIFICATION_CONTENT,
                        payload: {
                            verificationContent: Loading.displayName,
                        },
                    });
                }
            },
            onResendCode: () => {
                generateCode(currentUserEmail);
            },
            onValidateCodePromise: code => {
                return verifyCode(code, currentUserEmail);
            },
        };
    };

    const renderFoundAdditionaEmail = () => {
        RenderContent = FoundAdditionalEmail;
        const currentUserEmail = pendingUserEmails[0] || unverifiedUserEmails[0];
        let goNext = () => {
            verificationDispatch({
                type: ACTIONS.SET_VERIFICATION_CONTENT,
                payload: {
                    verificationContent: VerifyEmail.displayName,
                },
            });
        };
        newProps = {
            email: currentUserEmail.email,
            order: _findIndex(userEmailsCopy, currentUserEmail) + 1,
            totalNumber: userEmailsCopy.length,
            onDenyEmailPromise: () => {
                goNext = () => {
                    // console.log('deny email');
                    const nextUserEmails = denyUserEmail(currentUserEmail.id);
                    if (
                        nextUserEmails.filter(
                            nue =>
                                nue.verificationState === VERIFICATION_STATE.UNVERIFIED ||
                                nue.verificationState === VERIFICATION_STATE.PENDING
                        ).length > 0
                    ) {
                        verificationDispatch({
                            type: ACTIONS.SET_VERIFICATION_CONTENT,
                            payload: {
                                verificationContent: FoundAdditionalEmail.displayName,
                            },
                        });
                    } else {
                        verificationDispatch({
                            type: ACTIONS.SET_VERIFICATION_CONTENT,
                            payload: {
                                verificationContent: Loading.displayName,
                            },
                        });
                    }
                    verificationDispatch({
                        type: ACTIONS.SET_USER_EMAILS_COPY,
                        payload: {
                            userEmails: nextUserEmails,
                        },
                    });
                };
                return API({
                    method: 'post',
                    url: `${API_VERIFICATION_REMOVE}?v=1&emailId=${currentUserEmail.id}`,
                    headers: {
                        'X-Csrf-Token': csrfToken,
                    },
                });
            },
            onClickNext: () => {
                /**
                 * wrapping function inside this scope is necessary,
                 * otherwise, the content won't change based on Verify/Deny
                 * */
                goNext();
            },
        };
    };

    const renderLoading = ({ withRequest }) => {
        //This renders when requests are pending
        RenderContent = Loading;
        if (withRequest) {
            if (type === REQUEST_TYPE.EXPORT) {
                exportData();
            } else if (type === REQUEST_TYPE.DELETE) {
                deleteData();
            } else if (type === REQUEST_TYPE.DOWNLOAD) {
                downloadData();
                onCloseButtonClick();
            } else if (type === REQUEST_TYPE.REQUESTS) {
                fetchRequestsData();
                onCloseButtonClick();
            } else if (type === REQUEST_TYPE.OPT_OUT_OF_SALE) {
                optOutOfSale();
            }
        }
    };

    const renderAlertError = () => {
        RenderContent = AlertError;
        newProps = {
            error: serverError,
            onCloseButtonClick: onCloseButtonClick,
            buttonText: 'Back to privacy home',
            redirectTo: '/',
        };
    };

    const renderAlertOptOutOfSaleError = () => {
        RenderContent = AlertOptOutOfSaleError;
        newProps = {
            error: serverError,
            callbackFun: props.callbackFun,
            onCloseButtonClick: onCloseButtonClick,
        };
    };

    const renderVerifyEmailConfirmed = () => {
        RenderContent = VerifyEmailConfirmed;
        if (type === REQUEST_TYPE.EXPORT) {
            newProps = {
                title: 'Thanks for submitting your request',
                content: (
                    <StyledWrapper>
                        <Paragraph marginTop="lg" marginBottom="md">
                            We'll get right to work on this. Because of the complexity of our data
                            systems and the number of requests we receive, it could take up to 45
                            days to complete your data archive. We'll email you when it's ready at:
                        </Paragraph>
                        <StyledEmailParagraph fontType="h5" className="email-wrapper">
                            {primaryEmail}
                        </StyledEmailParagraph>
                    </StyledWrapper>
                ),
                onCloseButtonClick,
            };
        } else if (type === REQUEST_TYPE.DELETE) {
            newProps = {
                title: 'Your request has been submitted',
                content: (
                    <>
                        <Paragraph marginTop="lg" marginBottom="md">
                            Unless you hear otherwise from us, your data will be deleted within 45
                            days.
                        </Paragraph>
                    </>
                ),
                buttonText: 'Back to privacy home',
                onCloseButtonClick,
                redirectTo: '/',
            };
        } else if (type === REQUEST_TYPE.OPT_OUT_OF_SALE) {
            newProps = {
                title: (
                    <>
                        <StyledSuccessIcon>
                            {' '}
                            <strong>
                                <StyledIconCheckmarkCircle color="blue" />
                            </strong>
                        </StyledSuccessIcon>
                        Your advertising cookies have been disabled
                    </>
                ),
                content: (
                    <>
                        <StyledParagraph>
                            Your advertising cookies have been disabled
                        </StyledParagraph>
                        <StyledParagraph>
                            Your request to disable your advertising cookies has been received, and
                            it's been implemented on this browser. We'll apply it to your other
                            associated browsers and devices the next time you sign in with those
                            browsers or devices.
                        </StyledParagraph>
                        <TextButton
                            as="a"
                            onClick={() => {
                                onCloseButtonClick();
                            }}
                        >
                            Back to Manage cookies
                        </TextButton>
                    </>
                ),
                buttonText: 'Back to Privacy Portal',
                redirectTo: '/',
                footerLinkType: 'link',
            };
            props.callbackFun();
        }
    };

    // Business Logic Starts Here
    if (isOpen) {
        if (verificationContent === AreYouSure.displayName) {
            renderAreYouSure();
        } else if (verificationContent === VerifyEmail.displayName) {
            if (isVerificationNeeded) {
                renderVerifyEmail();
            } else {
                renderLoading({ withRequest: true });
            }
        } else if (verificationContent === FoundAdditionalEmail.displayName) {
            renderFoundAdditionaEmail();
        } else if (verificationContent === Loading.displayName) {
            renderLoading({ withRequest: true });
        } else if (verificationContent === AlertError.displayName) {
            renderAlertError();
        } else if (verificationContent === AlertOptOutOfSaleError.displayName) {
            renderAlertOptOutOfSaleError();
        } else if (verificationContent === VerifyEmailConfirmed.displayName) {
            renderVerifyEmailConfirmed();
        } else {
            renderLoading({});
        }
    }

    return (
        <>
            {buttonText && (
                <Button
                    buttonType="primary"
                    disabled={isDisabled}
                    onClick={() => {
                        verificationDispatch({
                            type: ACTIONS.OPEN_MODAL,
                        });
                    }}
                >
                    {buttonText}
                </Button>
            )}
            <StyledModalDialog {...props} isOpen={isOpen} body={<RenderContent {...newProps} />} />
        </>
    );
};

VerificationModalDialog.propTypes = {
    buttonText: PropTypes.string,
    dataCategories: PropTypes.array,
    defaultOpen: PropTypes.bool,
    downloadUrl: PropTypes.string,
    initialVerificationContentDisplayName: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    callbackFun: PropTypes.func,
};

VerificationModalDialog.defaultProps = {
    buttonText: null,
    dataCategories: [],
};

export default VerificationModalDialog;
