import React, { useContext } from 'react';
import LandingPage from '../pages/LandingPage';
import DeleteDataPage from '../pages/DeleteDataPage';
import DownloadDataPage from '../pages/DownloadDataPage';
import ExportDataPage from '../pages/ExportDataPage';
import ZillowOAuthPage from '../pages/ZillowOAuthPage';
import TruliaSSOPage from '../pages/TruliaSSOPage';
import HotpadsSSOPage from '../pages/HotpadsSSOPage';
import StreetEasyOAuthPage from '../pages/StreetEasyOAuthPage';
import PrivacySupportPage from '../pages/PrivacySupportPage';
import CaptchaWrappedManageCookiePage from '../pages/ManageCookiePage';
import NotFoundPage from '../pages/NotFoundPage';
import SigninContext from '../contexts/SigninContext';
import AdminPage from '../pages/AdminPage';
import CookieOptOutSuccessPage from '../pages/CookieOptOutSuccessPage';
import CookieOptOutError from '../pages/CookieOptOutError';
import OptoutTestCookiePage from '../pages/OptoutTestCookiePage';
import CcpaMetricsPage from '../pages/CcpaMetricsPage';
import CorrectYourDataPage from '../pages/CorrectYourDataPage';

import { Redirect, Route, Switch } from 'react-router-dom';

const AppRoutes = () => {
    const {
        state: { isAuthenticated },
    } = useContext(SigninContext);

    return (
        <Switch>
            <Route path="/" exact component={LandingPage} />
            <Route path="/cookie" component={CaptchaWrappedManageCookiePage} />
            <Route
                path="/export"
                component={() => (isAuthenticated ? <ExportDataPage /> : <Redirect to="/" />)}
            />
            <Route
                path="/delete"
                component={() => (isAuthenticated ? <DeleteDataPage /> : <Redirect to="/" />)}
            />
            <Route
                path="/support"
                component={() => (isAuthenticated ? <PrivacySupportPage /> : <Redirect to="/" />)}
            />
            <Route
                path="/download"
                component={() => {
                    return isAuthenticated ? <DownloadDataPage /> : <Redirect to="/#download" />;
                }}
            />
            <Route
                path="/admin"
                component={() => (isAuthenticated ? <AdminPage /> : <Redirect to="/" />)}
            />
            <Route path="/correction" component={CorrectYourDataPage} />
            <Route path="/zillowOAuth" component={ZillowOAuthPage} />
            <Route path="/truliaSSO" component={TruliaSSOPage} />
            <Route path="/hotpadsSSO" component={HotpadsSSOPage} />
            <Route path="/streetEasyOAuth" component={StreetEasyOAuthPage} />
            <Route path="/cookieOptOutSuccess" component={CookieOptOutSuccessPage} />
            <Route path="/cookieOptOutError" component={CookieOptOutError} />
            <Route path="/optoutTestCookie" component={OptoutTestCookiePage} />
            <Route path="/metrics" component={CcpaMetricsPage} />
            <Route component={NotFoundPage} />
        </Switch>
    );
};

export default AppRoutes;
