import React from 'react';
import { PageWrapper, PageMainContent, PageSection } from '../common/Page';
import styled from 'styled-components';
import { Chart } from 'react-google-charts';
import { withRouter } from 'react-router-dom';
import {
    IconArrowLeftOutline,
    Flex,
    Heading,
    Paragraph,
    spaceMixin,
    mediaBreakpointMixin,
} from '@zillow/constellation';

const StyledHeading = styled(Heading)`
    padding: 0 ${spaceMixin('md')};
    text-align: center;
`;

const StyledMainHeading = styled(StyledHeading)`
    @media ${mediaBreakpointMixin('lg')} {
      margin: 80px
      margin-bottom: 0;
    }
`;

const StyledChartContent = styled(StyledHeading)`
    @media ${mediaBreakpointMixin('lg')} {
      margin-bottom: 0
      font-weight: 600
      font-size: 14px
    }
`;

const StyledNoteContent = styled(StyledHeading)`
    @media ${mediaBreakpointMixin('lg')} {
      margin-bottom: 0
      font-weight: 600
      font-size: 10px
      color: ${props => props.theme.constellation?.colors.red500};
    }
`;

const StyledChartSection = styled(PageSection)`
    margin-left: 28%;
    width: 47%;
`;

const StyledActionCardsFlex = styled(Flex)`
    width: 85%;
    flex-direction: column;

    @media ${mediaBreakpointMixin('lg')} {
      margin: 40px auto;
      max-width: 1350px;
      height: 420px
      flex-direction: row;
      justify-content: space-between;
      flex-grow: 1;
      flex-basis: 0;
      > div {
          width: 40%;
      }
    }
`;

const StyledDiv = styled.div`
    background-color: #fff;
    overflow: auto;
    margin: ${spaceMixin('md')} 50px;
    position: relative;
`;

const StyledLastSingleDiv = styled.div`
    height: 400px
    background-color: #fff;
    overflow: auto;
    margin: ${spaceMixin('md')} 50px;
    position: relative;
`;

const StyledLink = styled(Paragraph)`
    margin-left: ${spaceMixin('lg')};
    margin-top: ${spaceMixin('xl')};
`;

const CcpaMetricsPage = ({ location: { hash } }) => {
    let requests = {};
    let isOptOutCookieYear = false;
    if (hash === '#2020') {
        isOptOutCookieYear = true;
        requests = {
            year: 2020,
            total: 13340,
            total_export: 3210,
            total_delete: 10130,
            total_opt_out: 0,
            export: {
                completed: 3210,
                denied: 0,
            },
            delete: {
                completed: 9101,
                denied: 1029,
            },
            opt_out: {
                completed: 402386,
                denied: 0,
            },
            median: {
                export: 19,
                delete: 16,
                opt_out: 0,
            },
        };
    } else if (hash === '#2021') {
        requests = {
            year: 2021,
            total: 29351,
            total_export: 3373,
            total_delete: 10270,
            total_opt_out: 15708,
            export: {
                completed: 3373,
                denied: 0,
            },
            delete: {
                completed: 9570,
                denied: 700,
            },
            opt_out: {
                completed: 15708,
                denied: 0,
            },
            median: {
                export: 18,
                delete: 15,
                opt_out: 2,
            },
        };
    } else if (hash === '#2022') {
        requests = {
            year: 2022,
            total: 54719,
            total_export: 3821,
            total_delete: 25900,
            total_opt_out: 24998,
            export: {
                completed: 3821,
                denied: 0,
            },
            delete: {
                completed: 25876,
                denied: 24,
            },
            opt_out: {
                completed: 24998,
                denied: 0,
            },
            median: {
                export: 17.5,
                delete: 14.5,
                opt_out: 1,
            },
        };
    } else if (hash === '#2023') {
        requests = {
            year: 2023,
            total: 64796,
            total_export: 2522,
            total_delete: 45364,
            total_opt_out: 16910,
            export: {
                completed: 2522,
                denied: 0,
            },
            delete: {
                completed: 45330,
                denied: 34,
            },
            opt_out: {
                completed: 16910,
                denied: 0,
            },
            median: {
                export: 21,
                delete: 25,
                opt_out: 6.5,
            },
        };
    }
    return (
        <PageWrapper backgroundColor="#f4f4f4">
            <PageMainContent maxWidth={1440} backgroundColor="#f4f4f4">
                <StyledLink>
                    <strong>
                        <a href="/">
                            {' '}
                            <IconArrowLeftOutline size="sm" />
                            Back to privacy home
                        </a>
                    </strong>
                </StyledLink>
                <StyledMainHeading level="2" serif>
                    Privacy Metrics - {requests.year}
                </StyledMainHeading>
                <StyledActionCardsFlex display="flex">
                    <StyledDiv>
                        <Chart
                            width={'400px'}
                            height={'320px'}
                            chartType="PieChart"
                            data={[
                                ['Requests', 'requests per Year'],
                                ['Export', requests.total_export],
                                ['Delete', requests.total_delete],
                                ['Opt-out', requests.total_opt_out],
                            ]}
                            options={{
                                title: 'Requests Addressed',
                                chartArea: {
                                    left: 20,
                                    top: 45,
                                    width: '100%',
                                    height: '75%',
                                },
                                titleTextStyle: {
                                    fontSize: 16,
                                    bold: true,
                                },
                                legend: {
                                    textStyle: {
                                        fontSize: 15,
                                    },
                                },
                                pieSliceText: 'value',
                                pieSliceTextStyle: {
                                    fontSize: 15,
                                    bold: true,
                                },
                                slices: {
                                    0: { color: 'blue' },
                                    1: { color: 'orange' },
                                    2: { color: 'red' },
                                },
                                tooltip: { showColorCode: true },
                            }}
                        />
                        <StyledChartContent> Export - {requests.total_export} </StyledChartContent>
                        <StyledChartContent> Delete - {requests.total_delete} </StyledChartContent>
                        {isOptOutCookieYear ? (
                            ''
                        ) : (
                            <StyledChartContent>
                                {' '}
                                Opt-Out - {requests.total_opt_out}{' '}
                            </StyledChartContent>
                        )}
                    </StyledDiv>
                    <StyledDiv>
                        <Chart
                            width={'400px'}
                            height={'320px'}
                            chartType="PieChart"
                            data={[
                                ['Requests', 'requests per Year'],
                                ['Completed', requests.opt_out.completed],
                                ['Denied', 0],
                            ]}
                            options={{
                                title: isOptOutCookieYear
                                    ? 'Opt-out cookie Requests'
                                    : 'Opt out of sale Requests',
                                chartArea: {
                                    left: 20,
                                    top: 45,
                                    width: '100%',
                                    height: '75%',
                                },
                                titleTextStyle: {
                                    fontSize: 16,
                                    bold: true,
                                },
                                legend: {
                                    textStyle: {
                                        fontSize: 15,
                                    },
                                },
                                pieSliceText: 'value',
                                pieSliceTextStyle: {
                                    fontSize: 15,
                                    bold: true,
                                },
                                slices: {
                                    0: { color: 'blue' },
                                    1: { color: 'orange' },
                                    2: { color: 'red' },
                                },
                                tooltip: { showColorCode: true },
                            }}
                        />
                        <StyledChartContent>
                            {' '}
                            Completed - {requests.opt_out.completed}{' '}
                        </StyledChartContent>
                        <StyledChartContent>
                            {' '}
                            Denied - {requests.opt_out.denied}{' '}
                        </StyledChartContent>
                        <div>
                            {' '}
                            {isOptOutCookieYear ? (
                                <StyledNoteContent>*since June 2020</StyledNoteContent>
                            ) : (
                                ''
                            )}{' '}
                        </div>
                    </StyledDiv>
                </StyledActionCardsFlex>
                <StyledActionCardsFlex display="flex">
                    <StyledDiv>
                        <Chart
                            width={'400px'}
                            height={'320px'}
                            chartType="PieChart"
                            data={[
                                ['Requests', 'requests per Year'],
                                ['Completed', requests.export.completed],
                                ['Denied', requests.export.denied],
                            ]}
                            options={{
                                title: 'Export Requests',
                                chartArea: {
                                    left: 20,
                                    top: 45,
                                    width: '100%',
                                    height: '75%',
                                },
                                titleTextStyle: {
                                    fontSize: 16,
                                    bold: true,
                                },
                                legend: {
                                    textStyle: {
                                        fontSize: 15,
                                    },
                                },
                                pieSliceText: 'value',
                                pieSliceTextStyle: {
                                    fontSize: 15,
                                    bold: true,
                                },
                                slices: {
                                    0: { color: 'blue' },
                                    1: { color: 'orange' },
                                    2: { color: 'red' },
                                },
                                tooltip: { showColorCode: true },
                            }}
                        />
                        <StyledChartContent>
                            {' '}
                            Completed - {requests.export.completed}{' '}
                        </StyledChartContent>
                        <StyledChartContent> Denied - {requests.export.denied} </StyledChartContent>
                    </StyledDiv>
                    <StyledDiv>
                        <Chart
                            width={'400px'}
                            height={'320px'}
                            chartType="PieChart"
                            data={[
                                ['Requests', 'requests per Year'],
                                ['Completed', requests.delete.completed],
                                ['Denied', requests.delete.denied],
                            ]}
                            options={{
                                title: 'Delete Requests',
                                chartArea: {
                                    left: 20,
                                    top: 45,
                                    width: '100%',
                                    height: '75%',
                                },
                                titleTextStyle: {
                                    fontSize: 16,
                                    bold: true,
                                },
                                legend: {
                                    textStyle: {
                                        fontSize: 15,
                                    },
                                },
                                pieSliceText: 'value',
                                pieSliceTextStyle: {
                                    fontSize: 15,
                                    bold: true,
                                },
                                slices: {
                                    0: { color: 'blue' },
                                    1: { color: 'orange' },
                                    2: { color: 'red' },
                                },
                                tooltip: { showColorCode: true },
                            }}
                        />
                        <StyledChartContent>
                            {' '}
                            Completed - {requests.delete.completed}{' '}
                        </StyledChartContent>
                        <StyledChartContent> Denied - {requests.delete.denied} </StyledChartContent>
                    </StyledDiv>
                </StyledActionCardsFlex>
                <StyledChartSection>
                    <StyledLastSingleDiv>
                        <Chart
                            width={'400px'}
                            height={'350px'}
                            chartType="PieChart"
                            data={[
                                ['Requests', 'requests per Year'],
                                ['Export', requests.median.export],
                                ['Delete', requests.median.delete],
                                ['Opt-out', requests.median.opt_out],
                            ]}
                            options={{
                                title: 'Median number of days to complete request',
                                chartArea: {
                                    left: 20,
                                    top: 45,
                                    width: '100%',
                                    height: '75%',
                                },
                                titleTextStyle: {
                                    fontSize: 16,
                                    bold: true,
                                },
                                legend: {
                                    textStyle: {
                                        fontSize: 15,
                                    },
                                },
                                pieSliceText: 'value',
                                pieSliceTextStyle: {
                                    fontSize: 15,
                                    bold: true,
                                },
                                slices: {
                                    0: { color: 'blue' },
                                    1: { color: 'orange' },
                                    2: { color: 'red' },
                                },
                                tooltip: { showColorCode: true },
                            }}
                        />
                        <StyledChartContent> Export - {requests.median.export} </StyledChartContent>
                        <StyledChartContent> Delete - {requests.median.delete} </StyledChartContent>
                        {isOptOutCookieYear ? (
                            ''
                        ) : (
                            <StyledChartContent>
                                {' '}
                                Opt-Out - {requests.median.opt_out}{' '}
                            </StyledChartContent>
                        )}
                    </StyledLastSingleDiv>
                </StyledChartSection>
            </PageMainContent>
        </PageWrapper>
    );
};

export default withRouter(CcpaMetricsPage);
