import React from 'react';
import PropTypes from 'prop-types';
import SeparatorLine from '../../common/SeparatorLine';
import { Button, Heading, Paragraph } from '@zillow/constellation';

export const DISPLAY_NAME = 'ARE_YOU_SURE';

const AreYouSure = ({ mainTitle, subtitle, disclaimer, confirmText, onClickConfirm }) => (
    <>
        <Heading level="3" serif marginTop="sm">
            {mainTitle}
        </Heading>
        <Paragraph marginTop="lg" marginBottom="lg">
            {subtitle}
        </Paragraph>
        <Paragraph marginTop="lg" marginBottom="lg">
            <b>{disclaimer}</b>
        </Paragraph>
        <SeparatorLine width={16} marginTop="lg" />
        <Button buttonType="primary" onClick={onClickConfirm} marginTop="xl">
            {confirmText}
        </Button>
    </>
);

AreYouSure.propTypes = {
    mainTitle: PropTypes.string,
    subtitle: PropTypes.string.isRequired,
    disclaimer: PropTypes.string,
    confirmText: PropTypes.string,
    onClickConfirm: PropTypes.func.isRequired,
};

AreYouSure.defaultProps = {
    mainTitle: 'Are you sure?',
    confirmText: 'Confirm',
};

AreYouSure.displayName = DISPLAY_NAME;

export default AreYouSure;
