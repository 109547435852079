import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Alert, Heading, Spacer } from '@zillow/constellation';

const gridSize = '26% 74%';

const StyledGridContainer = styled.div`
    font-size: 15px;
    display: grid;
    align-items: start;
    grid-template-columns: ${gridSize};
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 10px;
`;

const StyledReportContainer = styled.div`
    max-height: 350px;
    overflow: hidden;
    overflow-y: scroll;
`;

const StyledGridEvenContainer = styled.div`
    display: grid;
    background-color: ${props => props.theme.constellation?.colors.gray200};
    align-items: start;
    grid-template-columns: ${gridSize};
`;

const StyledGridOddContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: ${gridSize};
`;

const StyledHeader = styled.div`
    display: inline-block;
    padding: 10px;
    background-color: ${props => props.theme.constellation?.colors.gray400};
`;

const StyledRow = styled.div`
    display: inline-block;
    padding: 5px 10px;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const StyledGeneralContentGridContainer = styled.div`
    align-items: start;
    margin-top: 2px;
    font-size: 14px;
`;

const StyledTableRow = styled.div`
    display: inline-block;
    padding: 2px 2px 1px 2px;
`;

const DisplayIdentifierToRequestIds = props => {
    const getUserIdentifiersValues = values => {
        let userIdentifiers = [];
        values.forEach((obj, i) => {
            userIdentifiers.push(
                <StyledGeneralContentGridContainer key={i}>
                    <StyledTableRow>{obj}</StyledTableRow>
                </StyledGeneralContentGridContainer>
            );
        });
        return userIdentifiers;
    };

    const getContent = props => {
        let content = [];
        let count = 0;
        for (const [key, values] of Object.entries(props.data)) {
            const StyledGridContainer =
                count % 2 ? StyledGridEvenContainer : StyledGridOddContainer;
            const identifierValues = <Spacer>{getUserIdentifiersValues(values)}</Spacer>;

            content.push(
                <StyledGridContainer key={count}>
                    <StyledRow>{key}</StyledRow>
                    <StyledRow title={values}>{identifierValues}</StyledRow>
                </StyledGridContainer>
            );
            count++;
        }
        return content;
    };

    const reportContent =
        props.data.length <= 0 ? (
            <Alert appearance="warning" body="No data found." />
        ) : (
            <Spacer>
                <StyledGridContainer>
                    <StyledHeader>Key</StyledHeader>
                    <StyledHeader>Values</StyledHeader>
                </StyledGridContainer>
                <StyledReportContainer>{getContent(props)}</StyledReportContainer>
            </Spacer>
        );

    return (
        <div>
            <Heading level="4">Identifiers :</Heading>
            {reportContent}
        </div>
    );
};

DisplayIdentifierToRequestIds.propTypes = {
    data: PropTypes.any,
};

DisplayIdentifierToRequestIds.defaultProps = {
    data: [],
};

export default DisplayIdentifierToRequestIds;
