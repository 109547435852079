import React from 'react';
import styled from 'styled-components';
import correctYourData from '../images/correct-your-data.png';
import zillowLogo from '../images/zillow-logo.svg';
import truliaLogo from '../images/trulia-logo.svg';
import hotpadsLogo from '../images/hotpads-logo.svg';
import streeteasyLogo from '../images/streeteasy-logo.svg';
import { PageWrapper, PageSection, PageContent, StyledIntroFlex } from '../common/Page';
import {
    Heading,
    Paragraph,
    Spacer,
    Text,
    spaceMixin,
    mediaBreakpointMixin,
} from '@zillow/constellation';

const StyledPageContent = styled(PageContent)`
    padding: 0;
`;

const StyledParagraph = styled(Paragraph)`
    margin-top: ${spaceMixin('md')};
`;

const StyledDiv = styled.div`
    flex-wrap: wrap;

    > div {
        margin: ${spaceMixin('xl')} 0;

        > img {
            height: 40px;
            margin-bottom: ${spaceMixin('sm')};
        }

        > ul {
            list-style: outside;
            li {
                margin-top: ${spaceMixin('sm')};
                margin-left: ${spaceMixin('sm')};
            }
        }

        @media ${mediaBreakpointMixin('lg')} {
            display: flex;
            align-items: center;

            > img {
                min-width: 350px;
                margin-bottom: 0;
            }

            > ul {
                max-width: 500px;
            }
        }
    }
`;

const CorrectYourDataPage = () => (
    <PageWrapper backgroundColor="#f4f4f4">
        <StyledPageContent>
            <PageSection maxWidth={1200}>
                <StyledIntroFlex
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    flexWrap="wrap"
                    image={correctYourData}
                >
                    <div className="page-hero-image" />
                    <div>
                        <Heading level="3" serif>
                            Update your Personal Data
                        </Heading>
                    </div>
                </StyledIntroFlex>
            </PageSection>
        </StyledPageContent>

        <Spacer marginTop="md">
            <PageContent maxWidth={1200}>
                <StyledParagraph>
                    You may have to log in to access the pages below in order to update your
                    information.
                </StyledParagraph>

                <StyledDiv marginTop="sm">
                    <div>
                        <img src={zillowLogo} alt="zillow-logo" />
                        <ul>
                            <li>
                                <Text>
                                    To update your personal data (Name, email, etc.) on Zillow,
                                    please click{' '}
                                    <a
                                        href="https://www.zillow.com/myzillow/account/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        here
                                    </a>
                                    .
                                </Text>
                            </li>

                            <li>
                                <Text>
                                    Manual steps: Sign in to{' '}
                                    <a
                                        href="https://www.zillow.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        zillow.com
                                    </a>
                                    , go to Account Settings, click Edit.
                                </Text>
                            </li>

                            <li>
                                <Text>
                                    After logging in, you may additionally update “Your Home”{' '}
                                    <a
                                        href="https://www.zillow.com/myzillow/yourhome"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        here
                                    </a>
                                    , saved searches{' '}
                                    <a
                                        href="https://www.zillow.com/myzillow/savedsearches"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        here
                                    </a>
                                    , and saved homes{' '}
                                    <a
                                        href="https://www.zillow.com/myzillow/favorites"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        here
                                    </a>
                                    .
                                </Text>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <img src={truliaLogo} alt="trulia-logo" />
                        <ul>
                            <li>
                                <Text>
                                    To update your personal data (Name, email, etc.) on Trulia,
                                    please click{' '}
                                    <a
                                        href="https://www.trulia.com/account/user_profile/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        here
                                    </a>
                                    .
                                </Text>
                            </li>

                            <li>
                                <Text>
                                    Manual steps: Sign in to{' '}
                                    <a
                                        href="https://www.trulia.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        trulia.com
                                    </a>
                                    , go to Profile, click Edit.
                                </Text>
                            </li>

                            <li>
                                <Text>
                                    After logging in, you may update your notification preferences{' '}
                                    <a
                                        href="https://www.trulia.com/account/notifications/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        here
                                    </a>
                                    , saved searches{' '}
                                    <a
                                        href="https://www.trulia.com/account/searches/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        here
                                    </a>
                                    , and saved homes{' '}
                                    <a
                                        href="https://www.trulia.com/account/properties"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        here
                                    </a>
                                    .
                                </Text>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <img src={hotpadsLogo} alt="hotpads-logo" />
                        <ul>
                            <li>
                                <Text>
                                    To update your personal data (Name, email, etc.) on Hotpads,
                                    please click{' '}
                                    <a
                                        href="https://hotpads.com/user/edit"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        here
                                    </a>
                                    .
                                </Text>
                            </li>

                            <li>
                                <Text>
                                    Manual steps: Sign in to{' '}
                                    <a
                                        href="https://www.hotpads.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        hotpads.com
                                    </a>
                                    , go to My Account, click Settings.
                                </Text>
                            </li>

                            <li>
                                <Text>
                                    After logging in, you may update your saved searches{' '}
                                    <a
                                        href="https://hotpads.com/user/saved-search"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        here
                                    </a>{' '}
                                    and “My List”{' '}
                                    <a
                                        href="https://hotpads.com/user/listings/my-list"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        here
                                    </a>
                                    .
                                </Text>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <img src={streeteasyLogo} alt="streeteasy-logo" />
                        <ul>
                            <li>
                                <Text>
                                    To update your personal data (Name, email, etc.) on StreetEasy,
                                    please click{' '}
                                    <a
                                        href="https://streeteasy.com/my/account_profile"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        here
                                    </a>
                                    .
                                </Text>
                            </li>

                            <li>
                                <Text>
                                    Manual steps: Sign in to{' '}
                                    <a
                                        href="https://streeteasy.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        streeteasy.com
                                    </a>
                                    , go to Account, click Profile.
                                </Text>
                            </li>

                            <li>
                                <Text>
                                    After logging in, you may update your building{' '}
                                    <a
                                        href="https://streeteasy.com/my/settings"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        here
                                    </a>{' '}
                                    and notifications{' '}
                                    <a
                                        href="https://streeteasy.com/nyc/user/notification_preferences"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        here
                                    </a>
                                    .
                                </Text>
                            </li>
                        </ul>
                    </div>
                </StyledDiv>
                <StyledParagraph>
                    If you have any trouble accessing or updating your data, please reach out to{' '}
                    <a href="mailto: privacy@zillow.com">privacy@zillow.com</a>
                </StyledParagraph>
                <StyledParagraph>
                    If you are a current or former Zillow Group employee or job applicant, please
                    email <a href="mailto: privacy@zillow.com">privacy@zillow.com</a>
                </StyledParagraph>
            </PageContent>
        </Spacer>
    </PageWrapper>
);

export default CorrectYourDataPage;
