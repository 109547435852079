export const API_BASE_DOMAIN =
    process.env[`REACT_APP_API_DOMAIN_BASE_${process.env.REACT_APP_STAGE.toUpperCase()}`];

export const ZILLOW_OAUTH_REDIRECT_URL = `${window.location.origin}/zillowOAuth`;
export const ZILLOW_OAUTH_REDIRECT_URL_FUNC = ({ impersonatedEmail }) =>
    `${ZILLOW_OAUTH_REDIRECT_URL}?email=${encodeURIComponent(
        encodeURIComponent(impersonatedEmail)
    )}`;
export const ZILLOW_OAUTH_FUNC = ({ baseDomain, redirectUrl }) =>
    `${baseDomain}/oauth/authorize?client_id=${
        process.env[`REACT_APP_ZILLOW_OAUTH_CLIENT_ID_${process.env.REACT_APP_STAGE.toUpperCase()}`]
    }&response_type=code&redirect_uri=${redirectUrl}`;

export const TRULIA_SSO_REDIRECT_URL = `${window.location.origin}/truliaSSO`;
export const TRULIA_SSO_FUNC = ({ baseDomain, redirectUrl }) =>
    `${baseDomain}/login?redirectUrl=${redirectUrl}`;

export const HOTPADS_SSO_REDIRECT_URL = `${window.location.origin}/hotpadsSSO`;
export const HOTPADS_SSO_FUNC = ({ baseDomain, redirectUrl }) =>
    `${baseDomain}/login?redirectUrl=${redirectUrl}`;

export const STREETEASY_OAUTH_REDIRECT_URL = `${window.location.origin}/streeteasyOAuth`;
export const STREETEASY_OAUTH_FUNC = ({ baseDomain, redirectUrl }) =>
    `${baseDomain}/oauth/authorize?client_id=${
        process.env[
            `REACT_APP_STREETEASY_OAUTH_CLIENT_ID_${process.env.REACT_APP_STAGE.toUpperCase()}`
        ]
    }&response_type=code&redirect_uri=${redirectUrl}`;

export const API_CSRF = `${API_BASE_DOMAIN}/csrfToken?v=1`;
export const API_DATA_CATEGORIES = `${API_BASE_DOMAIN}/dataCategories?v=1`;

export const API_IDENTIFICATION = `${API_BASE_DOMAIN}/identification?v=1`;
export const API_IDENTIFICATION_EMAIL_ONLY = `${API_BASE_DOMAIN}/identification/emailOnly?v=1`;
export const API_IDENTIFICATION_PROVIDERS = `${API_BASE_DOMAIN}/identification/providers?v=1`;
export const API_IDENTIFICATION_LOGOUT = `${API_BASE_DOMAIN}/identification/logout?v=1`;
export const API_IDENTIFICATION_OAUTH_FUNC = ({ authorizationCode, redirectUrl }) =>
    `${API_BASE_DOMAIN}/identification/oauth?v=1&provider=ZILLOW&authorizationCode=${authorizationCode}&redirectUrl=${redirectUrl}`;
export const API_IDENTIFICATION_STREETEASY_OAUTH_FUNC = ({ authorizationCode, redirectUrl }) =>
    `${API_BASE_DOMAIN}/identification/oauth?v=1&provider=STREETEASY&authorizationCode=${authorizationCode}&redirectUrl=${redirectUrl}`;

export const API_IDENTIFICATION_ADMIN_FUNC = ({
    authorizationCode,
    redirectUrl,
    impersonatedEmail,
}) =>
    `${API_BASE_DOMAIN}/identification/admin?v=1&provider=ZILLOW&authorizationCode=${authorizationCode}&email=${encodeURIComponent(
        impersonatedEmail
    )}&redirectUrl=${redirectUrl}`;
export const API_IDENTIFICATION_SSO_FUNC = ({
    email = '',
    mac,
    provider,
    ssoData = '',
    redirectUrl,
    timestamp,
    userId = '',
}) =>
    `${API_BASE_DOMAIN}/identification/sso?v=1&email=${email}&mac=${mac}&provider=${provider}&timestamp=${timestamp}&userId=${userId}&redirectUrl=${redirectUrl}&ssoData=${ssoData}`;

export const API_VERIFICATION = `${API_BASE_DOMAIN}/verification?v=1`;
export const API_VERIFICATION_GENERATE = `${API_BASE_DOMAIN}/verification/generate`;
export const API_VERIFICATION_VALIDATE = `${API_BASE_DOMAIN}/verification/validate`;
export const API_VERIFICATION_REMOVE = `${API_BASE_DOMAIN}/verification/remove`;

export const API_REQUESTS = `${API_BASE_DOMAIN}/requests?v=1`;
export const API_REQUESTS_EXPORT_FUNC = ({ dataCategories }) =>
    `${API_BASE_DOMAIN}/requests?v=1&type=export${dataCategories
        .map(dc => '&dataCategory=' + dc)
        .join('')}`;
export const API_REQUESTS_DELETE = `${API_BASE_DOMAIN}/requests?v=1&type=delete`;
export const API_REQUESTS_DOWNLOAD_FUNC = ({ requestId }) =>
    `${API_BASE_DOMAIN}/requests/download?v=1&request_id=${requestId}`;
export const API_REQUESTS_OPT_OUT_OF_SALE = `${API_BASE_DOMAIN}/requests?v=1&type=OPT_OUT_OF_SALE`;

// ADMIN
export const API_ADMIN = `${API_BASE_DOMAIN}/admin`;
export const API_ADMIN_REQUESTS = `${API_ADMIN}/requests`;
export const API_ADMIN_REQUEST_AGENTS_FUNC = (requestId, requestIdType) =>
    `${API_ADMIN}/request-agents?requestId=${requestId}&requestIdType=${requestIdType}`;
export const API_ADMIN_USER_IDENTIFIERS = (userId, userIdType) =>
    `${API_ADMIN}/get-user-identifiers?v=1&userId=${userId}&userIdType=${userIdType}`;
export const API_ADMIN_AGENT_DETAILS_FUNC = agentName =>
    `${API_ADMIN}/agent?agentName=${agentName}`;
export const API_ADMIN_AGENT_REQUESTS_FUNC = (agentName, statusTypes) =>
    `${API_ADMIN}/agent-requests?agentName=${agentName}${statusTypes
        .map(st => '&statusType=' + st)
        .join('')}`;
export const API_ADMIN_AGENTS_DETAILS_FUNC = `${API_ADMIN}/agents-details`;
export const API_ADMIN_JIRA_TICKET_CREATED_PER_DAY_BY_AGENTS = `${API_ADMIN}/jira-ticket-created-per-day`;
export const API_ADMIN_REQUESTS_IN_JIRA_TICKET_CREATED_STATE = `${API_ADMIN}/jira-ticket-created-requests-details`;
export const API_ADMIN_REQUESTS_PER_DAY = `${API_ADMIN}/requests-per-day`;
export const API_ADMIN_AGENTS_OVERDUE = agentName =>
    `${API_ADMIN}/overdue-requests?agentName=${agentName}`;
export const API_ADMIN_REQUESTS_OVERDUE = `${API_ADMIN}/get-overdue-requests`;
export const API_ADMIN_OVERALL_AGENT_STATUS_COUNTS = noOfHours =>
    `${API_ADMIN}/overall-agent-status-count?v=1&no_of_hours=${noOfHours}`;
export const API_ADMIN_AGENTS_IN_STATUS_TYPE = statusTypes =>
    `${API_ADMIN}/agents-in-status-type?v=1${statusTypes.map(st => '&statusType=' + st).join('')}`;
export const API_ADMIN_DEACTIVATE_REQUEST_DATA_AGENT = (requestId, agentId, messageType) =>
    `${API_ADMIN}/deactivate-request-data-agent?v=1&agentId=${agentId}&userRequestId=${requestId}&requestAgentMessageType=${messageType}`;
export const API_ADMIN_UPDATE_DATA_AGENT_STATUS = (agentName, jiraTicketNumber, operationType) =>
    `${API_ADMIN}/update-data-agent-status?v=1&agentName=${agentName}&jiraTicketNumber=${jiraTicketNumber}&operationType=${operationType}`;
export const API_IS_USER_HAVE_FULL_ADMIN_ACCESS = agentZodiacServiceName =>
    `${API_ADMIN}/is-user-have-full-admin-portal-access?agentZodiacServiceName=${agentZodiacServiceName}`;
export const API_IS_USER_BELONGS_TO_AUTHORIZED_TEAM = `${API_ADMIN}/is-user-belongs-to-authorized-team`;
export const API_ADMIN_UPDATE_AGENT_REQUEST_STATUS = (
    userRequestId,
    agentId,
    currentRequestStatus,
    newRequestStatus
) =>
    `${API_ADMIN}/update-agent-request-status?v=1&userRequestId=${userRequestId}&agentId=${agentId}&currentRequestStatus=${currentRequestStatus}&newRequestStatus=${newRequestStatus}`;

// Audit
export const API_AUDIT_ERROR_FUNC = (description, inputReferer) =>
    `${API_BASE_DOMAIN}/advertisingcookies/optout/error?v=1&description=${description}&inputReferer=${inputReferer}`;
export const API_AUDIT_SUCCEEDED_FUNC = inputReferer =>
    `${API_BASE_DOMAIN}/advertisingcookies/optout/succeeded?v=1&inputReferer=${inputReferer}`;
export const API_AUDIT_INITIATED_FUNC = inputReferer =>
    `${API_BASE_DOMAIN}/advertisingcookies/optout/initiated?v=1&inputReferer=${inputReferer}`;

// Replay Requests
export const API_REPLAY_REQUESTS = (replayAgentId, userRequestIdsWithRequestType) =>
    `${API_ADMIN}/replay-requests/${replayAgentId}?${Object.keys(userRequestIdsWithRequestType)
        .map(
            k => `${encodeURIComponent(k)}=${encodeURIComponent(userRequestIdsWithRequestType[k])}`
        )
        .join('&')}`;
