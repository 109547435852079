// Kudos to zrs-zillow-offers
import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

const loader =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAMAAAAMCGV4AAAAilBMVEUAAAAAdOQAdOQAdOQAc+QAc+QAc+QAdOQAc+QAdOQAdOQAc+QAc+QAdOQAc+QAdOQAdOQAdOQAc+QAdOQAdOQAdOQAc+QAdOQAdOQAdOQAdOQAc+QAc+MAc+MAdOQAdOQAc+QAc+QAc+QAc+QAc+QAdOQAdOQAc+MAdOQAc+MAdOQAc+MAc+QAc+OGJIrmAAAALnRSTlMAAiQGtArCdGNYSEUqGhYE7eKbjW1fUT80Ew7689GYkoh8OB7b19PLu62po2gyr2El+QAAAJhJREFUCNdFjlUWwzAMBGWGuHGYOWW4//WauHW7fyO90Qq2EEXjLkcIPsE86to2QYQgh+co6tOstoQ124DQOH5apzV1A1CcjvIrsvDAIOmpPwSHNQR+ycAnxBg4V382BuhV/nh9GZjo4H1ULRiC2137x8qyBps+kgDtW1Poai8Zh1HopVSqCJhrndNpFpnMN3SxVS6E1Hi33szECHUpgs6EAAAAAElFTkSuQmCC';

const loaderLarge =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAtCAMAAAANxBKoAAAAk1BMVEUAAAAAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOQAdOTLrAsxAAAAMXRSTlMAAg0EFTU+GgjlwXIi/pxl3ZXr1416UTAnHhHRoyv69fC2hsewqYFsX0tDzFpIVTm8oi7UiAAAAo5JREFUSMeF1dmSqkAMgOFudhDZRFZF0BFlU97/6U6SRktmhPN7RfEZokUB+4hzxozn5nAxy6JrGIfPUmTjuiu3ohNwbBGrTrAtTqdTURTbYmvBbC7L/CtmWW1uy7IUGoaXGWrsC1atIghIT7M9gxE2DIP/xpFXmqCRY+BrJnOBJYnP8T0IAIvhxRZzDM65wJA8m2xCxE/bU37Y31yVEZYRzzjs7Jme4IUXahITgX/hLMvkF1ZG0xO69O2JiU2BT1iR+KTrYOcB94Jdg5J9JgutKIox7bEDDAVdzGjmnKOlxCnHJG7mR4bL/eEC6zr+HhgNoR8Af8sgDOHwvZmjNuvFWy7TqRSGS9Uuz3f5zkoXtSxwqjCm5ZS3AbxUloJN7VRmm7xD3R1XtAQUk5ifd9DuIK9oDhhTWNWR3gNeTkebJDq7UHm/qpUEO9pCW92wro8JxSzqov1HU2/dru8dT3pEPF6iVZ2qMZawqzVClxtbKyGt2iy0qnGsrHBttByrVMpcq8J+7MVVOMueQivsSfZnvK8sftSeT/yCwSQHLOQowBdGaxpy7QhHtxHxtXKXhvO41dBrOl6GMKR+5ZzZQ9u2yA082ldoD1c/Rf4H6wNo4K1Nh0fCUAiczy3gpmnIawaj3OqAOYcwRvBpeRJFgg+vK2fhFTHk9woqis5o/SNCPzTxe7XEERY6R7bBREaq9fcHaOStBG7i2kuHoX++RRrcFs+hd9173ws+ZIjfHCzh8AzV9X6/2dzc+13wR6MQfnPVdwCTBor25rrIQfczTNzeO77Ak0ZOw3vxRJ9zKQp9wJN+D3cfyfeHR3o/g59pt1elxXexPmxqmI4avRvFYBdfx/QnP1ySjzZW+K93xT82/1FMJcGOgwAAAABJRU5ErkJggg==';

const spin = keyframes`
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
`;

export const LoadingSpinnerStyle = styled.div`
    position: relative;
    display: inline-block;
    min-height: ${props => (props.isLarge ? '45px' : '15px')};
    min-width: ${props => (props.isLarge ? '45px' : '15px')};

    &:before {
        content: '';
        position: absolute;
        width: ${props => (props.isLarge ? '45px' : '15px')};
        height: ${props => (props.isLarge ? '45px' : '15px')};
        margin: ${props => (props.isLarge ? '-22.5px' : '-7.5px')};
        background-image: url(${props => (props.isLarge ? loaderLarge : loader)});
        animation: ${spin} 0.5s infinite linear;
        top: 50%;
        left: 50%;
        background-repeat: no-repeat;
        background-size: 100.5% 100.5%;
    }
`;

/**
 * A drop-in replacement for react-ZSG's [LoadingSpinner](http://www.zillowstatic.com/static-zsg/LATEST/styleguide/components/Loaders.html).
 *
 * Changes from ZSG:
 * * The `skin` property is no longer supported since it didn't really do anything anyway.
 * * The `large` property is renamed to `isLarge` to provide clarity that this is a boolean property
 */
const LoadingSpinner = ({ isLarge, ...props }) => (
    <LoadingSpinnerStyle isLarge={isLarge} {...props} />
);

LoadingSpinner.propTypes = {
    /** Indicates that this is to be a large loading spinner */
    isLarge: PropTypes.bool,
};

LoadingSpinner.defaultProps = {
    isLarge: false,
};

export default LoadingSpinner;
