import React from 'react';
import styled from 'styled-components';

import {
    REQ_REC_PER_DAY_BY_TYPE,
    AGENTS_IN_ERROR_STATE,
    JIRA_TICKETS_CREATED_PER_DAY,
    AGENTS_IN_REQUEST_SENT_STATE,
    AGENTS_OVERDUE,
    REQUESTS_OVERDUE,
    OVERALL_AGENT_STATUS_COUNTS,
    REQ_JIRA_TICKETS_CREATED_STATE,
} from '../../constants/ReportsConstants';

const StyledReportsUl = styled.ul`
    li {
        cursor: pointer;
    }
`;

const Reports = ({ onClick, onSubmitData }) => {
    return (
        <StyledReportsUl>
            <li
                name="req-in-jira-ticket-created-state"
                onClick={() => onClick(REQ_JIRA_TICKETS_CREATED_STATE)}
            >
                Requests in <strong>JIRA_TICKET_CREATED</strong> state
            </li>
            <li name="req-rec-per-day-by-type" onClick={() => onClick(REQ_REC_PER_DAY_BY_TYPE)}>
                Requests of each type received per day
            </li>
            <li name="agents-in-error-state" onClick={() => onClick(AGENTS_IN_ERROR_STATE)}>
                Agents in <strong>ERROR</strong> state
            </li>
            <li
                name="jira-tickets-created-per-day"
                onClick={() => onClick(JIRA_TICKETS_CREATED_PER_DAY)}
            >
                <strong>JIRA_TICKET_CREATED</strong> per day
            </li>
            <li
                name="agents-in-request-sent-state"
                onClick={() => onClick(AGENTS_IN_REQUEST_SENT_STATE)}
            >
                Agents in <strong>REQUEST_SENT</strong> state
            </li>
            <li name="overdeu-request-agents" onClick={() => onClick(AGENTS_OVERDUE)}>
                <strong>AGENTS</strong> Overdue
            </li>
            <li name="overdeu-request-" onClick={() => onClick(REQUESTS_OVERDUE)}>
                <strong>REQUESTS</strong> Overdue
            </li>
            <li
                name="overall-agent-status-counts"
                onClick={() => onClick(OVERALL_AGENT_STATUS_COUNTS, onSubmitData)}
            >
                Overall Agent Status Counts
            </li>
        </StyledReportsUl>
    );
};

export default Reports;
