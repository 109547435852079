import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledDiv = styled.div`
    display: inline-block;
    > span {
        display: inline-block;
        height: ${props => props.size};
        width: ${props => props.size};
        border-top: ${props => props.borderWidth} solid ${props => props.color};
        border-left: ${props => props.borderWidth} solid ${props => props.color};
        transform: rotate(
            ${props => {
                if (props.direction === 'up') {
                    return '45deg';
                } else if (props.direction === 'right') {
                    return '135deg';
                } else if (props.direction === 'down') {
                    return '225deg';
                } else {
                    return '315deg';
                }
            }}
        );
    }
`;

// Need a wrapper to apply spacings
const BigArrow = props => {
    return (
        <StyledDiv {...props}>
            <span />
        </StyledDiv>
    );
};

BigArrow.propTypes = {
    borderWidth: PropTypes.string,
    color: PropTypes.string,
    direction: PropTypes.oneOf(['up', 'right', 'down', 'left']),
    size: PropTypes.string,
};

BigArrow.defaultProps = {
    borderWidth: '2px',
    color: '#1872FA',
    size: '11px',
    direction: 'up',
};

export default BigArrow;
