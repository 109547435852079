import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import zillowLogo from '../../images/zillow-logo.svg';
import truliaLogo from '../../images/trulia-logo.svg';
import hotpadsLogo from '../../images/hotpads-logo.svg';
import streeteasyLogo from '../../images/streeteasy-logo.svg';
import SeparatorLine from '../../common/SeparatorLine';
import { Button, Heading, Paragraph, spaceMixin } from '@zillow/constellation';
import {
    ZILLOW_OAUTH_FUNC,
    ZILLOW_OAUTH_REDIRECT_URL,
    HOTPADS_SSO_FUNC,
    HOTPADS_SSO_REDIRECT_URL,
    TRULIA_SSO_FUNC,
    TRULIA_SSO_REDIRECT_URL,
    STREETEASY_OAUTH_FUNC,
    STREETEASY_OAUTH_REDIRECT_URL,
} from '../../constants/ApiConstants';

export const DISPLAY_NAME = 'SIGNIN_LANDING';

const StyledUl = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > li {
        width: 200px;
        height: 46px;
        border: 1px solid ${props => props.theme.constellation?.colors.borderDark};
        border-radius: 3px;
        margin-top: ${spaceMixin('sm')};

        &:hover {
            border-color: ${props => props.theme.constellation?.colors.blue400};
        }

        > a {
            display: flex;
            height: 100%;
            width: 100%;
            justify-content: center;
            align-items: center;
        }

        img {
            width: 50%;
        }
    }
`;

const StyledButton = styled(Button)`
    width: 200px;
    font-weight: 600;
    font-size: 14px;
`;

const SigninLanding = ({ goAdmin, goNext, identificationProviders, closeSigninModal }) => {
    const {
        zillow: zillowDomain,
        trulia: truliaDomain,
        hotpads: hotpadsDomain,
        streeteasy: streetEasyDomain,
    } = identificationProviders;

    const [showAdmin, setShowAdmin] = useState(false);

    let typedSecretKeys = [];

    // up, up, down, down, left, right, left, right
    const secretKeysString = [38, 38, 40, 40, 37, 39, 37, 39].join('');

    const handleKeyDown = event => {
        typedSecretKeys.push(event.keyCode);

        if (typedSecretKeys.length === 9) {
            typedSecretKeys.shift();
        }

        if (typedSecretKeys.join('') === secretKeysString) {
            document.removeEventListener('keydown', handleKeyDown, false);
            setShowAdmin(true);
        }
    };

    useEffect(() => {
        if (!showAdmin) {
            document.addEventListener('keydown', handleKeyDown, false);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown, false);
        };
    });

    return (
        <>
            <Heading level="3" serif marginTop="sm">
                Sign in to use our privacy tools
            </Heading>
            <Paragraph marginTop="lg" marginBottom="xs">
                To get started, choose a brand you use or select{' '}
                <strong>I don't have an account</strong>.
            </Paragraph>
            <StyledUl>
                {zillowDomain && (
                    <li>
                        <a
                            href={ZILLOW_OAUTH_FUNC({
                                baseDomain: zillowDomain,
                                redirectUrl: ZILLOW_OAUTH_REDIRECT_URL,
                            })}
                            onClick={closeSigninModal}
                        >
                            <img src={zillowLogo} alt="zillow-logo-sign-in" />
                        </a>
                    </li>
                )}
                {truliaDomain && (
                    <li>
                        <a
                            href={TRULIA_SSO_FUNC({
                                baseDomain: truliaDomain,
                                redirectUrl: TRULIA_SSO_REDIRECT_URL,
                            })}
                            onClick={closeSigninModal}
                        >
                            <img src={truliaLogo} alt="trulia-logo-sign-in" />
                        </a>
                    </li>
                )}
                {hotpadsDomain && (
                    <li>
                        <a
                            href={HOTPADS_SSO_FUNC({
                                baseDomain: hotpadsDomain,
                                redirectUrl: HOTPADS_SSO_REDIRECT_URL,
                            })}
                            onClick={closeSigninModal}
                        >
                            <img src={hotpadsLogo} alt="hotpads-logo-sign-in" />
                        </a>
                    </li>
                )}
                {streetEasyDomain && (
                    <li>
                        <a
                            href={STREETEASY_OAUTH_FUNC({
                                baseDomain: streetEasyDomain,
                                redirectUrl: STREETEASY_OAUTH_REDIRECT_URL,
                            })}
                            onClick={closeSigninModal}
                        >
                            <img src={streeteasyLogo} alt="streeteasy-logo-sign-in" />
                        </a>
                    </li>
                )}
            </StyledUl>
            {showAdmin && (
                <StyledButton onClick={goAdmin} marginTop="sm">
                    CSR Admin
                </StyledButton>
            )}
            <SeparatorLine width={16} marginTop="lg" marginBottom="lg" />
            <StyledButton onClick={goNext} marginBottom="sm">
                I don't have an account
            </StyledButton>
        </>
    );
};

SigninLanding.displayName = DISPLAY_NAME;

SigninLanding.propTypes = {
    goAdmin: PropTypes.func,
    goNext: PropTypes.func,
    identificationProviders: PropTypes.object,
};

export default SigninLanding;
