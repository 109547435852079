export const REQ_REC_PER_DAY_BY_TYPE = 'requestsReceivedPerDayByType';
export const AGENTS_IN_ERROR_STATE = 'agentsInErrorState';
export const JIRA_TICKETS_CREATED_PER_DAY = 'jiraTicketsCreatedPerDay';
export const AGENTS_IN_REQUEST_SENT_STATE = 'agentsInRequestSentState';
export const AGENTS_OVERDUE = 'agentsOverdue';
export const REQUESTS_OVERDUE = 'requestsOverdue';
export const OVERALL_AGENT_STATUS_COUNTS = 'OverallAgentStatusCounts';
export const LOOKBACK_HOURS_DEFAULT = 24;
export const LOOKBACK_HOURS_MIN = 1;
export const LOOKBACK_HOURS_MAX = 168; // 1 week = (24*7)
export const GET_AGENT_REQUEST_STATUS = 'getAgentRequestStatus';
export const REQ_JIRA_TICKETS_CREATED_STATE = 'requestsInJiraTicketCreatedState';
