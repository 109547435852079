import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import AgentRequestStatusForm from '../components/admin/AgentRequestStatusForm';
import BigArrow from '../common/BigArrow';
import JSONPretty from 'react-json-pretty';
import API from '../utils/api';
import SigninContext from '../contexts/SigninContext';
import LoadingSpinner from '../common/LoadingSpinner';
import ReportsPage from './ReportsPage';
import AgentDetailsPage from '../components/admin/AgentDetailsPage';
import UserIdentifiersPage from '../components/admin/UserIdentifiersPage';
import DisplayAgentRequestStatus from '../components/admin/DisplayAgentRequestStatus';
import DisplayIdentifierToRequestIds from '../components/admin/DisplayIdentifierToRequestIds';
import { Alert, Collapsible, Spacer, spaceMixin } from '@zillow/constellation';
import { PageWrapper, PageContent, StyledExpandTextButton } from '../common/Page';
import {
    API_ADMIN_REQUEST_AGENTS_FUNC,
    API_ADMIN_REQUESTS,
    API_ADMIN_AGENT_DETAILS_FUNC,
    API_ADMIN_AGENTS_DETAILS_FUNC,
    API_ADMIN_JIRA_TICKET_CREATED_PER_DAY_BY_AGENTS,
    API_ADMIN_REQUESTS_PER_DAY,
    API_ADMIN_AGENTS_IN_STATUS_TYPE,
    API_ADMIN_AGENTS_OVERDUE,
    API_ADMIN_REQUESTS_OVERDUE,
    API_ADMIN_OVERALL_AGENT_STATUS_COUNTS,
    API_ADMIN_USER_IDENTIFIERS,
    API_ADMIN_REQUESTS_IN_JIRA_TICKET_CREATED_STATE,
    API_IS_USER_BELONGS_TO_AUTHORIZED_TEAM,
} from '../constants/ApiConstants';
import { LOOKBACK_HOURS_DEFAULT } from '../constants/ReportsConstants';
import IdentifierToRequestIdForm from '../components/admin/IdentifierToRequestIdForm';
import AgentDetailsForm from '../components/admin/AgentDetailsForm';
import AgentsDetailsPage from '../components/admin/AgentsDetailsPage';
import UserIdentifiersForm from '../components/admin/UserIdentifiersForm';
import AgentRequestStatusUpdate from '../components/admin/AgentRequestStatusUpdate';
import Reports from '../components/Reports/Reports';
import { parseErrorResponse } from '../utils';
import {
    REQ_REC_PER_DAY_BY_TYPE,
    AGENTS_IN_ERROR_STATE,
    JIRA_TICKETS_CREATED_PER_DAY,
    AGENTS_IN_REQUEST_SENT_STATE,
    AGENTS_OVERDUE,
    REQUESTS_OVERDUE,
    OVERALL_AGENT_STATUS_COUNTS,
    REQ_JIRA_TICKETS_CREATED_STATE,
} from '../constants/ReportsConstants';

const StyledJSONPretty = styled(JSONPretty)`
    pre {
        font-size: 14px;
        white-space: pre-wrap;
    }
`;

const StyledSpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
`;

const StyledGridContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: 33.2% 66.8%;

    > section {
        margin: ${spaceMixin('xs')};
        border: 1px solid ${props => props.theme.constellation?.colors.borderLight};

        &:last-child {
            min-height: 300px;
            padding: ${spaceMixin('sm')};
        }
    }

    .form-container {
        padding: ${spaceMixin('sm')};
        background-color: ${props => props.theme.constellation?.colors.gray100};
        border-radius: 5px;
    }
`;

const StyledUl = styled.ul`
    li {
        border-bottom: 1px solid ${props => props.theme.constellation?.colors.borderLight};
        padding: ${spaceMixin('sm')};

        &:last-child {
            border-bottom: 0 none;
        }
    }
`;

const FurtherStyledExpandTextButton = styled(StyledExpandTextButton)`
    font-size: 18px;
    font-weight: 600;
    color: ${props => props.theme.constellation?.colors.textDark};
`;

const CollapsibleListItem = ({ children, title }) => {
    return (
        <Collapsible defaultCollapsed>
            {(collapsed, toggle) => (
                <>
                    <FurtherStyledExpandTextButton fontType="bodySmall" onClick={toggle}>
                        {title}
                        {collapsed ? (
                            <>
                                <BigArrow className="down-arrow" direction="down" />
                            </>
                        ) : (
                            <>
                                <BigArrow className="up-arrow" direction="up" />
                            </>
                        )}
                    </FurtherStyledExpandTextButton>
                    {collapsed ? null : (
                        <Spacer className="form-container" marginTop="sm">
                            {children}
                        </Spacer>
                    )}
                </>
            )}
        </Collapsible>
    );
};

const AdminPage = () => {
    const {
        state: { csrfToken },
    } = useContext(SigninContext);

    const [result, setResult] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isReport, setIsReport] = useState(false);
    const [reportComponent, setReportComponent] = useState(null);
    const [isContent, SetIsConent] = useState(true);

    const handleReportLink = (reportId, submitFormData) => {
        setError('');
        setIsLoading(true);
        SetIsConent(true);

        if (reportId != null) {
            switch (reportId) {
                case REQ_JIRA_TICKETS_CREATED_STATE:
                    getReportData(API_ADMIN_REQUESTS_IN_JIRA_TICKET_CREATED_STATE, reportId);
                    break;
                case JIRA_TICKETS_CREATED_PER_DAY:
                    getReportData(API_ADMIN_JIRA_TICKET_CREATED_PER_DAY_BY_AGENTS, reportId);
                    break;
                case REQ_REC_PER_DAY_BY_TYPE:
                    getReportData(API_ADMIN_REQUESTS_PER_DAY, reportId);
                    break;
                case AGENTS_IN_ERROR_STATE:
                    getReportData(
                        API_ADMIN_AGENTS_IN_STATUS_TYPE([
                            'JIRA_TICKET_CREATED',
                            'ERROR',
                            'RESPONSE_ERROR',
                        ]),
                        reportId
                    );
                    break;
                case AGENTS_IN_REQUEST_SENT_STATE:
                    getReportData(
                        API_ADMIN_AGENTS_IN_STATUS_TYPE(['REQUEST_SENT', 'RETRY_SENT']),
                        reportId
                    );
                    break;
                case AGENTS_OVERDUE:
                    getReportData(API_ADMIN_AGENTS_OVERDUE('all'), reportId);
                    break;
                case REQUESTS_OVERDUE:
                    getReportData(API_ADMIN_REQUESTS_OVERDUE, reportId);
                    break;
                case OVERALL_AGENT_STATUS_COUNTS:
                    getReportData(
                        API_ADMIN_OVERALL_AGENT_STATUS_COUNTS(LOOKBACK_HOURS_DEFAULT),
                        reportId,
                        submitFormData
                    );
                    break;
                default:
                    console.log('unable to find such report.');
            }
        }
    };

    const getReportData = (report_url, reportId, submitFormData) => {
        setError('');
        setIsReport(false);
        setIsLoading(true);
        SetIsConent(true);
        API({
            url: report_url,
            headers: {
                'X-Csrf-Token': csrfToken,
            },
        })
            .then(res => {
                if (res) {
                    setIsLoading(false);
                    setIsReport(true);
                    SetIsConent(true);
                    if (!res.data || res.data === '') {
                        setResult('');
                        res.data = [];
                    }
                    setReportComponent(
                        <ReportsPage
                            data={res.data}
                            reportId={reportId}
                            submitFormData={submitFormData}
                            isResponse={true}
                        />
                    );
                }
            })
            .catch(error => {
                setIsLoading(false);
                SetIsConent(false);
                const errorMessage = parseErrorResponse(error);
                setResult('');
                setError(`ERROR: ${errorMessage}`);
            });
    };

    const onSubmitAgentRequestStatusForm = ({ requestId, requestIdType }) => {
        setError('');
        setIsReport(false);
        setIsLoading(true);
        SetIsConent(true);
        API({
            url: API_ADMIN_REQUEST_AGENTS_FUNC(requestId, requestIdType),
            headers: {
                'X-Csrf-Token': csrfToken,
            },
        })
            .then(res => {
                if (res) {
                    setIsLoading(false);
                    setIsReport(true);
                    if (res.data) {
                        if (!res.data || res.data === '') {
                            setResult('');
                            res.data = [];
                        }
                        setReportComponent(<DisplayAgentRequestStatus data={res.data} />);
                        SetIsConent(true);
                    } else {
                        SetIsConent(false);
                        setResult('');
                        setError(`Error: Can't find given Id ${requestId}.`);
                    }
                }
            })
            .catch(error => {
                setIsLoading(false);
                SetIsConent(false);
                const errorMessage = parseErrorResponse(error);
                setResult('');
                setError(`ERROR: ${errorMessage}`);
            });
    };

    const onSubmitUserIdentifiersForm = ({ userId, userIdType }) => {
        setError('');
        setIsReport(false);
        setIsLoading(true);
        SetIsConent(true);
        API({
            url: API_ADMIN_USER_IDENTIFIERS(userId, userIdType),
            headers: {
                'X-Csrf-Token': csrfToken,
            },
        })
            .then(res => {
                if (res) {
                    setIsLoading(false);
                    setIsReport(true);
                    if (!res.data || res.data === '') {
                        setResult('');
                        res.data = [];
                        SetIsConent(false);
                    }
                    SetIsConent(true);
                    setReportComponent(<UserIdentifiersPage data={res.data} />);
                }
            })
            .catch(error => {
                setIsLoading(false);
                SetIsConent(false);
                const errorMessage = parseErrorResponse(error);
                setResult('');
                setError(`ERROR: ${errorMessage}`);
            });
    };

    const onSubmitAgentDetailsForm = async agentName => {
        setError('');
        setIsReport(false);
        setIsLoading(true);
        SetIsConent(true);
        const isUserBelongsToAuthorizedTeam = await IsUserBelongsToAuthorizedTeam();
        API({
            url: API_ADMIN_AGENT_DETAILS_FUNC(agentName.trim()),
            headers: {
                'X-Csrf-Token': csrfToken,
            },
        })
            .then(res => {
                if (res) {
                    setIsLoading(false);
                    setIsReport(true);
                    if (res.data) {
                        if (
                            res.data.agentDeleteDataDomains !== undefined &&
                            res.data.agentDeleteDataDomainsForDisplay !== undefined
                        ) {
                            res.data.agentDeleteDataDomains =
                                res.data.agentDeleteDataDomainsForDisplay;
                            delete res.data.agentDeleteDataDomainsForDisplay;
                        }
                        SetIsConent(true);
                        setReportComponent(
                            <AgentDetailsPage
                                data={res.data}
                                isUserBelongsToAuthorizedTeam={isUserBelongsToAuthorizedTeam}
                            />
                        );
                    } else {
                        setResult('');
                        SetIsConent(false);
                        setError(`Error: Can't find ${agentName}.`);
                    }
                }
            })
            .catch(error => {
                setIsLoading(false);
                SetIsConent(false);
                const errorMessage = parseErrorResponse(error);
                setResult('');
                setError(`ERROR: ${errorMessage}`);
            });
    };

    const IsUserBelongsToAuthorizedTeam = async () => {
        try {
            const res = await API({
                url: API_IS_USER_BELONGS_TO_AUTHORIZED_TEAM,
                headers: {
                    'X-Csrf-Token': csrfToken,
                },
                method: 'GET',
            });

            if (res) {
                return res.data;
            }
        } catch (error) {
            return false;
        }
    };

    const onSubmitIdentifierToRequestIdForm = ({
        zuids,
        emails,
        tuids,
        beth_ids,
        hotpads_user_tokens,
        encoded_zuids,
        street_easy_user_ids,
    }) => {
        setError('');
        setIsReport(false);
        setIsLoading(true);
        SetIsConent(true);
        API({
            url: API_ADMIN_REQUESTS,
            params: {
                zuids,
                emails,
                tuids,
                beth_ids,
                hotpads_user_tokens,
                encoded_zuids,
                street_easy_user_ids,
            },
            headers: {
                'X-Csrf-Token': csrfToken,
            },
        })
            .then(res => {
                if (res && res.data) {
                    setIsLoading(false);
                    setIsReport(true);
                    if (!res.data || res.data === '') {
                        setResult('');
                        res.data = [];
                        SetIsConent(false);
                    }
                    SetIsConent(true);
                    setReportComponent(<DisplayIdentifierToRequestIds data={res.data.requests} />);
                }
            })
            .catch(error => {
                setIsLoading(false);
                SetIsConent(false);
                const errorMessage = parseErrorResponse(error);
                setResult('');
                setError(`ERROR: ${errorMessage}`);
            });
    };

    const onSubmitLookBackHoursForm = lookbackHours => {
        if (lookbackHours) {
            getReportData(
                API_ADMIN_OVERALL_AGENT_STATUS_COUNTS(lookbackHours),
                OVERALL_AGENT_STATUS_COUNTS,
                onSubmitLookBackHoursForm
            );
        }
    };

    const onSubmitAgentsDetailsForm = () => {
        setError('');
        setIsReport(false);
        setIsLoading(true);
        SetIsConent(true);

        API({
            url: API_ADMIN_AGENTS_DETAILS_FUNC,
            headers: {
                'X-Csrf-Token': csrfToken,
            },
        })
            .then(res => {
                if (res) {
                    setIsLoading(false);
                    setIsReport(true);
                    if (res.data) {
                        SetIsConent(true);
                        setReportComponent(<AgentsDetailsPage data={res.data} />);
                    } else {
                        SetIsConent(false);
                        setResult('');
                    }
                }
            })
            .catch(error => {
                setIsLoading(false);
                SetIsConent(false);
                const errorMessage = parseErrorResponse(error);
                setResult('');
                setError(`ERROR: ${errorMessage}`);
            });
    };

    const onSubmitUpdateAgentRequestStausForm = () => {
        setIsReport(true);
        setError('');
        setIsLoading(true);
        SetIsConent(true);

        API({
            url: API_IS_USER_BELONGS_TO_AUTHORIZED_TEAM,
            headers: {
                'X-Csrf-Token': csrfToken,
            },
            method: 'GET',
        })
            .then(res => {
                if (res.data) {
                    setIsLoading(false);
                    setIsReport(true);
                    SetIsConent(true);
                    setReportComponent(<AgentRequestStatusUpdate />);
                } else {
                    setIsLoading(false);
                    SetIsConent(false);
                    setError(
                        `ERROR: You are not authorized to perform this action. Only data-governance/data-platform team have the access. Please reach out the teams.`
                    );
                }
            })
            .catch(error => {
                setIsLoading(false);
                SetIsConent(false);
                const errorMessage = parseErrorResponse(error);
                setResult('');
                setError(`ERROR: ${errorMessage}`);
            });
    };

    const content = isLoading ? (
        <StyledSpinnerWrapper>
            <LoadingSpinner isLarge />
        </StyledSpinnerWrapper>
    ) : isReport ? (
        reportComponent
    ) : (
        <StyledJSONPretty data={result} space="4" />
    );
    return (
        <PageWrapper backgroundColor="#f4f4f4">
            <PageContent maxWidth={1400}>
                <StyledGridContainer>
                    <section>
                        <StyledUl>
                            <li>
                                <CollapsibleListItem title="Get agent request status">
                                    <AgentRequestStatusForm
                                        onSubmit={onSubmitAgentRequestStatusForm}
                                    />
                                </CollapsibleListItem>
                            </li>
                            <li>
                                <CollapsibleListItem title="Get user identifiers">
                                    <UserIdentifiersForm onSubmit={onSubmitUserIdentifiersForm} />
                                </CollapsibleListItem>
                            </li>
                            <li>
                                <CollapsibleListItem title="Get agent details">
                                    <AgentDetailsForm onSubmit={onSubmitAgentDetailsForm} />
                                </CollapsibleListItem>
                            </li>
                            <li>
                                {' '}
                                <FurtherStyledExpandTextButton
                                    fontType="bodySmall"
                                    onClick={onSubmitAgentsDetailsForm}
                                >
                                    Get All Agents details
                                </FurtherStyledExpandTextButton>
                            </li>
                            <li>
                                <CollapsibleListItem title="Get request Ids from user identifiers">
                                    <IdentifierToRequestIdForm
                                        onSubmit={onSubmitIdentifierToRequestIdForm}
                                    />
                                </CollapsibleListItem>
                            </li>
                            <li>
                                {' '}
                                <FurtherStyledExpandTextButton
                                    fontType="bodySmall"
                                    onClick={onSubmitUpdateAgentRequestStausForm}
                                >
                                    Update Agent Request Status
                                </FurtherStyledExpandTextButton>
                            </li>
                            <li>
                                <CollapsibleListItem title="CPS Monitoring, Reporting">
                                    <Reports
                                        onClick={handleReportLink}
                                        onSubmitData={onSubmitLookBackHoursForm}
                                    />
                                </CollapsibleListItem>
                            </li>
                        </StyledUl>
                    </section>
                    <section>
                        {isContent ? content : error && <Alert appearance="error" body={error} />}
                    </section>
                </StyledGridContainer>
            </PageContent>
        </PageWrapper>
    );
};

export default AdminPage;
