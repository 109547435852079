export const REQUEST_TYPE = {
    DOWNLOAD: 'DOWNLOAD',
    DELETE: 'DELETE',
    EXPORT: 'EXPORT',
    REQUESTS: 'REQUESTS', // fetch requests status
    OPT_OUT_OF_SALE: 'OPT_OUT_OF_SALE',
};

export const REQUEST_STATUS = {
    REQUEST_PENDING: 'REQUEST_PENDING',
    REQUEST_SENT: 'REQUEST_SENT',
    READY_TO_EXPORT: 'READY_TO_EXPORT',
    FINISHED: 'FINISHED',
    REJECTED: 'REJECTED',
    PENDING_MANUAL_APPROVAL: 'PENDING_MANUAL_APPROVAL',
    APPROVE_DELETE_FINISHED: 'APPROVE_DELETE_FINISHED',
    RESET: 'RESET',
};
