import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Spacer, TextButton, spaceMixin, mediaBreakpointMixin } from '@zillow/constellation';

const StyledNav = styled.nav`
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.15s ease-in-out;

    &.nav-expanded {
        max-height: 410px;
    }

    ul {
        > li {
            padding: ${spaceMixin('sm')} ${spaceMixin('md')};
            border-bottom: 1px solid ${props => props.theme.constellation?.colors.borderLight};

            &.signout-wrapper {
                border-bottom: 0 none;
                word-break: break-word;

                @media ${mediaBreakpointMixin('lg')} {
                    display: none;
                }
            }

            a {
                color: ${props => props.theme.constellation?.colors.textDark};
                font-weight: 600;
                text-decoration: none;

                &.selected {
                    color: ${props => props.theme.constellation?.colors.purple600};
                }
            }
        }
    }

    @media ${mediaBreakpointMixin('lg')} {
        background-color: #fff;
        max-width: 1440px;
        margin: 0 auto;
        max-height: none;

        ul {
            max-width: 1350px;
            margin: 0 auto;
            padding: ${spaceMixin('sm')} ${spaceMixin('md')};

            > li {
                display: inline-block;
                margin-top: ${spaceMixin('xs')};
                margin-right: ${spaceMixin('lg')};
                margin-bottom: ${spaceMixin('xs')};
                padding: 0;
                border-color: #fff;

                &:first-child {
                    margin-left: 0;
                }

                a {
                    font-weight: 600;
                    text-decoration: none;
                    color: ${props => props.theme.constellation?.colors.textDark};

                    &:hover,
                    &.selected {
                        color: ${props => props.theme.constellation?.colors.purple600};
                        padding-bottom: 5px;
                    }

                    &.selected {
                        border-bottom: 3px solid
                            ${props => props.theme.constellation?.colors.purple600};
                    }
                }
            }
        }
    }
`;

const SubNav = ({ isOpen, onClickLogout, primaryEmail }) => {
    return (
        <StyledNav className={`menu ${isOpen ? 'nav-expanded' : ''}`}>
            <ul>
                <li>
                    <NavLink exact activeClassName="selected" to="/">
                        Privacy home
                    </NavLink>
                </li>
                <li>
                    <NavLink activeClassName="selected" to="/cookie">
                        Manage cookies
                    </NavLink>
                </li>
                <li>
                    <NavLink activeClassName="selected" to="/correction">
                        Update your personal data
                    </NavLink>
                </li>
                <li>
                    <NavLink activeClassName="selected" to="/export">
                        Request a copy of your data
                    </NavLink>
                </li>
                <li>
                    <NavLink activeClassName="selected" to="/delete">
                        Delete your data
                    </NavLink>
                </li>
                <li>
                    <NavLink activeClassName="selected" to="/support">
                        Privacy help center
                    </NavLink>
                </li>
                <li>
                    <NavLink activeClassName="selected" to="/download">
                        Download data
                    </NavLink>
                </li>
                <li className="signout-wrapper">
                    Hi, {primaryEmail}
                    <br />
                    <Spacer marginTop="xs">
                        <TextButton onClick={onClickLogout}>Sign out</TextButton>
                    </Spacer>
                </li>
            </ul>
        </StyledNav>
    );
};

SubNav.propTypes = {
    isOpen: PropTypes.bool,
    onClickLogout: PropTypes.func,
    primaryEmail: PropTypes.string,
};

export default SubNav;
