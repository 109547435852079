import React, { useEffect } from 'react';
import styled from 'styled-components';
import API from '../utils/api';
import { API_AUDIT_SUCCEEDED_FUNC } from '../constants/ApiConstants';
import { parseErrorResponse } from '../utils';

import { PageWrapper, PageContent, PageSection } from '../common/Page';
import { Heading, Paragraph } from '@zillow/constellation';
import { withRouter } from 'react-router-dom';
import { AUDIT_WHITE_LIST_DOMAINS } from '../constants/ManageCookiePageConstants';

const StyledPageContent = styled(PageContent)`
    padding: 0;
`;

const CookieOptOutSuccessPage = () => {
    useEffect(() => {
        if (window.performance) {
            if (performance.navigation.type === 0) {
                let referrer = document.referrer.split('/')[2];
                if (
                    referrer !== undefined &&
                    AUDIT_WHITE_LIST_DOMAINS.split(',').includes(referrer)
                ) {
                    API({
                        url: API_AUDIT_SUCCEEDED_FUNC(referrer),
                        method: 'POST',
                    }).catch(error => {
                        const errorMessage = parseErrorResponse(error);
                        console.log('Error in auditing API call ' + errorMessage);
                    });
                }
                referrer = '';
            }
        }
    }, []);

    return (
        <PageWrapper backgroundColor="#f4f4f4">
            <StyledPageContent>
                <PageSection maxWidth={1000}>
                    <Heading level="2" serif>
                        Your advertising cookies have been disabled
                    </Heading>
                    <Paragraph fontType="h5" as="p" marginTop="sm">
                        At your request, we've disabled advertising cookies when you visit our
                        zillow.com, trulia.com, and hotpads.com websites from this device using this
                        browser. If you'd like to enable those cookies, you'll need to do so from
                        each site individually.
                    </Paragraph>
                </PageSection>
            </StyledPageContent>
        </PageWrapper>
    );
};

export default withRouter(CookieOptOutSuccessPage);
