import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SeparatorLine from '../../common/SeparatorLine';
import BigArrow from '../../common/BigArrow';
import {
    Alert,
    Button,
    Heading,
    FormField,
    Spacer,
    Input,
    Paragraph,
    TextButton,
} from '@zillow/constellation';
import { Formik } from 'formik';

const StyledTextButton = styled(TextButton)`
    display: block;
`;

const StyledForm = styled.form`
    text-align: left;
`;

const StyledButtonWrapper = styled.div`
    text-align: center;
`;

const SigninCSRAdmin = ({ onClickBack, onSubmit }) => (
    <>
        <StyledTextButton onClick={onClickBack}>
            <BigArrow direction="left" />
            Back
        </StyledTextButton>

        <Heading level="3" serif marginTop="sm">
            CSR Admin
        </Heading>

        <Paragraph marginTop="sm">
            Please enter a valid email address to impersonate a user.
        </Paragraph>

        <SeparatorLine width={16} lgSpacingTop="lg" lgSpacingBottom="sm" />

        <Formik
            initialValues={{ userEmail: '', zuid: '', tuid: '' }}
            validate={values => {
                let errors = {};
                if (!values.userEmail && !values.zuid && !values.tuid) {
                    errors.emptyForm = 'Please fill the form.';
                }
                return errors;
            }}
            onSubmit={values => onSubmit(values.userEmail)}
        >
            {({ errors, touched, handleChange, handleBlur, handleSubmit }) => (
                <StyledForm onSubmit={handleSubmit}>
                    <FormField marginTop="md" marginBottom="sm">
                        <Input
                            name="userEmail"
                            id="userEmail"
                            placeholder="put a valid user email here"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </FormField>

                    {errors.emptyForm && (touched.userEmail || touched.zuid || touched.tuid) && (
                        <Spacer marginTop="xs">
                            <Alert appearance="error" body={errors.emptyForm} />
                        </Spacer>
                    )}

                    <StyledButtonWrapper>
                        <Button buttonType="primary" type="submit" marginTop="sm" marginBottom="xs">
                            Impersonate
                        </Button>
                    </StyledButtonWrapper>
                </StyledForm>
            )}
        </Formik>
    </>
);

SigninCSRAdmin.propTypes = {
    onSubmit: PropTypes.func,
    onClickBack: PropTypes.func,
};

SigninCSRAdmin.displayName = 'SIGNIN_CSR_ADMIN';

export default SigninCSRAdmin;
