export const INFO_SELECTIONS = [
    {
        name: 'BROWSING',
        description:
            'Internet or Browsing Information: This includes your activity on our sites and data sent to us from web pixels or beacons located on other sites.',
        isAdvanced: true,
        displayName: 'Browsing information',
    },
    {
        name: 'COMMERCIAL',
        description:
            "Commercial Information: This includes property transactions, as well as products or services you've requested from us.",
        isAdvanced: false,
        displayName: 'Transactions and services',
    },
    {
        name: 'PERSONAL_IDENTIFICATION',
        description:
            'Personal Identifiers: This includes information that identifies or could be used to identify or describe you.',
        isAdvanced: false,
        displayName: 'Personal & account information',
    },
    {
        name: 'INFERENCES',
        description:
            "Inferences: Predictive inferences we've generated about your preferences from data we've collected.",
        isAdvanced: true,
        displayName: 'Inferences',
    },
    {
        name: 'GEOLOCATION',
        description:
            "Geolocation Data: This includes your location while using our apps, if you've enabled those settings on your devices.",
        isAdvanced: true,
        displayName: 'Geolocation data',
    },
    {
        name: 'RECORDING_OR_COMMUNICATION',
        description:
            'Audio Recordings and Electronic Communications: This includes phone call recordings and records of electronic communications with us.',
        isAdvanced: true,
        displayName: 'Communications with us',
    },
];

export const EXAMPLE_EXPORT_DATA = [
    {
        'User ID': 66666,
        'Saved Homes': [
            {
                propertyId: 66666,
                savedDate: 1562113775160,
                contactedDate: null,
                comment: 'string',
            },
            {
                propertyId: 7382783278,
                savedDate: 1565986098846,
                contactedDate: null,
                comment: 'this is a sample',
            },
        ],
    },
];
