import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading, Alert, Text, Spacer, spaceMixin } from '@zillow/constellation';

const StyledGridContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: 40% 30% 30%;
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 10px;
`;

const StyledReportContainer = styled.div`
    max-height: 350px;
    overflow: hidden;
    overflow-y: scroll;
`;

const StyledGridEvenContainer = styled.div`
    display: grid;
    background-color: ${props => props.theme.constellation?.colors.gray200};
    align-items: start;
    grid-template-columns: 40% 30% 30%;
`;

const StyledGridOddContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: 40% 30% 30%;
`;

const StyledHeader = styled.div`
    display: inline-block;
    padding: 10px;
    background-color: ${props => props.theme.constellation?.colors.gray400};
    min-height: ${spaceMixin('xs')};
`;
const StyledRow = styled.div`
    display: inline-block;
    padding: 5px 10px;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const getContent = props => {
    let content = [];
    props.data.forEach((obj, i) => {
        const StyledGridRowContainer = i % 2 ? StyledGridEvenContainer : StyledGridOddContainer;
        content.push(
            <StyledGridRowContainer key={i}>
                <StyledRow>{obj.date}</StyledRow>
                <StyledRow>{obj.requestType}</StyledRow>
                <StyledRow>{obj.RequestCount}</StyledRow>
            </StyledGridRowContainer>
        );
    });
    return content;
};

const RequestReceivedPerDayByType = props => {
    const reportContent =
        props.data.length <= 0 ? (
            <Alert appearance="warning" body="No data found." />
        ) : (
            <Spacer>
                <Spacer marginTop="xs">
                    <Alert
                        body={
                            <Text>
                                Total number of records found: <strong>{props.data.length}</strong>
                            </Text>
                        }
                    />
                </Spacer>
                <StyledGridContainer>
                    <StyledHeader>Date</StyledHeader>
                    <StyledHeader>RequestType</StyledHeader>
                    <StyledHeader>RequestCount</StyledHeader>
                </StyledGridContainer>
                <StyledReportContainer>{getContent(props)}</StyledReportContainer>
            </Spacer>
        );
    return (
        <div>
            <Heading level="4">Request received per day by request type :</Heading>
            {reportContent}
        </div>
    );
};

RequestReceivedPerDayByType.propTypes = {
    data: PropTypes.any,
};

RequestReceivedPerDayByType.defaultProps = {
    data: [],
};

export default RequestReceivedPerDayByType;
