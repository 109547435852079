import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AgentDetailsPage from '../admin/AgentDetailsPage';
import {
    Alert,
    Button,
    Heading,
    Input,
    ModalDialog,
    Paragraph,
    Spacer,
    Radio,
    Text,
    TextButton,
    Tooltip,
    TooltipPopper,
    Trigger,
    TriggerText,
    FormField,
} from '@zillow/constellation';

const gridSize = '32% 24% 28% 17%';

const StyledGridContainer = styled.div`
    font-size: 15px;
    display: grid;
    align-items: start;
    grid-template-columns: ${gridSize};
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 10px;
`;

const StyledReportContainer = styled.div`
    max-height: 350px;
    overflow: hidden;
    overflow-y: scroll;
`;

const StyledGridEvenContainer = styled.div`
    display: grid;
    background-color: ${props => props.theme.constellation?.colors.gray200};
    align-items: start;
    grid-template-columns: ${gridSize};
`;

const StyledGridOddContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: ${gridSize};
`;

const StyledHeader = styled.div`
    display: inline-block;
    padding: 15px;
    background-color: ${props => props.theme.constellation?.colors.gray400};
`;

const StyledRow = styled.div`
    display: inline-block;
    padding: 5px 10px;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const StyledFooterButtonsContainer = styled.div`
    width: 100%;
    text-align: right;
`;

const StyledGreenSpan = styled.span`
    color: ${props => props.theme.constellation?.colors.green500};
`;

const StyledRedSpan = styled.span`
    color: ${props => props.theme.constellation?.colors.red500};
`;

const StyledBlackSpan = styled.span`
    color: ${props => props.theme.constellation?.colors.trueBlack};
`;

const StyledHeaderSpan = styled.span`
    font-weight: bold;
`;

const StyledTriggerText = styled(TriggerText)`
    text-align: left;
`;

const StyledButton = styled(Button)`
    padding: 5px 5px;
    width: 45px;
    border-radius: 25px;
    height: 35px;
    font-size: 12px;
`;

const StyledModalDialog = styled(ModalDialog)`
    text-align: center;
    button[class^='DialogClose-'] {
        display: none;
    }
`;

const StyledInput = styled(Input)`
    margin-top: 10px;
    width: 95%
    border-radius: 10px;
    margin-bottom: 10px;
`;

const AgentsDetailsPage = props => {
    const [searchText, setSearchText] = useState('');
    let filteredData = props;

    const getContent = props => {
        let content = [];
        props.sort(function(agent1, agent2) {
            return agent1.agentName.localeCompare(agent2.agentName);
        });

        props.forEach((obj, i) => {
            const StyledGridContainer = i % 2 ? StyledGridEvenContainer : StyledGridOddContainer;
            const StyledAgentName =
                obj.agentStatus === 'ACTIVE'
                    ? StyledGreenSpan
                    : obj.agentStatus === 'INACTIVE'
                    ? StyledRedSpan
                    : StyledBlackSpan;
            const agentTooltip = (
                <Tooltip
                    body={
                        <Paragraph>
                            Agent Status :<StyledHeaderSpan> {obj.agentStatus} </StyledHeaderSpan>
                        </Paragraph>
                    }
                />
            );
            if (JSON.stringify(obj.agentDeleteDataDomainsForDisplay) !== '[{}]') {
                obj.agentDeleteDataDomains = obj.agentDeleteDataDomainsForDisplay;
            }

            const scrollBodyDialog = (props, close) => (
                <StyledModalDialog
                    {...props}
                    wide
                    body={
                        <React.Fragment>
                            <AgentDetailsPage data={obj} isAllAgentsPage={true} />
                        </React.Fragment>
                    }
                    footer={
                        <StyledFooterButtonsContainer>
                            <TextButton onClick={close}>OK</TextButton>
                        </StyledFooterButtonsContainer>
                    }
                />
            );

            content.push(
                <React.Fragment>
                    <StyledGridContainer key={obj.agentId}>
                        <StyledRow>
                            <TooltipPopper triggered={agentTooltip}>
                                <StyledTriggerText>
                                    <StyledAgentName>{obj.agentName}</StyledAgentName>
                                </StyledTriggerText>
                            </TooltipPopper>
                        </StyledRow>
                        <StyledRow>
                            <a
                                href={
                                    'https://zodiac.zgtools.net/services/' + obj.zodiacServiceName
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {obj.zodiacServiceName}
                            </a>
                        </StyledRow>
                        <StyledRow>{obj.agentDeleteType}</StyledRow>
                        <StyledRow>
                            <Trigger triggered={scrollBodyDialog}>
                                <StyledButton> More</StyledButton>
                            </Trigger>
                        </StyledRow>
                    </StyledGridContainer>
                </React.Fragment>
            );
        });
        return content;
    };

    const handleAgentSearch = (searchText, state) => {
        filteredData = [];
        filteredData =
            state === 'searchText'
                ? props.data.filter(job =>
                      job.agentName.toLowerCase().includes(searchText.toLowerCase())
                  )
                : state === 'allAgents'
                ? props.data
                : state === 'activeAgents'
                ? props.data.filter(job => job.agentStatus === 'ACTIVE')
                : state === 'inactiveAgents'
                ? props.data.filter(job => job.agentStatus === 'INACTIVE')
                : props.data;

        return filteredData.length <= 0 ? (
            <Alert appearance="warning" body="No agent found." />
        ) : (
            <div>
                <StyledGridContainer>
                    <StyledHeader>Agent Name</StyledHeader>
                    <StyledHeader>Zodiac Service Name</StyledHeader>
                    <StyledHeader>Agent Delete Type</StyledHeader>
                    <StyledHeader>More Details</StyledHeader>
                </StyledGridContainer>
                <StyledReportContainer>{getContent(filteredData)}</StyledReportContainer>
            </div>
        );
    };

    const [checked, setChecked] = React.useState([false, false, false]);
    const [statusType, setStatusType] = React.useState('All');
    const options = [
        {
            label: 'All Agents: ',
            value: 'allAgents',
            count: props.data.length,
        },
        {
            label: 'Active Agents: ',
            value: 'activeAgents',
            count: props.data.filter(job => job.agentStatus === 'ACTIVE').length,
        },
        {
            label: 'Inactive Agents: ',
            value: 'inactiveAgents',
            count: props.data.filter(job => job.agentStatus === 'INACTIVE').length,
        },
    ];
    const reportContent =
        props.data.length <= 0 ? (
            <Alert appearance="warning" body="No agent found." />
        ) : (
            <Spacer>
                <Spacer marginTop="xs">
                    <Alert
                        body={
                            <span>
                                <Text>
                                    Total number of Registered Data Agents:{' '}
                                    <strong> {props.data.length}</strong>
                                </Text>
                                <FormField>
                                    <div>
                                        <StyledInput
                                            type="text"
                                            placeholder="Search your data agent here"
                                            onChange={e => {
                                                setStatusType('searchText');
                                                setSearchText(e.target.value);
                                            }}
                                        />
                                        <ul>
                                            {options.map(option => (
                                                <label key={option.value}>
                                                    <Radio
                                                        style={{ verticalAlign: 'middle' }}
                                                        name="statusType"
                                                        onChange={() => {
                                                            setChecked(!checked[option.index]);
                                                            setStatusType(option.value);
                                                        }}
                                                    />
                                                    <Text style={{ marginLeft: 5 }}>
                                                        {option.label}
                                                    </Text>
                                                    <strong style={{ marginRight: 30 }}>
                                                        {option.count}
                                                    </strong>
                                                </label>
                                            ))}
                                        </ul>
                                    </div>
                                </FormField>
                            </span>
                        }
                    />
                    {handleAgentSearch(searchText, statusType)}
                </Spacer>
            </Spacer>
        );

    return (
        <div>
            <div>
                <Heading level="4"> Registered Data Agents Details</Heading>
                {reportContent}
            </div>
        </div>
    );
};

AgentsDetailsPage.propTypes = {
    data: PropTypes.any,
};

AgentsDetailsPage.defaultProps = {
    data: [],
};

export default AgentsDetailsPage;
