import React, { useEffect, useContext, useState, useRef } from 'react';
import styled from 'styled-components';
import manageCookiesImage from '../images/manage-cookies.png';
import zillowLogo from '../images/zillow-logo.svg';
import truliaLogo from '../images/trulia-logo.svg';
import hotpadsLogo from '../images/hotpads-logo.svg';
import streetEasyLogo from '../images/streeteasy-logo.svg';
import BigArrow from '../common/BigArrow';
import API from '../utils/api';
import grecaptchaComponent from '@zillow/google-recaptcha-component';
import PropTypes from 'prop-types';
import { parseErrorResponse } from '../utils';
import SigninContext, { ACTIONS as SIGNIN_ACTIONS } from '../contexts/SigninContext';
import {
    PageWrapper,
    PageSection,
    PageContent,
    StyledIntroFlex,
    StyledExpandTextButton,
} from '../common/Page';
import { API_AUDIT_INITIATED_FUNC, API_BASE_DOMAIN } from '../constants/ApiConstants';
import { REQUEST_TYPE } from '../constants/RequestsConstants';
import {
    spaceMixin,
    Collapsible,
    Heading,
    Paragraph,
    Spacer,
    Text,
    Button,
    TextButton,
    mediaBreakpointMixin,
} from '@zillow/constellation';
import {
    API_OPT_OUT_URL,
    CAPTCHA_PUBLIC_API_KEY,
    OPT_OUT_CAPTCHA_DISPLAY,
    OPT_OUT_OF_SALE_REQUEST,
} from '../constants/ManageCookiePageConstants';
import Loading from '../components/verification/Loading';
import VerificationModalDialog from '../containers/VerificationModalDialog';
import { StyledHeadingParagraph } from '../common/Page';

const StyledPageContent = styled(PageContent)`
    padding: 0;
`;

const StyledParagraph = styled(Paragraph)`
    max-width: 792px;
    margin-top: ${spaceMixin('md')};
`;

const StyledDiv = styled.div`
    flex-wrap: wrap;

    > div {
        margin: ${spaceMixin('xl')} 0;

        > img {
            height: 40px;
            margin-bottom: ${spaceMixin('sm')};
        }

        > ul {
            list-style: outside;
            li {
                margin-top: ${spaceMixin('sm')};
                margin-left: ${spaceMixin('sm')};
            }
        }

        @media ${mediaBreakpointMixin('lg')} {
            display: flex;
            align-items: center;

            > img {
                min-width: 350px;
                margin-bottom: 0;
            }

            > ul {
                max-width: 500px;
            }
        }
    }
`;

const ShowErrorMessage = styled.span`
    color: ${props => props.theme.constellation?.colors.red500};
`;

const ShowCaptchaBorder = styled.div`
    width: 792px;
    margin-top: ${spaceMixin('sm')};
    border: 1px solid ${props => props.theme.constellation?.colors.borderLight};
    box-shadow: 0 2px 3px ${props => props.theme.constellation?.colors.gray300};
    padding: 16px;
`;

const ManageCookiePage = props => {
    const {
        state: { isAuthenticated, isBrandAccount },
        dispatch: signinDispatch,
    } = useContext(SigninContext);

    let captchaWidgetId = useRef();
    const [isCaptchaError, setCaptchaError] = useState(false);
    const [isCaptchaRender, setCaptchaRender] = useState(false);

    const isDisplayCaptcha = OPT_OUT_CAPTCHA_DISPLAY === 'true' && !isAuthenticated;

    const validate_captcha = props => {
        const captchaResponse = props.captchaAPI.getResponse(captchaWidgetId.current);
        if (!captchaResponse && isDisplayCaptcha) {
            setCaptchaError(true);
        } else {
            initiate_opt_out();
        }
    };

    const initiate_opt_out = () => {
        window.open(API_OPT_OUT_URL, 'optout');
        let inputReferrer = API_BASE_DOMAIN.split('/')[2];
        API({
            url: API_AUDIT_INITIATED_FUNC(inputReferrer),
            method: 'POST',
        }).catch(error => {
            const errorMessage = parseErrorResponse(error);
            console.log('Error in auditing API call ' + errorMessage);
        });
    };

    const openSigninModal = () => {
        signinDispatch({
            type: SIGNIN_ACTIONS.OPEN_SIGNIN_MODAL,
            payload: {
                redirectTo: '/cookie',
            },
        });
    };

    const mounted = useRef();

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            if (props.captchaAPI && !isCaptchaRender && isDisplayCaptcha) {
                captchaWidgetId.current = props.captchaAPI.render('remove-cookie', {
                    sitekey: CAPTCHA_PUBLIC_API_KEY,
                });
                setCaptchaRender(true);
            }
        } else {
            props.captchaAPI.ready(() => {
                if (!isCaptchaRender && isDisplayCaptcha && props.captchaAPI) {
                    captchaWidgetId.current = props.captchaAPI.render('remove-cookie', {
                        sitekey: CAPTCHA_PUBLIC_API_KEY,
                    });
                    setCaptchaRender(true);
                }
            });
        }

        return () => {
            if (typeof captchaWidgetId.current === 'number') {
                props.captchaAPI.reset(captchaWidgetId.current);
            }
        };
    }, [isCaptchaRender, isDisplayCaptcha, props.captchaAPI]);

    const messageText =
        isAuthenticated && isBrandAccount
            ? 'You can disable advertising cookies on zillow.com, trulia.com, hotpads.com or streeteasy.com by selecting "Disable Your Advertising Cookies" below. This setting will apply to all of the devices and browsers you use to visit to us.'
            : 'You can disable advertising cookies on zillow.com, trulia.com, hotpads.com and streeteasy.com by selecting "Disable Your Advertising Cookies" below. Please note that this setting will apply only to this browser and this device.';
    const signinText =
        !isAuthenticated || !isBrandAccount ? (
            <span>
                If you'd like to apply this setting to all of your associated browsers and devices,
                &nbsp;<TextButton onClick={openSigninModal}>sign in to your account.</TextButton>
            </span>
        ) : (
            ''
        );
    return (
        <PageWrapper backgroundColor="#f4f4f4">
            <StyledPageContent>
                <PageSection maxWidth={1200}>
                    <StyledIntroFlex
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        flexWrap="wrap"
                        image={manageCookiesImage}
                    >
                        <div className="page-hero-image" />
                        <div>
                            <Heading level="3" serif>
                                Manage cookies
                            </Heading>
                            <StyledHeadingParagraph fontType="h5" marginTop="sm">
                                You can customize your cookie settings on any of our sites.
                            </StyledHeadingParagraph>
                        </div>
                    </StyledIntroFlex>
                </PageSection>
            </StyledPageContent>

            <Spacer marginTop="md">
                <PageContent maxWidth={1200}>
                    <Heading level="5">
                        <strong>What are cookies and pixels?</strong>
                    </Heading>

                    <StyledParagraph>
                        Cookies are small snippets of text that are placed on your browser when you
                        visit a website. They allow the site to recognize your device when you
                        return. Pixels (sometimes called tags or web beacons) are tiny images placed
                        on a website that allow the site's operator or a third party to track what
                        content is viewed on the site. Pixels are often used in combination with
                        cookies. Websites use these technologies to track what people see while
                        using the sites, but they don't identify you directly.
                    </StyledParagraph>
                    <Collapsible defaultCollapsed>
                        {(collapsed, toggle) => (
                            <>
                                {collapsed ? null : (
                                    <>
                                        <Spacer marginTop="xl">
                                            <Text fontType="bodyheading">
                                                <strong>First-party and third-party cookies</strong>
                                            </Text>
                                            <StyledParagraph>
                                                There are two kinds of cookies: first-party cookies
                                                and third-party cookies. First-party cookies are
                                                placed by the owner of the site you're visiting.
                                                They give the owner feedback about the site's
                                                functioning and performance. They also make
                                                personalization features possible and allow the site
                                                to remember your password the next time you visit.
                                                Third-party cookies are placed on the site by other
                                                websites and give the owners of those sites
                                                information about your internet activity.
                                            </StyledParagraph>
                                        </Spacer>

                                        <Spacer marginTop="xl">
                                            <Text fontType="bodyheading">
                                                <strong>Cookies and pixels on our sites</strong>
                                            </Text>
                                            <StyledParagraph>
                                                We use first-party cookies to remember your
                                                preferences and gather information about how people
                                                use our sites. We also allow certain companies to
                                                place third-party cookies and pixels on our sites.
                                                Those third parties can use the information from
                                                those cookies and pixels for interest-based
                                                advertising. For example, if a furniture company
                                                knows that you've visited Zillow recently to look at
                                                homes for sale, they may assume you're interested in
                                                buying a new home and show you an ad for new home
                                                furnishings. That kind of ad could appear on Zillow
                                                or on other websites.
                                            </StyledParagraph>
                                            <StyledParagraph>
                                                This type of data sharing for advertising purposes
                                                may be considered a "sale" under certain laws,
                                                because the companies can use that data to serve you
                                                more relevant ads. You always have full control over
                                                whether these technologies work on your devices.
                                            </StyledParagraph>
                                        </Spacer>
                                    </>
                                )}
                                <Spacer marginTop="xl">
                                    <StyledExpandTextButton fontType="bodySmall" onClick={toggle}>
                                        {collapsed ? (
                                            <>
                                                FIND OUT MORE{' '}
                                                <BigArrow className="down-arrow" direction="down" />
                                            </>
                                        ) : (
                                            <>
                                                CLOSE{' '}
                                                <BigArrow className="up-arrow" direction="up" />
                                            </>
                                        )}
                                    </StyledExpandTextButton>
                                </Spacer>
                            </>
                        )}
                    </Collapsible>
                </PageContent>
            </Spacer>

            <Spacer marginTop="md">
                <PageContent maxWidth={1200}>
                    <Heading level="5">
                        <strong>Disable all your advertising cookies</strong>
                    </Heading>

                    <StyledParagraph>
                        {messageText}
                        {signinText}
                    </StyledParagraph>
                    {isDisplayCaptcha ? (
                        <ShowCaptchaBorder>
                            <Paragraph>Please complete the Captcha below.</Paragraph>
                            <br />
                            <div id="remove-cookie" />
                            <div>
                                <RenderOptOutButton
                                    callbackFun={validate_captcha}
                                    isAuthenticated={isAuthenticated}
                                    verificationModalCallBackFun={initiate_opt_out}
                                    captchaAPI={props.captchaAPI}
                                    isBrandAccount={isBrandAccount}
                                />
                            </div>
                            <span>
                                {isCaptchaError ? (
                                    <div id="captcha_error_message">
                                        <ShowErrorMessage>
                                            There was an issue with the captcha result.
                                            <br />
                                            Please try again.
                                        </ShowErrorMessage>
                                    </div>
                                ) : null}
                            </span>
                        </ShowCaptchaBorder>
                    ) : (
                        <div>
                            <br />
                            <RenderOptOutButton
                                callbackFun={initiate_opt_out}
                                isAuthenticated={isAuthenticated}
                                verificationModalCallBackFun={initiate_opt_out}
                                captchaAPI={props.captchaAPI}
                                isBrandAccount={isBrandAccount}
                            />
                        </div>
                    )}
                </PageContent>
            </Spacer>

            <Spacer marginTop="md">
                <PageContent maxWidth={1200}>
                    <Heading level="5">
                        <strong>Adjust your cookie settings individually</strong>
                    </Heading>

                    <StyledParagraph>
                        You can adjust your cookie settings by following the steps below. Be aware
                        that if you disable or limit certain cookies, you may be unable to use
                        features that rely on personalization.
                    </StyledParagraph>

                    <StyledParagraph>
                        Follow these instructions to adjust your cookie preferences on our sites.
                    </StyledParagraph>

                    <StyledDiv marginTop="sm">
                        <RenderCookieSettings
                            brandLogo={zillowLogo}
                            brandUrl={'https://www.zillow.com'}
                            brandLogoAltText={'zillow-logo'}
                            brandDomain={'zillow.com'}
                        />

                        <RenderCookieSettings
                            brandLogo={truliaLogo}
                            brandUrl={'https://www.trulia.com'}
                            brandLogoAltText={'trulia-logo'}
                            brandDomain={'trulia.com'}
                        />

                        <RenderCookieSettings
                            brandLogo={hotpadsLogo}
                            brandUrl={'https://www.hotpads.com'}
                            brandLogoAltText={'hotpads-logo'}
                            brandDomain={'hotpads.com'}
                        />

                        <RenderCookieSettings
                            brandLogo={streetEasyLogo}
                            brandUrl={'https://streeteasy.com/'}
                            brandLogoAltText={'streeteasy-logo'}
                            brandDomain={'streeteasy.com'}
                        />
                    </StyledDiv>
                </PageContent>
            </Spacer>
        </PageWrapper>
    );
};

ManageCookiePage.propTypes = {
    captchaAPI: PropTypes.object,
};

const RenderOptOutButton = props => {
    const initiateOptOutRequest =
        OPT_OUT_OF_SALE_REQUEST === 'true' && props.isAuthenticated && props.isBrandAccount;

    const optOutButton = initiateOptOutRequest ? (
        <VerificationModalDialog
            type={REQUEST_TYPE.OPT_OUT_OF_SALE}
            buttonText="Disable My Advertising Cookies"
            initialVerificationContentDisplayName={Loading.displayName}
            shouldCloseOnOutsideClick={false}
            shouldCloseOnESCKeyPress={false}
            renderCloseButton={null}
            callbackFun={props.verificationModalCallBackFun}
        />
    ) : (
        <Button
            marginTop="md"
            buttonType="primary"
            onClick={() => {
                props.callbackFun(props);
            }}
        >
            Disable My Advertising Cookies
        </Button>
    );

    return optOutButton;
};

RenderOptOutButton.propTypes = {
    callbackFun: PropTypes.func,
    isAuthenticated: PropTypes.bool,
    verificationModalCallBackFun: PropTypes.func,
    captchaAPI: PropTypes.object,
    isBrandAccount: PropTypes.bool,
};

const RenderCookieSettings = props => {
    const cookieSetting = (
        <div>
            <img src={props.brandLogo} alt={props.brandLogoAltText} />
            <ul>
                <li>
                    <Text>
                        Visit{' '}
                        <a href={props.brandUrl} target="_blank" rel="noopener noreferrer">
                            {props.brandDomain}
                        </a>
                        , scroll to the bottom of the page and select{' '}
                        <strong>Cookie Preference</strong>.
                    </Text>
                </li>

                <li>
                    <Text>Adjust your preferences.</Text>
                </li>

                <li>
                    <Text>
                        Select <strong>Save Settings</strong>.
                    </Text>
                </li>
            </ul>
        </div>
    );

    return cookieSetting;
};

RenderCookieSettings.propTypes = {
    brandUrl: PropTypes.string,
    brandLogo: PropTypes.string,
    brandLogoAltText: PropTypes.string,
    brandDomain: PropTypes.string,
};

const CaptchaWrappedManageCookiePage = grecaptchaComponent(ManageCookiePage);

CaptchaWrappedManageCookiePage.WrappedComponent = ManageCookiePage;

export default CaptchaWrappedManageCookiePage;
