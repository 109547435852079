import React from 'react';
import styled from 'styled-components';
import deleteDataImage from '../images/delete-data.png';
import SeparatorLine from '../common/SeparatorLine';
import BigArrow from '../common/BigArrow';
import VerificationModalDialog from '../containers/VerificationModalDialog';
import AreYouSure from '../components/confirmation/AreYouSure';
import NeedHelp from '../components/NeedHelp';
import { REQUEST_TYPE } from '../constants/RequestsConstants';

import {
    PageWrapper,
    PageContent,
    PageSection,
    StyledIntroFlex,
    StyledExpandTextButton,
    StyledHeadingParagraph,
} from '../common/Page';
import { Link } from 'react-router-dom';
import {
    spaceMixin,
    Button,
    Collapsible,
    Heading,
    Paragraph,
    Spacer,
    Text,
} from '@zillow/constellation';

const StyledParagraph = styled(Paragraph)`
    max-width: 792px;
    margin-top: ${spaceMixin('md')};
`;

// const StyledParagraphText = styled(Paragraph)`
//   line-height: ${spaceMixin('lg')};
//   font-weight: ${fontWeightMixin('body')};
// `;

const StyledPageContent = styled(PageContent)`
    padding: 0;
`;

const StyledCollapsibleDiv = styled.div`
    max-width: 792px;
`;

const StyledLargeSpacer = styled.div`
    margin-top: 72px;
`;

const DeleteDataPage = () => (
    <PageWrapper backgroundColor="#f4f4f4">
        <StyledPageContent>
            <PageSection maxWidth={1200}>
                <StyledIntroFlex
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    flexWrap="wrap"
                    image={deleteDataImage}
                >
                    <div className="page-hero-image" />
                    <div>
                        <Heading level="3" serif>
                            Delete your data
                        </Heading>
                        <StyledHeadingParagraph fontType="h5" marginTop="sm">
                            You can choose to deactivate your account or to permanently delete your
                            data and your account.
                        </StyledHeadingParagraph>
                    </div>
                </StyledIntroFlex>
            </PageSection>
        </StyledPageContent>

        <Spacer marginTop="md">
            <PageContent maxWidth={1200}>
                <Heading level="5">
                    <strong>Deactivate your account</strong>
                </Heading>

                <StyledParagraph>
                    You may choose to deactivate your account. This takes effect immediately and it
                    is reversible — you can always decide to reactivate your account later. Be aware
                    that as long as your account is deactivated, you'll be unable to take advantage
                    of tools and services such as online rental applications, requesting a Zillow
                    Offer on your home or applying for a mortgage online.
                </StyledParagraph>

                <StyledParagraph>
                    <Link to="/support#deactivate-account">
                        <Button buttonType="secondary">Deactivate your account</Button>
                    </Link>
                </StyledParagraph>
            </PageContent>
        </Spacer>

        <Spacer marginTop="md">
            <PageContent maxWidth={1200}>
                <Heading level="5">
                    <strong>Permanently delete your data</strong>
                </Heading>

                <StyledParagraph>
                    You may also choose to permanently delete your personal information,
                    <strong> including your account.</strong> This cannot be reversed. Once you
                    delete your data, you'll be unable to retrieve any personal information related
                    to your account. We delete data either by erasing it entirely (where possible)
                    or by de-identifying it, meaning we remove the part that can be used to identify
                    you, leaving the data no longer linked to you.
                </StyledParagraph>

                <StyledParagraph>
                    We'll ask you to verify your email and confirm that you want to delete your
                    data. Because of the complexity of our data systems and the number of requests
                    we receive, it may take up to 45 days to complete the deletion process. Please
                    note: you will not be able to create a new account using your existing email
                    address for 90 days. We’ll retain a record of your request. Get more info by
                    visiting our <Link to="/support">Privacy Help Center</Link>.
                </StyledParagraph>

                <Spacer marginTop="xl" marginBottom="xl">
                    <VerificationModalDialog
                        buttonText="Delete data"
                        type={REQUEST_TYPE.DELETE}
                        initialVerificationContentDisplayName={AreYouSure.displayName}
                        shouldCloseOnOutsideClick={false}
                        shouldCloseOnESCKeyPress={false}
                        renderCloseButton={null}
                    />
                </Spacer>

                <StyledLargeSpacer>
                    <Text fontType="bodySmallHeading" as="p">
                        <strong>WHAT'S NOT INCLUDED</strong>
                    </Text>
                </StyledLargeSpacer>

                <SeparatorLine
                    width={16}
                    justifyContent="flex-start"
                    lgSpacingTop="20"
                    lgSpacingBottom="20"
                />

                <StyledCollapsibleDiv>
                    <Paragraph>
                        Information we get from public records and other sources about properties,
                        including square feet, number of rooms, property taxes and past sales. Or
                        the{' '}
                        <a
                            href="https://www.zillow.com/zestimate/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Zestimate®
                        </a>
                        , Zillow's estimate of a home's market value.
                    </Paragraph>

                    <StyledParagraph>
                        Details relating to a{' '}
                        <a
                            href="https://www.zillow.com/offers/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Zillow Offer
                        </a>{' '}
                        that you've requested, including internal calculations and estimates for
                        recommended repairs and upgrades.
                    </StyledParagraph>

                    <StyledParagraph>
                        Data that isn't linked to you or your account.
                    </StyledParagraph>

                    <StyledParagraph>
                        Any photos, videos or 3D home tours that you've uploaded. These can be{' '}
                        <a
                            href="https://zillow.zendesk.com/hc/en-us/articles/203511930-How-do-I-add-or-remove-photos-of-my-home-"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            deleted only by you
                        </a>
                        .
                    </StyledParagraph>

                    <StyledParagraph>
                        Information outside the scope of the California Consumer Privacy Act.
                    </StyledParagraph>

                    <StyledParagraph>
                        In some circumstances, we may need to retain data or keep an account active
                        for a period of time.
                    </StyledParagraph>

                    <StyledParagraph>
                        Information related to your employment or application for employment with
                        Zillow Group. Please contact your Human Resources Business Partner or email
                        <a href="mailto:privacy@zillow.com"> privacy@zillow.com</a>
                    </StyledParagraph>

                    <Collapsible defaultCollapsed>
                        {(collapsed, toggle) => (
                            <>
                                {collapsed ? null : (
                                    <>
                                        <Heading level="5" marginTop="xl">
                                            For example:
                                        </Heading>
                                        <Spacer marginTop="sm">
                                            <Text fontType="bodySmallHeading" as="p">
                                                <strong>
                                                    IF YOU HAVE ONGOING BUSINESS WITH US
                                                </strong>
                                            </Text>
                                            <Paragraph marginTop="xs">
                                                We cannot delete your information if you have
                                                ongoing business with us, such as a rental
                                                application, an offer on your home, automatic rent
                                                payments, or an active Premier Agent account.
                                            </Paragraph>
                                        </Spacer>

                                        <Spacer marginTop="xl">
                                            <Text fontType="bodySmallHeading" as="p">
                                                <strong>
                                                    IF YOU HAVE RECENTLY DONE BUSINESS WITH US
                                                </strong>
                                            </Text>
                                            <Paragraph marginTop="xs">
                                                For legal reasons, we need to retain your
                                                information for a period of time after any business
                                                transaction ends.
                                            </Paragraph>
                                        </Spacer>

                                        <Spacer marginTop="xl">
                                            <Text fontType="bodySmallHeading" as="p">
                                                <strong>
                                                    IF WE'RE REQUIRED TO KEEP IT OR WE NEED IT FOR
                                                    FRAUD PREVENTION
                                                </strong>
                                            </Text>
                                            <Paragraph marginTop="xs">
                                                We won't be able to delete data if we're required to
                                                keep it for legal or business reasons or if it's
                                                necessary to combat fraudulent transactions and
                                                other illegal activities.
                                            </Paragraph>
                                        </Spacer>

                                        <Spacer marginTop="xl">
                                            <Text fontType="bodySmallHeading" as="p">
                                                <strong>
                                                    IF YOU HAVE A PENDING DATA ARCHIVE REQUEST
                                                </strong>
                                            </Text>
                                            <Paragraph marginTop="xs">
                                                We cannot delete your data until we've completed any
                                                archive request you've made.
                                            </Paragraph>
                                        </Spacer>
                                    </>
                                )}
                                <Spacer marginTop="xl">
                                    <StyledExpandTextButton fontType="bodySmall" onClick={toggle}>
                                        {collapsed ? (
                                            <>
                                                FIND OUT MORE{' '}
                                                <BigArrow className="down-arrow" direction="down" />
                                            </>
                                        ) : (
                                            <>
                                                CLOSE{' '}
                                                <BigArrow className="up-arrow" direction="up" />
                                            </>
                                        )}
                                    </StyledExpandTextButton>
                                </Spacer>
                            </>
                        )}
                    </Collapsible>
                </StyledCollapsibleDiv>
            </PageContent>
        </Spacer>

        <Spacer marginTop="md">
            <PageContent maxWidth={1200}>
                <NeedHelp />
            </PageContent>
        </Spacer>
    </PageWrapper>
);

export default DeleteDataPage;
