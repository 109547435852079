import React, { useEffect } from 'react';
import styled from 'styled-components';
import privacySupportImage from '../images/privacy-support.png';
import BigArrow from '../common/BigArrow';
import scrollToElement from 'scroll-to-element';

import {
    PageWrapper,
    PageContent,
    PageSection,
    StyledIntroFlex,
    StyledExpandTextButton,
} from '../common/Page';
import {
    Button,
    Collapsible,
    Heading,
    Paragraph,
    Spacer,
    fontWeightMixin,
} from '@zillow/constellation';
import { FAQ } from '../constants/PrivacySupportPageConstants';
import { withRouter } from 'react-router-dom';

const StyledPageContent = styled(PageContent)`
    padding: 0;
`;

const StyledCollapsibleDiv = styled.div`
    max-width: 792px;
`;

const StyledTextButton = styled(StyledExpandTextButton)`
    color: #000;
    text-align: left;
    font-weight: 600;
    display: inline-block;
`;

const StyledParagraph = styled(Paragraph)`
    font-weight: ${fontWeightMixin('body')};
`;

const PrivacySupportPage = ({ location: { hash } }) => {
    // Scroll to hash-name if available
    useEffect(() => {
        let targetAnchor;

        if (hash && hash.startsWith('#')) {
            targetAnchor = document.getElementById(hash.slice(1));
        }

        if (targetAnchor) {
            scrollToElement(hash, {
                offset: 0,
                ease: 'in-out-quad',
                duration: 300,
            });

            if (targetAnchor.children[0]) {
                targetAnchor.children[0].click();
            }
        }
    }, [hash]);

    return (
        <PageWrapper backgroundColor="#f4f4f4">
            <StyledPageContent>
                <PageSection maxWidth={1200}>
                    <StyledIntroFlex
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        flexWrap="wrap"
                        image={privacySupportImage}
                    >
                        <div className="page-hero-image" />
                        <div>
                            <Heading level="3" serif>
                                Privacy help center
                            </Heading>
                            <StyledParagraph fontType="h5" marginTop="sm">
                                We're here to help you navigate your privacy options.
                            </StyledParagraph>
                        </div>
                    </StyledIntroFlex>
                </PageSection>
            </StyledPageContent>

            <Spacer marginTop="md">
                <PageContent maxWidth={1200}>
                    <Heading level="5">
                        <strong>Frequently asked questions</strong>
                    </Heading>

                    <StyledCollapsibleDiv>
                        {FAQ.map(qa => (
                            <Collapsible key={qa.question} defaultCollapsed>
                                {(collapsed, toggle) => (
                                    <>
                                        <Spacer id={qa.id || ''} marginTop="lg">
                                            <StyledTextButton
                                                fontType="bodyHeading"
                                                onClick={toggle}
                                            >
                                                {collapsed ? (
                                                    <>
                                                        {qa.question}{' '}
                                                        <BigArrow
                                                            className="down-arrow"
                                                            direction="down"
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        {qa.question}{' '}
                                                        <BigArrow
                                                            className="up-arrow"
                                                            direction="up"
                                                        />
                                                    </>
                                                )}
                                            </StyledTextButton>
                                        </Spacer>
                                        {collapsed ? null : (
                                            <Spacer marginTop="md">
                                                <qa.answer />
                                            </Spacer>
                                        )}
                                    </>
                                )}
                            </Collapsible>
                        ))}
                    </StyledCollapsibleDiv>
                </PageContent>
            </Spacer>

            <Spacer marginTop="md">
                <PageContent maxWidth={1200}>
                    <Heading level="5">
                        <strong>Still have questions?</strong>
                    </Heading>

                    <Paragraph marginTop="md">Browse more topics and FAQs.</Paragraph>
                    <a
                        href="https://zillow.zendesk.com/hc/en-us"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Button marginTop="md" buttonType="primary">
                            Get answers
                        </Button>
                    </a>

                    <Paragraph marginTop="xl">
                        Ask a question about our privacy practices.
                    </Paragraph>
                    <a href="mailto:privacy@zillow.com">
                        <Button marginTop="md" buttonType="primary">
                            Email us
                        </Button>
                    </a>

                    <Paragraph marginTop="xl">Get assistance with a data request.</Paragraph>
                    <a href="mailto:privacy-request@zillowgroup.com">
                        <Button marginTop="md" buttonType="primary">
                            Ask for help
                        </Button>
                    </a>
                </PageContent>
            </Spacer>
        </PageWrapper>
    );
};

export default withRouter(PrivacySupportPage);
