import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    Alert,
    Heading,
    Paragraph,
    Spacer,
    Text,
    Tooltip,
    TooltipPopper,
    TriggerText,
} from '@zillow/constellation';

const gridSize = '23% 22% 20% 15% 20%';

const StyledGridContainer = styled.div`
    font-size: 15px;
    display: grid;
    align-items: start;
    grid-template-columns: ${gridSize};
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 10px;
`;

const StyledReportContainer = styled.div`
    max-height: 500px;
    overflow: hidden;
    overflow-y: scroll;
`;

const StyledGridEvenContainer = styled.div`
    display: grid;
    background-color: ${props => props.theme.constellation?.colors.gray200};
    align-items: start;
    grid-template-columns: ${gridSize};
`;

const StyledGridOddContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: ${gridSize};
`;

const StyledHeader = styled.div`
    display: inline-block;
    padding: 10px;
    background-color: ${props => props.theme.constellation?.colors.gray400};
`;

const StyledRow = styled.div`
    display: inline-block;
    padding: 5px 10px;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const StyledRedSpan = styled.span`
    color: ${props => props.theme.constellation?.colors.red500};
`;

const StyledGreenSpan = styled.span`
    color: ${props => props.theme.constellation?.colors.green500};
`;

const StyledYellow400Span = styled.span`
    color: ${props => props.theme.constellation?.colors.yellow400};
`;

const StyledFacebookSpan = styled.span`
    color: ${props => props.theme.constellation?.colors.facebook};
`;

const StyledHeaderSpan = styled.span`
    font-weight: bold;
`;

const StyledTriggerText = styled(TriggerText)`
    text-align: left;
`;

const DisplayIdentifierToRequestIds = props => {
    const getDisplayDate = timestamp => {
        const plus0 = num => `0${num.toString()}`.slice(-2);

        const d = new Date(timestamp);

        const year = d.getFullYear();
        const monthTmp = d.getMonth() + 1;
        const month = plus0(monthTmp);
        const date = plus0(d.getDate());
        const hour = plus0(d.getHours());
        const minute = plus0(d.getMinutes());
        const second = plus0(d.getSeconds());

        return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
    };

    const requestStatus = (status, isStrong = false) => {
        switch (status) {
            case 'REQUEST_SENT':
            case 'RETRY':
            case 'RETRY_SENT':
                return (
                    <StyledYellow400Span>
                        {isStrong ? <strong>{status}</strong> : status}{' '}
                    </StyledYellow400Span>
                );
            case 'REJECTED':
            case 'PARTIAL_REJECTED':
            case 'JIRA_TICKET_CREATED':
            case 'OVERDUE':
            case 'DELETE':
                return (
                    <StyledRedSpan>{isStrong ? <strong>{status}</strong> : status}</StyledRedSpan>
                );
            case 'EXPORT':
            case 'FINISHED':
                return (
                    <StyledGreenSpan>
                        {isStrong ? <strong>{status}</strong> : status}
                    </StyledGreenSpan>
                );
            default:
                return (
                    <StyledFacebookSpan>
                        {isStrong ? <strong>{status}</strong> : status}
                    </StyledFacebookSpan>
                );
        }
    };

    const getContent = props => {
        let content = [];
        props.data.forEach((obj, i) => {
            const StyledGridContainer = i % 2 ? StyledGridEvenContainer : StyledGridOddContainer;
            const userIdentifier = (
                <Tooltip
                    body={
                        <Paragraph>
                            <div>
                                Value:{' '}
                                <StyledHeaderSpan> {obj.userIdentifierValue} </StyledHeaderSpan>
                            </div>
                            <div>
                                Type:<StyledHeaderSpan> {obj.userIdentifierType} </StyledHeaderSpan>
                            </div>
                        </Paragraph>
                    }
                />
            );
            content.push(
                <StyledGridContainer key={i}>
                    <StyledRow title={obj.requestId}>{obj.requestId}</StyledRow>
                    <StyledRow>
                        <TooltipPopper triggered={userIdentifier}>
                            <StyledTriggerText>{obj.userIdentifierValue}</StyledTriggerText>
                        </TooltipPopper>
                    </StyledRow>
                    <StyledRow>{obj.requestType}</StyledRow>
                    <StyledRow>{getDisplayDate(obj.timestamp * 1000)}</StyledRow>
                    <StyledRow title={obj.requestStatus}>
                        {requestStatus(obj.requestStatus)}
                    </StyledRow>
                </StyledGridContainer>
            );
        });
        return content;
    };

    const reportContent =
        props.data.length <= 0 ? (
            <Alert appearance="warning" body="No data found." />
        ) : (
            <Spacer>
                <Spacer marginTop="xs">
                    <Alert
                        body={
                            <Text>
                                Total number of records found: <strong>{props.data.length}</strong>
                            </Text>
                        }
                    />
                </Spacer>
                <StyledGridContainer>
                    <StyledHeader>Request Id</StyledHeader>
                    <StyledHeader>Identifier Value</StyledHeader>
                    <StyledHeader>Request Type</StyledHeader>
                    <StyledHeader>Create Date</StyledHeader>
                    <StyledHeader>Request Status</StyledHeader>
                </StyledGridContainer>
                <StyledReportContainer>{getContent(props)}</StyledReportContainer>
            </Spacer>
        );

    return (
        <div>
            <Heading level="4">Requests :</Heading>
            {reportContent}
        </div>
    );
};

DisplayIdentifierToRequestIds.propTypes = {
    data: PropTypes.any,
};

DisplayIdentifierToRequestIds.defaultProps = {
    data: [],
};

export default DisplayIdentifierToRequestIds;
